import { ReadOnlyRelationshipsGrid } from '@code/authzed-common/src/components/ReadOnlyRelationshipsGrid';
import { rewriteSchema } from '@code/authzed-common/src/parsers/dsl/generator';
import { parseRelationships } from '@code/authzed-common/src/parsing';
import {
  LOOKUP_TENANT_BY_SLUG,
  LookupTenantBySlugData,
  LookupTenantBySlugParams,
  POPULATE_TENANT,
  PopulateTenantParameters,
} from '@code/authzed-common/src/queries/tenant';
import { UserEvent } from '@code/authzed-common/src/types/events';
import { Organization } from '@code/authzed-common/src/types/organization';
import { Tenant } from '@code/authzed-common/src/types/tenant';
import { AmplitudeContext } from '@code/trumpet/src/AmplitudeClient';
import ExternalLink from '@code/trumpet/src/ExternalLink';
import LoadingView from '@code/trumpet/src/LoadingView';
import Wizard, { WizardStep } from '@code/trumpet/src/Wizard';
import {
  UpdateOption,
  usePrometheusEventTrigger,
} from '@code/trumpet/src/charting/hook';
import { useFixedQuery, useManagedMutation } from '@code/trumpet/src/hooks';
import { PLAYGROUND_URL } from "../constants";
import {
  faArrowCircleRight,
  faCalendarDay,
  faCode,
  faList,
  faListAlt,
  faProjectDiagram,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from '@material-ui/lab/Alert';
import { AnalyticsBrowser } from '@segment/analytics-next';
import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { openPopupWidget } from 'react-calendly';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import replaceall from 'string.prototype.replaceall';
import { ReactComponent as DISCORD } from '../assets/discord-gray.svg';
import { CurrentUserContext } from '../context';
import AppConfig from '../services/configservice';
import { CodeSample, LanguageSample } from './CodeSample';
import { CreateTenantForm } from './CreateTenantForm';
import { SchemaDisplay } from './SchemaDisplay';
import {
  GoSample,
  JavaSample,
  NodeJSSample,
  PythonSample,
  RubySample,
  ZedSample,
} from './TutorialSamples';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(2),
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: 'auto 1fr auto',
    },
    wizard: {},
    wizardContent: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    wizardStepper: {
      backgroundColor: theme.palette.background.default,
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    hidden: {
      display: 'none',
    },
    infoBox: {
      marginBottom: theme.spacing(2),
    },
    inlineForm: {
      backgroundColor: theme.palette.background.default,
      marginTop: theme.spacing(2),
    },
    relation: {
      color: '#ffa887',
    },
    permission: {
      color: '#1acc92',
    },
    def: {
      color: '#8787ff',
    },
    snippet: {
      backgroundColor: theme.palette.background.default,
      fontFamily: 'Roboto Mono, monospace',
      padding: theme.spacing(1),
      whiteSpace: 'pre',
    },
    triggerText: {
      display: 'inline-grid',
      gridTemplateColumns: 'auto auto',
      columnGap: theme.spacing(1),
      alignItems: 'center',
      marginTop: theme.spacing(1),
      '&:before': {
        content: "''",
        display: 'inline-block',
        width: '1em',
        height: '1em',
        background: 'white',
        marginRight: '4px',
        borderRadius: '50%',
        animation: `$pulse 750ms ${theme.transitions.easing.easeInOut}`,
        animationIterationCount: 'infinite',
        animationDirection: 'alternate-reverse',
      },
    },
    '@keyframes pulse': {
      '0%': {
        opacity: 0.25,
      },
      '100%': {
        opacity: 1,
      },
    },
    buttonBar: {
      display: 'inline-grid',
      gridTemplateColumns: 'auto auto',
      columnGap: theme.spacing(2),
      alignItems: 'center',
    },
    shoutout: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      '& svg': {
        width: '1.5em!important',
        height: '1.5em',
        color: 'gray',
        marginTop: '4px',
      },
      '& button': {
        marginTop: theme.spacing(1),
      },
      '& a': {
        marginTop: theme.spacing(1),
      },
      columnGap: theme.spacing(2),
      rowGap: '16px',
      marginTop: theme.spacing(3),
    },
  })
);

const TUTORIAL_SCHEMA = `/** user defines the users that can interact with documents */
definition user {}

/** document represents a document in the system */
definition document {
    /** reader relation defines which users are readers of the document */
    relation reader: user

    /** writer relation defines which users are writers of the document */
    relation writer: user

    /** write permission indicates whether a user can write to the document */
    permission write = writer

    /** read permission indicates whether a user can read the document */
    permission read = reader + write
}`;

const TUTORIAL_RELATIONSHIPS = `document:firstdoc#reader@user:sam#...
document:firstdoc#writer@user:sarah#...
document:seconddoc#reader@user:tammy#...
document:seconddoc#reader@user:cleo#...
document:seconddoc#writer@user:fred#...`;

export function Tutorial(props: { organization: Organization }) {
  const classes = useStyles();
  const history = useHistory();
  const amplitudeClient = useContext(AmplitudeContext);
  const currentUser = useContext(CurrentUserContext);

  const [cookies, setCookies, deleteCookies] = useCookies([
    'skip-tutorial',
    'tutorial-step',
  ]);
  const [tenant, setTenant] = useState<Tenant | undefined>();
  const [sawCheck, setSawCheck] = useState(false);
  const [showSawCheckDialog, setShowSawCheckDialog] = useState(false);
  const segmentAnalytics = useRef<AnalyticsBrowser | undefined>(undefined);

  useEffect(() => {
    const analytics = new AnalyticsBrowser();
    const writeKey = AppConfig().segment.writeKey;
    if (writeKey) {
      // Note: Currently this is the only place Segment events are emitted.
      // This load call must be moved to the app root if additional events are added.
      analytics.load({ writeKey: AppConfig().segment.writeKey });
      analytics.identify(
        {
          email: currentUser?.authenticatedUser?.profile?.email,
          name: currentUser?.authenticatedUser?.profile?.fullName,
          authnId: currentUser?.authenticatedUser?.authnId,
        },
        {
          context: {
            identify_source: 'serverless_signup',
          },
        }
      );
      // Viewing the Tutorial is a proxy for new user sign up
      analytics.track('Serverless User Signup');
      segmentAnalytics.current = analytics;
    }
  }, [currentUser]);

  const existingTenant = useMemo(() => {
    if (tenant) {
      return undefined;
    }

    if (!props.organization.recentTenants?.length) {
      return undefined;
    }

    if (props.organization.recentTenants.length === 1) {
      return props.organization.recentTenants[0];
    }

    if (props.organization.recentTenants.length > 1) {
      return props.organization.recentTenants.find(
        (tenant: Tenant) => tenant.slug.indexOf('tutorial') > 0
      );
    }

    return undefined;
  }, [props.organization, tenant]);

  const { data: fullTenantData, loading: loadingFullTenant } = useFixedQuery<
    LookupTenantBySlugData,
    LookupTenantBySlugParams
  >(LOOKUP_TENANT_BY_SLUG.query, {
    variables: {
      organizationId: props.organization.id,
      tenantSlug: existingTenant?.slug ?? '',
    },
    skipIf: !(existingTenant?.slug ?? ''),
  });

  const [activeStep, setActiveStepLocal] = useState(0);

  const setActiveStep = (index: number) => {
    setActiveStepLocal(index);
    deleteCookies('tutorial-step');
    setCookies('tutorial-step', index.toString());
  };

  useEffect(() => {
    // If the full existing tenant was loaded, move the tutorial to the correct step.
    if (
      !loadingFullTenant &&
      fullTenantData?.tenantBySlug &&
      !tenant &&
      activeStep === 0
    ) {
      setTenant(fullTenantData?.tenantBySlug);
      setToken(fullTenantData.tenantBySlug.metricsViewToken);

      let existingStep = parseInt(cookies['tutorial-step']);
      if (existingStep < 0) {
        existingStep = fullTenantData.tenantBySlug.definitions!.length ? 3 : 2;
      }

      setActiveStepLocal(existingStep);
    }
  }, [cookies, loadingFullTenant, fullTenantData, tenant, activeStep]);

  const [token, setToken] = useState<string | undefined>(undefined);
  const [populateTenant, { running: populating }] = useManagedMutation<
    any,
    PopulateTenantParameters
  >(POPULATE_TENANT);

  const parameters = useMemo(() => {
    return {
      start: () => Date.now() / 1000 - 60 * 5,
      step: 10,
      end: () => Date.now() / 1000,
      tenant: tenant?.slug ?? '',
    };
  }, [tenant]);

  const awaitingTrigger = tenant !== undefined && activeStep === 4 && !sawCheck;

  const checkTriggerState = usePrometheusEventTrigger(
    `grpc_server_handling_seconds_count{tenant="${tenant?.slug}", grpc_method=~"Check|CheckPermission"}`,
    parameters,
    AppConfig().authzed.promEndpoint,
    `Bearer ${tenant?.metricsViewToken ?? ''}`,
    5 * 1000 /* 5 seconds */,
    UpdateOption.WITH_PAGE_VISIBLE,
    awaitingTrigger
  );

  useEffect(() => {
    if (checkTriggerState.triggered && activeStep === 4 && !sawCheck) {
      setSawCheck(true);
      setShowSawCheckDialog(true);
    }
  }, [checkTriggerState, activeStep, sawCheck]);

  const canProgress = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return true;

      case 2:
        return !populating;

      case 3:
        return !populating;

      case 4:
        return true;

      case 5:
        return true;
    }

    return false;
  };

  const isNextVisible = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return false;
    }

    return true;
  };

  const nextTitle = (currentStep: number) => {
    switch (currentStep) {
      case 2:
        return 'Write Schema and continue';

      case 3:
        return 'Write Relationships and continue';

      case 4:
        return sawCheck ? `Complete Tutorial` : `I already ran the Check`;

      case 5:
        return `Close Tutorial`;
    }

    return 'Next';
  };

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        amplitudeClient?.logEvent(UserEvent.ViewTutorialStep, {
          step: 'create-sys',
        });
        setActiveStep(1);
        return;

      case 2:
        if (tenant) {
          (async () => {
            const result = await populateTenant({
              variables: {
                tenantId: tenant.id,
                schema: rewriteSchema(TUTORIAL_SCHEMA, tenant.slug) ?? '',
                relationships: [],
              },
            });
            if (result && (result.errors?.length ?? 0) === 0 && result.data) {
              amplitudeClient?.logEvent(UserEvent.ViewTutorialStep, {
                step: 'write-relationships',
              });
              setActiveStep(3);
            }
          })();
        }
        return;

      case 3:
        if (tenant) {
          (async () => {
            const result = await populateTenant({
              variables: {
                tenantId: tenant.id,
                schema: rewriteSchema(TUTORIAL_SCHEMA, tenant.slug) ?? '',
                relationships: TUTORIAL_RELATIONSHIPS.split('\n'),
              },
            });
            if (result && (result.errors?.length ?? 0) === 0 && result.data) {
              amplitudeClient?.logEvent(UserEvent.ViewTutorialStep, {
                step: 'api-call',
              });
              setActiveStep(4);
            }
          })();
        }
        return;

      case 4:
        amplitudeClient?.logEvent(UserEvent.ViewTutorialStep, {
          step: 'next-steps',
        });
        setActiveStep(5);
        return;

      case 5:
        amplitudeClient?.logEvent(UserEvent.CompleteTutorial);
        handleSkip();
        return;
    }
  };

  const handleSkip = () => {
    setCookies('tutorial-step', undefined);
    setCookies('skip-tutorial', 'true');
    history.replace(`/organization/${props.organization.slug}/`, {
      skipTutorial: true,
    });
  };

  const handleSkipButton = () => {
    amplitudeClient?.logEvent(UserEvent.SkipTutorial);
    handleSkip();
  };

  const handleTenantCreated = (tenant: Tenant, defaultToken: string) => {
    setTenant(tenant);
    setToken(defaultToken);
    setActiveStep(2);
  };

  const handleTenantCreating = (isCreating: boolean) => {
    // Intentionally left blank.
  };

  const showCalendly = () => {
    openPopupWidget({
      url: AppConfig().calendly.url,
    });
  };

  const defaultSlug = `${replaceall(
    props.organization.slug,
    '-',
    '_'
  )}_tutorial`;

  return (
    <div>
      <Typography className={classes.header} variant="h5">
        Getting Started With Authzed
        <div />
        {activeStep < 5 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSkipButton}
          >
            Skip tutorial and go to dashboard
          </Button>
        )}
      </Typography>
      {loadingFullTenant && <LoadingView message="Loading tutorial..." />}
      {!loadingFullTenant && (
        <>
          <Wizard
            className={classes.wizard}
            stepComponent={Paper}
            classes={{
              stepper: classes.wizardStepper,
              stepContent: classes.wizardContent,
            }}
            activeStep={activeStep}
          >
            <WizardStep title="Welcome" icon={<DescriptionIcon />}>
              <Typography variant="subtitle1">
                This tutorial will walk you step-by-step through protecting an
                application with Authzed, covering:
              </Typography>
              <ol>
                <li>Creating the permissions system for your application</li>
                <li>
                  Writing a schema to define your permissions system's data and
                  permissions
                </li>
                <li>Making a permissions API call</li>
                <li>Integrating within your application</li>
              </ol>
              <Typography variant="subtitle2">
                This tutorial should take approximately ten minutes
              </Typography>
            </WizardStep>
            <WizardStep
              title="Create a permissions system"
              icon={<FontAwesomeIcon icon={faProjectDiagram} />}
            >
              <Alert severity="info" className={classes.infoBox}>
                A permissions system holds the schema and data for computing
                answers to permissions questions
              </Alert>
              <Typography>
                The first step when using Authzed is to define a{' '}
                <strong>permissions system</strong> for your application or
                service:
              </Typography>
              <Paper
                className={classes.inlineForm}
                elevation={1}
                variant="outlined"
              >
                <CreateTenantForm
                  organization={props.organization}
                  tenantCreated={handleTenantCreated}
                  tenantCreating={handleTenantCreating}
                  defaultSlug={defaultSlug}
                  defaultName={`${props.organization.name} Tutorial System`}
                  defaultDescription={`Permissions system for the tutorial`}
                />
              </Paper>
            </WizardStep>
            <WizardStep title="Writing schema" icon={'DEF'}>
              {tenant && (
                <Alert severity="success" className={classes.infoBox}>
                  Your permissions system has been created!
                </Alert>
              )}

              <Typography>
                <p>
                  The first step after creating a new permissions system is to
                  define a <strong>schema</strong> for the permissions system.
                </p>
                <p>
                  The{' '}
                  <ExternalLink to="https://docs.authzed.com/reference/schema-lang">
                    <strong>schema</strong>
                  </ExternalLink>{' '}
                  of a permissions system specifies the kind of data that can be
                  placed into the system via{' '}
                  <code className={classes.relation}>relation</code>'s and how{' '}
                  <code className={classes.permission}>permission</code>'s are
                  to be evaluated.
                </p>
                <p>
                  For this tutorial, we've chosen a simple schema that
                  represents the permissions system for a basic document
                  application:
                </p>
                <SchemaDisplay schema={TUTORIAL_SCHEMA} height="350px" />
                <p>
                  Schema's are loaded via this dashboard or{' '}
                  <ExternalLink to="https://buf.build/authzed/api/docs/main/authzed.api.v1alpha1#WriteSchema">
                    written via the Authzed API
                  </ExternalLink>
                  .
                </p>
                <p>
                  For this tutorial, we'll be writing the schema for you. Click
                  "Write Schema and Continue" to write the schema to your
                  permissions system and continue.
                </p>
              </Typography>
            </WizardStep>
            <WizardStep
              title="Writing relationships"
              icon={<FontAwesomeIcon icon={faListAlt} />}
            >
              <Alert severity="success" className={classes.infoBox}>
                Schema has been written to your permissions system!
              </Alert>
              <Typography>
                <p>
                  With a schema written to your permissions system, you can now
                  define <strong>relationships</strong> between various kinds of
                  objects/subjects.
                </p>
                <p>
                  <ExternalLink to="https://docs.authzed.com/concepts/terminology#relationship">
                    <strong>Relationships</strong>
                  </ExternalLink>{' '}
                  are used to indicate to Authzed how an object (or subject)
                  relates to another in your permissions system.
                </p>
                <p>
                  For this tutorial, we've chosen a simple set of relationships
                  to represent a set of documents and the users that are able to
                  access them:
                </p>
                <ReadOnlyRelationshipsGrid
                  relationships={parseRelationships(TUTORIAL_RELATIONSHIPS)}
                  hideSubjectRelation
                />
                <p>
                  Relationships's are normally written via the{' '}
                  <ExternalLink to="https://buf.build/authzed/api/docs/main/authzed.api.v0#Write">
                    Authzed API Write
                  </ExternalLink>{' '}
                  call.
                </p>
                <p>
                  For this tutorial, we'll be writing the relationships for you.
                  Click "Write Relationships and Continue" to write the
                  relationships to your permissions system and continue.
                </p>
              </Typography>
            </WizardStep>
            <WizardStep
              title="Making an API call"
              icon={<FontAwesomeIcon icon={faCode} />}
            >
              <Alert severity="success" className={classes.infoBox}>
                Relationships have been written to your permissions system!
              </Alert>

              <Typography>
                <p>
                  With both a schema and relationships written to your
                  permissions system, you can now make API requests to check
                  permissions for the objects and subjects defined!
                </p>
                <p>
                  To get started, we'll begin with the most basic of operations:
                  a <code>Check</code>
                </p>
                <p>
                  A <code>Check</code> (as its name implies) checks that a
                  particular subject has a particular{' '}
                  <code className={classes.permission}>permission</code> on an
                  object.
                </p>
                <p>
                  For example, let's say we wished to check if{' '}
                  <code className={classes.def}>user</code> <code>sarah</code>{' '}
                  can <code className={classes.permission}>read</code> the{' '}
                  <code className={classes.def}>document</code>{' '}
                  <code>firstdoc</code>:
                </p>
                <Paper variant="outlined" className={classes.snippet}>
                  check user:sarah read document:firstdoc
                </Paper>

                <p>
                  To make this easier, we've placed your permissions system ID
                  and default token into the code sample below.
                </p>
                <p>
                  Copy the sample for your preferred language and run it to
                  issue your first request to Authzed:
                </p>
                <CodeSample height="250px">
                  <LanguageSample language="zed">
                    {tenant && token ? ZedSample(tenant, token) : ''}
                  </LanguageSample>
                  <LanguageSample language="go">
                    {tenant && token ? GoSample(tenant, token) : ''}
                  </LanguageSample>
                  <LanguageSample language="python">
                    {tenant && token ? PythonSample(tenant, token) : ''}
                  </LanguageSample>
                  <LanguageSample language="nodejs">
                    {tenant && token ? NodeJSSample(tenant, token) : ''}
                  </LanguageSample>
                  <LanguageSample language="ruby">
                    {tenant && token ? RubySample(tenant, token) : ''}
                  </LanguageSample>
                  <LanguageSample language="java">
                    {tenant && token ? JavaSample(tenant, token) : ''}
                  </LanguageSample>
                </CodeSample>

                {awaitingTrigger && (
                  <div className={classes.triggerText}>
                    Waiting for Check Request...
                  </div>
                )}
              </Typography>
            </WizardStep>
            <WizardStep
              title="Next steps"
              icon={<FontAwesomeIcon icon={faArrowCircleRight} />}
            >
              <Typography variant="h6">
                And that's it! You have successfully provisioned, defined and
                tested a permissions system!
              </Typography>
              <p className={classes.shoutout}>
                <FontAwesomeIcon icon={faList} />
                <div>
                  <Typography variant="subtitle1">
                    Some next steps to help you on your Authzed journey:
                  </Typography>
                  <ul>
                    <li>
                      Read the{' '}
                      <ExternalLink to="https://docs.authzed.com/guides/first-app">
                        Protecting your first app
                      </ExternalLink>{' '}
                      guide to protect your first application
                    </li>
                    <li>
                      Read how to develop your own schema in the{' '}
                      <ExternalLink to="https://docs.authzed.com/guides/schema">
                        Developing a Permissions System Schema
                      </ExternalLink>{' '}
                      guide
                    </li>
                    <li>
                      Work on your own schema in the{' '}
                      <ExternalLink to={PLAYGROUND_URL}>
                        Authzed Playground
                      </ExternalLink>
                    </li>
                  </ul>
                </div>
              </p>
              {!!AppConfig().discord.inviteUrl && (
                <p className={classes.shoutout}>
                  <DISCORD viewBox="0 0 71 55" />
                  <div>
                    <Typography variant="subtitle1">
                      Want to discuss with the community?
                    </Typography>
                    <Button
                      variant="contained"
                      color="default"
                      href={AppConfig().discord.inviteUrl}
                      target="_blank"
                    >
                      Join Discord
                    </Button>
                  </div>
                </p>
              )}
              {!!AppConfig().calendly.url && (
                <p className={classes.shoutout}>
                  <FontAwesomeIcon icon={faCalendarDay} />
                  <div>
                    <Typography variant="subtitle1">
                      Want help developing your schema?
                    </Typography>
                    Schedule a session with an Authzed Engineer to develop your
                    schema in real time or to discuss any questions you may
                    have.
                    <br />
                    <Button
                      onClick={showCalendly}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      Speak to an Authzed Engineer
                    </Button>
                  </div>
                </p>
              )}
            </WizardStep>
          </Wizard>
          <div className={classes.buttonBar}>
            <Button
              variant={!awaitingTrigger ? 'contained' : 'text'}
              color={!awaitingTrigger ? 'primary' : 'default'}
              disabled={!canProgress(activeStep)}
              onClick={handleNext}
              className={clsx({ [classes.hidden]: !isNextVisible(activeStep) })}
            >
              {nextTitle(activeStep)}
            </Button>
          </div>
        </>
      )}
      <Dialog
        open={showSawCheckDialog}
        onClose={() => setShowSawCheckDialog(false)}
      >
        <DialogTitle>Check call succeeded!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your call to the Check API has succeeded!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSawCheckDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
