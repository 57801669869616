import {
  DeleteTenantParameters,
  DELETE_TENANT,
  LookupTenantBySlugData,
  LOOKUP_TENANT_BY_SLUG,
} from "@code/authzed-common/src/queries/tenant";
import {
  OrganizationPermission,
  TenantPermission,
  usePermissionsService,
  WithTenantPermission,
} from "@code/authzed-common/src/services/permissionsservice";
import { UserEvent } from "@code/authzed-common/src/types/events";
import { Organization } from "@code/authzed-common/src/types/organization";
import { TenantKind } from "@code/authzed-common/src/types/tenant";
import { ParsedValidation } from "@code/authzed-common/src/validationfileformat";
import { AmplitudeContext } from "@code/trumpet/src/AmplitudeClient";
import PrometheusQueryChart, {
  PrometheusQueryChartProps,
} from "@code/trumpet/src/charting/PrometheusQueryChart";
import PrometheusRangeQueryChart from "@code/trumpet/src/charting/PrometheusRangeQueryChart";
import { QueryView } from "@code/trumpet/src/components";
import { useConfirmDialog } from "@code/trumpet/src/ConfirmDialogProvider";
import ExternalLink from "@code/trumpet/src/ExternalLink";
import useHistoricalTabs from "@code/trumpet/src/HistoricalTabsHook";
import { useManagedMutation, useManagedQuery } from "@code/trumpet/src/hooks";
import LoadingView from "@code/trumpet/src/LoadingView";
import TabLabel from "@code/trumpet/src/TabLabel";
import TabPanel from "@code/trumpet/src/TabPanel";
import { useTitle } from "@code/trumpet/src/TitleHook";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import CreditCard from "@material-ui/icons/CreditCard";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ViewListIcon from "@material-ui/icons/ViewList";
import Alert from "@material-ui/lab/Alert";
import {
  schemeBlues,
  schemeGreens,
  schemeOranges,
  schemeReds,
} from "d3-scale-chromatic";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import RelationshipsList from "../components/RelationshipsList";
import { SchemaDisplay } from "../components/SchemaDisplay";
import TenantLogo from "../components/TenantLogo";
import TenantPermissionsEditor from "../components/TenantPermissionsEditor";
import { TenantSummary } from "../components/TenantSummary";
import { CreatedTokenDialog } from "../components/TokenManager";
import AppConfig from "../services/configservice";
import { useSharedTenantStyles } from "../sharedstyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tenantHeader: {
      display: "grid",
      gridTemplateColumns: "auto auto auto 1fr auto",
      columnGap: theme.spacing(2),
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    tabBar: {
      marginBottom: theme.spacing(2),
    },
    permissionsEditor: {
      marginTop: theme.spacing(1),
    },
    relationshipsList: {
      marginTop: theme.spacing(1),
    },
    billingView: {
      display: "grid",
      columnGap: "10px",
      rowGap: "10px",
      gridTemplateColumns: "repeat(auto-fit, minmax(450px, 1fr))",
      alignItems: "center",
      justifyContent: "center",
    },
    usageTitle: {
      marginBottom: theme.spacing(2),
    },
    logo: {
      marginRight: theme.spacing(1),
    },
    startText: {},
    tenantSlug: {},
    propertyDisplay: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      alignItems: "center",
      columnGap: theme.spacing(1),
      "& strong": {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        borderRadius: "4px",
        minWidth: "100px",
      },
    },
    namespaces: {
      padding: theme.spacing(1),
    },
    empty: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    nsItem: {
      color: "#92beff",
    },
    "@global": {
      ".monaco-editor .cursors-layer > .cursor": {
        display: "none !important",
      },
    },
    charts: {
      display: "grid",
      columnGap: "10px",
      rowGap: "10px",
      gridTemplateColumns: "repeat(auto-fit, minmax(450px, 1fr))",
      alignItems: "center",
      justifyContent: "center",
    },
    tenantInfo: {
      display: "grid",
      gridTemplateColumns: "auto auto 1fr",
      columnGap: "10px",
      marginTop: theme.spacing(1),
    },
    ns: {
      fontFamily: "Roboto Mono, monospace",
      color: "#8787ff",
      fontSize: "85%",
      fontWeight: "bold",
    },
  })
);

interface OrgTenantState {
  /**
   * defaultToken is the token that was created for this new tenant, if any.
   */
  defaultToken?: string;
}

function NS() {
  const classes = useStyles();
  return <span className={classes.ns}>DEF</span>;
}

interface TenantToLoadData {
  id: string;
  data: ParsedValidation;
}

type colorScheme =
  | typeof schemeBlues
  | typeof schemeGreens
  | typeof schemeOranges
  | typeof schemeReds;

function colorSchemeGenerator(scheme: colorScheme, samples: number = 3) {
  samples = Math.min(9, Math.max(3, samples));
  const sampleIndex = samples - 1;
  return (index: number) => scheme[samples][sampleIndex - index];
}

// Returns unix timestamp in UTC seconds of the beginning of the month relative to the reference date.
function beginningOfMonth(refDate: Date) {
  const year = refDate.getFullYear();
  const month = refDate.getMonth();
  return Math.trunc(Date.UTC(year, month, 1, 0, 0, 0, 0) / 1000);
}

export function OrgTenant(props: {
  organization: Organization;
  tenantSlug: string;
}) {
  const amplitudeClient = useContext(AmplitudeContext);
  const tenantToLoad = useRef<TenantToLoadData | undefined>(undefined);

  const tenantState = useManagedQuery<LookupTenantBySlugData>(
    LOOKUP_TENANT_BY_SLUG,
    {
      variables: {
        organizationId: props.organization.id,
        tenantSlug: props.tenantSlug,
      },
    }
  );

  const tenant = tenantState.data?.tenantBySlug;

  const classes = useStyles({ kind: tenant?.kind });
  const sharedClasses = useSharedTenantStyles({ tenant: tenant });
  const permissionsService = usePermissionsService();

  const isDevelopmentTenant = tenant?.kind === TenantKind.DEVELOPMENT;
  const isSystemTenant = tenant?.kind === TenantKind.SYSTEM;
  const isPopulated =
    tenant?.definitions !== undefined && tenant?.definitions.length > 0;
  const tabNames = [
    "schema",
    {
      metrics: isPopulated,
      relationships:
        isPopulated &&
        !isSystemTenant &&
        permissionsService.checkTenant(
          tenant,
          TenantPermission.ReadRelationships
        ),      
      billing:
        isPopulated &&
        permissionsService.checkOrg(
          props.organization,
          OrganizationPermission.ManageBilling
        ),
      permissions: permissionsService.checkTenant(
        tenant,
        TenantPermission.ManageAccess
      ),
      settings:
        isDevelopmentTenant &&
        permissionsService.checkTenant(tenant, TenantPermission.DeleteTenant),
    },
  ];

  const {
    currentTabName,
    handleChangeTab: _handleChangeTab,
    hasTab,
  } = useHistoricalTabs(tabNames, "schema");

  const handleChangeTab = (
    event: React.ChangeEvent<{}>,
    selectedTabValue: any
  ): void => {
    _handleChangeTab(event, selectedTabValue);
    amplitudeClient?.logEvent(UserEvent.ViewOrgTab, { tab: selectedTabValue });
  };

  useTitle(
    ["authzed", tenant?.name, props.organization.name],
    [props.organization, tenant]
  );

  const history = useHistory();
  const [createdToken, setCreatedToken] = useState(
    (history.location.state as OrgTenantState | undefined)?.defaultToken ??
      undefined
  );

  type AuthzedChartProps = Omit<
    PrometheusQueryChartProps,
    "endpoint" | "authHeader"
  > & { chart: React.FunctionComponent<any> };
  const AuthzedPromChart = (props: AuthzedChartProps) => {
    const { chart: Chart, ...chartProps } = props;
    return (
      <Chart
        {...chartProps}
        endpoint={AppConfig().authzed.promEndpoint}
        authHeader={`Bearer ${tenant?.metricsViewToken ?? ""}`}
      />
    );
  };

  const chartParams = useMemo(() => {
    return {
      start: () => Date.now() / 1000 - 60 * 5,
      step: 10,
      end: () => Date.now() / 1000,
      tenant: props.tenantSlug,
    };
  }, [props.tenantSlug]);

  const now = new Date();
  const utcMonth = now.getUTCMonth();
  const usageParams = useMemo(() => {
    // Usage end time is 1 second before the beginning of the next month
    const thisMonthSecs = new Date(now).setUTCMonth(now.getUTCMonth());
    const nextMonthSecs = new Date(now).setUTCMonth(now.getUTCMonth() + 1);
    const usageStart = beginningOfMonth(new Date(thisMonthSecs));
    const usageEnd = beginningOfMonth(new Date(nextMonthSecs)) - 1;
    const usageDuration = `${usageEnd - usageStart}s`;

    // Use UTC date to determine label
    const usageLabel = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    ).toLocaleString("default", { month: "long", year: "numeric" });

    return {
      duration: usageDuration,
      end: usageEnd,
      label: usageLabel,
    };
  }, [utcMonth]);

  const usageChartParams = useMemo(() => {
    return {
      start: () => Date.now() / 1000 - 60 * 60, // 1 hour
      step: "1m",
      end: () => Date.now() / 1000,
      tenant: props.tenantSlug,
    };
  }, [props.tenantSlug]);

  const [
    CheckLatencyChart,
    ExpandLatencyChart,
    WriteLatencyChart,
    LookupLatencyChart,
    CachedOpsChart,
    DispatchedOpsChart,
    CachedOpsPercentageChart,
  ] = useMemo(() => {
    return [
      <AuthzedPromChart
        chart={PrometheusRangeQueryChart}
        title="Check Latency"
        query={`histogram_quantile(0.95, avg by(grpc_method, le) (rate(grpc_server_handling_seconds_bucket{tenant="${props.tenantSlug}",grpc_method=~"Check|ContentChangeCheck|CheckPermission"}[1m]))) * 1000`}
        parameters={chartParams}
        legendGenerator={(metric: Record<string, any>) => metric["grpc_method"]}
        valueFormat="ms"
      />,
      <AuthzedPromChart
        chart={PrometheusRangeQueryChart}
        title="Expand And Read Latency"
        query={`histogram_quantile(0.95, avg by(grpc_method, le) (rate(grpc_server_handling_seconds_bucket{tenant="${props.tenantSlug}",grpc_method=~"Expand|ExpandPermissionTree|Read|ReadRelationships"}[1m]))) * 1000`}
        parameters={chartParams}
        legendGenerator={(metric: Record<string, any>) => metric["grpc_method"]}
        valueFormat="ms"
        getDataColor={colorSchemeGenerator(schemeGreens)}
      />,
      <AuthzedPromChart
        chart={PrometheusRangeQueryChart}
        title="Write Latency"
        query={`histogram_quantile(0.95, avg by(grpc_method, le) (rate(grpc_server_handling_seconds_bucket{tenant="${props.tenantSlug}",grpc_method=~"Write|WriteRelationships"}[1m]))) * 1000`}
        parameters={chartParams}
        legendGenerator={(metric: Record<string, any>) => metric["grpc_method"]}
        valueFormat="ms"
        getDataColor={colorSchemeGenerator(schemeOranges)}
      />,
      <AuthzedPromChart
        chart={PrometheusRangeQueryChart}
        title="Lookup Latency"
        query={`histogram_quantile(0.95, avg by(grpc_method, le) (rate(grpc_server_handling_seconds_bucket{tenant="${props.tenantSlug}",grpc_method=~"Lookup|LookupResources"}[1m]))) * 1000`}
        parameters={chartParams}
        legendGenerator={(metric: Record<string, any>) => metric["grpc_method"]}
        valueFormat="ms"
        getDataColor={colorSchemeGenerator(schemeBlues)}
      />,
      <AuthzedPromChart
        chart={PrometheusRangeQueryChart}
        title="Cached Operations"
        query={`sum by (grpc_method) (rate(request_operations_cached_total{tenant="${props.tenantSlug}"}[1m]))`}
        parameters={chartParams}
        legendGenerator={(metric: Record<string, any>) => metric["grpc_method"]}
        valueFormat="ops"
        getDataColor={colorSchemeGenerator(schemeBlues)}
      />,
      <AuthzedPromChart
        chart={PrometheusRangeQueryChart}
        title="Dispatched Operations"
        query={`sum by (grpc_method) (rate(request_operations_dispatched_total{tenant="${props.tenantSlug}"}[1m]))`}
        parameters={chartParams}
        legendGenerator={(metric: Record<string, any>) => metric["grpc_method"]}
        valueFormat="ops"
        getDataColor={colorSchemeGenerator(schemeReds, 6)}
      />,
      <AuthzedPromChart
        chart={PrometheusRangeQueryChart}
        title="Cached Operations Percentage"
        query={`100 * sum(rate(request_operations_cached_total{tenant="${props.tenantSlug}"}[1m])) / (sum(rate(request_operations_cached_total{tenant="${props.tenantSlug}"}[1m])) + sum(rate(request_operations_dispatched_total{tenant="${props.tenantSlug}"}[1m])))`}
        parameters={chartParams}
        legendGenerator={(metric: Record<string, any>) => "Cached Ratio"}
        valueFormat="%"
        getDataColor={colorSchemeGenerator(schemeOranges)}
      />,
    ];
  }, [tenant, props.tenantSlug]);

  const [CachedOpsRate, DispatchedOpsRate] = useMemo(() => {
    return [
      <AuthzedPromChart
        chart={PrometheusRangeQueryChart}
        title="Cached Operations"
        query={`sum(rate(request_operations_cached_total{tenant="${props.tenantSlug}"}[1m]))`}
        parameters={usageChartParams}
        legendGenerator={(metric: Record<string, any>) => "Cached"}
        valueFormat="ops"
        getDataColor={colorSchemeGenerator(schemeBlues)}
      />,
      <AuthzedPromChart
        chart={PrometheusRangeQueryChart}
        title="Dispatched Operations"
        query={`sum(rate(request_operations_dispatched_total{tenant="${props.tenantSlug}"}[1m]))`}
        parameters={usageChartParams}
        legendGenerator={(metric: Record<string, any>) => "Dispatched"}
        valueFormat="ops"
        getDataColor={colorSchemeGenerator(schemeReds)}
      />,
    ];
  }, [tenant, props.tenantSlug]);

  const { showConfirm } = useConfirmDialog();
  const [deleteTenant, { running: deleting }] = useManagedMutation<
    any,
    DeleteTenantParameters
  >(DELETE_TENANT);
  const askDeleteTenant = () => {
    if (tenant === undefined) {
      return;
    }

    (async () => {
      const [result, entered] = await showConfirm({
        title: `Delete permissions system ${tenant.name}?`,
        content: `Enter "${tenant.slug}" to permanently this permissions system.`,
        withPrompt: "Confirm Permissions System name",
        buttons: [
          { title: "Cancel", value: "undefined" },
          {
            title: `Delete ${tenant.name}`,
            variant: "contained",
            color: "primary",
            value: "delete",
            isEnabled: (promptValue: string | undefined) => {
              return promptValue === tenant.slug;
            },
          },
        ],
      });

      if (result === "delete" && entered === tenant.slug) {
        await deleteTenant({
          variables: {
            tenantId: tenant.id,
          },
        });
        history.push(`/organization/${props.organization.slug}`);
      }
    })();
  };

  return (
    <div>
      {tenant && createdToken && (
        <CreatedTokenDialog
          createdToken={createdToken}
          onClose={() => setCreatedToken(undefined)}
          message="A default client and token have been created to access the API for the new permissions system."
          tenantSlug={tenant.slug}
        />
      )}
      
      <QueryView state={tenantState}>
        {deleting && <LoadingView message="Deleting permissions system..." />}
        {tenant && !deleting && (
          <div>
            <div className={classes.tenantHeader}>
              <span style={{ gridRow: "1/2", gridColumn: "1/2" }}>
                <TenantLogo tenant={tenant} large />
              </span>
              <span style={{ gridRow: "1/2", gridColumn: "2/3" }}>
                <Typography variant="h4">{tenant.name}</Typography>
              </span>
              <span style={{ gridRow: "2/3", gridColumn: "2/3" }}>
                <Typography variant="subtitle1">
                  {tenant.description}
                </Typography>
              </span>
              <span
                className={classes.tenantInfo}
                style={{ gridRow: "3/4", gridColumn: "2/5" }}
              >
                <div className={sharedClasses.tenantTag}>
                  <TenantSummary tenant={tenant} />
                </div>

                <div className={sharedClasses.tenantTag}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    style={{ display: "inline-block" }}
                  >
                    <FontAwesomeIcon icon={faCode} />
                  </Typography>
                  &nbsp;
                  <code className={classes.tenantSlug}>{tenant.slug}/</code>
                </div>

                <div />
              </span>             
            </div>

            <AppBar
              className={classes.tabBar}
              position="static"
              color="default"
            >
              <Tabs
                value={currentTabName}
                onChange={handleChangeTab}
                indicatorColor="primary"
                aria-label="Tabs"
              >
                <Tab
                  value="schema"
                  label={<TabLabel icon={<NS />} title="Schema" />}
                />
                {hasTab("metrics") && (
                  <Tab
                    value="metrics"
                    label={
                      <TabLabel icon={<DashboardIcon />} title="Metrics" />
                    }
                  />
                )}
                {hasTab("relationships") && (
                  <Tab
                    value="relationships"
                    label={
                      <TabLabel icon={<ViewListIcon />} title="Relationships" />
                    }
                  />
                )}
                {hasTab("permissions") && (
                  <Tab
                    value="permissions"
                    label={
                      <TabLabel
                        icon={<SupervisedUserCircleIcon />}
                        title="API Access Permissions"
                      />
                    }
                  />
                )}
                {hasTab("billing") && (
                  <Tab
                    value="billing"
                    label={<TabLabel icon={<CreditCard />} title="Billing" />}
                  />
                )}                
                {hasTab("settings") && (
                  <Tab
                    value="settings"
                    label={
                      <TabLabel icon={<SettingsIcon />} title="Settings" />
                    }
                  />
                )}
              </Tabs>
            </AppBar>

            <TabPanel index="metrics" value={currentTabName}>
              {tenant.definitions &&
                tenant.definitions?.length > 0 &&
                currentTabName === "metrics" && (
                  <div className={classes.charts}>
                    {CheckLatencyChart}
                    {ExpandLatencyChart}
                    {WriteLatencyChart}
                    {LookupLatencyChart}
                    {CachedOpsChart}
                    {DispatchedOpsChart}
                    {CachedOpsPercentageChart}
                  </div>
                )}
            </TabPanel>

            <TabPanel index="schema" value={currentTabName}>
              {!!tenant.schema && (
                <Paper className={classes.namespaces}>
                  <SchemaDisplay schema={tenant.schema} />
                </Paper>
              )}
              {tenant.definitions &&
                tenant.definitions.length > 0 &&
                !tenant.schema && (
                  <Alert severity="warning">
                    System is currently in read only mode and cannot load
                    schemas
                  </Alert>
                )}
              {tenant.definitions !== null && tenant.definitions?.length === 0 && (
                <>
                  <Alert severity="info">
                    <Typography
                      className={classes.startText}
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      The {tenant.name} Permissions System has no schema
                      defined.
                    </Typography>

                    <WithTenantPermission
                      tenant={tenant}
                      permission={TenantPermission.PopulateTenant}
                    >
                      <Typography
                        className={classes.startText}
                        variant="subtitle2"
                        color="textSecondary"
                      >
                        Choose an option below to get started or follow the{" "}
                        <ExternalLink to="https://docs.authzed.com/guides/first-app">
                          Protecting Your First App guide
                        </ExternalLink>{" "}
                        to write the initial schema.
                      </Typography>
                    </WithTenantPermission>
                  </Alert>                  
                </>
              )}
            </TabPanel>

            <TabPanel index="relationships" value={currentTabName}>
              <div className={classes.relationshipsList}>
                <RelationshipsList
                  organization={props.organization}
                  tenant={tenant}
                />
              </div>
            </TabPanel>

            <TabPanel index="permissions" value={currentTabName}>
              <WithTenantPermission
                tenant={tenant}
                permission={TenantPermission.ManageAccess}
              >
                <div className={classes.permissionsEditor}>
                  <TenantPermissionsEditor
                    organization={props.organization}
                    tenant={tenant}
                  />
                </div>
              </WithTenantPermission>
            </TabPanel>

            <TabPanel index="billing" value={currentTabName}>
              {currentTabName === "billing" && (
                <Paper className={classes.empty}>
                  <Typography variant="h5" className={classes.usageTitle}>
                    Usage Metrics
                  </Typography>
                  <Typography variant="subtitle1">
                    {usageParams.label}
                  </Typography>
                  {tenant.definitions && tenant.definitions?.length > 0 && (
                    <div className={classes.billingView}>
                      <AuthzedPromChart
                        chart={PrometheusQueryChart}
                        title="Total Cached Operations"
                        legendTitle="this month (UTC)"
                        query={() =>
                          `scalar(floor(sum(increase(request_operations_cached_total{tenant="${props.tenantSlug}"}[${usageParams.duration}] @ ${usageParams.end}) or vector(0))))`
                        }
                        parameters={{ tenant: props.tenantSlug }}
                        scalarFormatter={(value) =>
                          Math.round(parseFloat(value)).toLocaleString()
                        }
                      />
                      {CachedOpsRate}
                      <AuthzedPromChart
                        chart={PrometheusQueryChart}
                        title="Total Dispatched Operations"
                        legendTitle="this month (UTC)"
                        query={() =>
                          `scalar(floor(sum(increase(request_operations_dispatched_total{tenant="${props.tenantSlug}"}[${usageParams.duration}] @ ${usageParams.end}) or vector(0))))`
                        }
                        parameters={{ tenant: props.tenantSlug }}
                        scalarFormatter={(value) =>
                          Math.round(parseFloat(value)).toLocaleString()
                        }
                      />
                      {DispatchedOpsRate}
                      <AuthzedPromChart
                        chart={PrometheusQueryChart}
                        title="Cached Operations Percentage"
                        legendTitle="this month (UTC)"
                        // Defaulting denominator to 1
                        query={() =>
                          `scalar(floor(sum(increase(request_operations_cached_total{tenant="${props.tenantSlug}"}[${usageParams.duration}] @ ${usageParams.end}) or vector(0)))) / (scalar(floor(sum(increase(request_operations_cached_total{tenant="${props.tenantSlug}"}[${usageParams.duration}] @ ${usageParams.end}) or vector(1)))) + scalar(floor(sum(increase(request_operations_dispatched_total{tenant="${props.tenantSlug}"}[${usageParams.duration}] @ ${usageParams.end}) or vector(0)))))`
                        }
                        parameters={{ tenant: props.tenantSlug }}
                        scalarFormatter={(value) =>
                          `${(parseFloat(value) * 100).toFixed(2)}%`
                        }
                      />
                    </div>
                  )}
                </Paper>
              )}
            </TabPanel>

            <TabPanel index="settings" value={currentTabName}>
              <Paper className={classes.empty}>
                <h2 style={{ margin: "10px" }}>Delete Permissions System</h2>
                <div style={{ margin: "20px" }}>
                  <Alert variant="standard" severity="error">
                    Deleted permissions systems cannot be recovered. Please be
                    certain!
                  </Alert>
                  <Button
                    style={{ color: "red", marginTop: "10px" }}
                    size="large"
                    variant="outlined"
                    disabled={deleting}
                    onClick={askDeleteTenant}
                  >
                    Delete Permissions System
                  </Button>
                </div>
              </Paper>
            </TabPanel>
          </div>
        )}
      </QueryView>
    </div>
  );
}
