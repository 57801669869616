import { AuthenticatedUserData } from '@code/authzed-common/src/queries/authenticateduser';
import { UserEvent } from '@code/authzed-common/src/types/events';
import { Organization } from '@code/authzed-common/src/types/organization';
import { AmplitudeContext } from '@code/trumpet/src/AmplitudeClient';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AdjustIcon from '@material-ui/icons/Adjust';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';
import OrgLogo from './OrgLogo';
import OrgReference from './OrgReference';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orgView: {
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
        },
        orgLogo: {
            width: '2rem',
            height: '2rem',
            marginRight: '10px',
        },
        orgContext: {
            display: 'inline-grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto auto',
            columnGap: '4px',
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(1),
            '&> div': {
                padding: theme.spacing(1),
                borderRadius: '16px',
            },
            '&:hover > div': {
                cursor: 'pointer',
                backgroundColor: 'rgba(255,255, 255, 0.07)'
            },
        },
        link: {
            color: theme.palette.text.primary,
        },
        orgNavLink: {
        },
        currentOrgCheck: {
            opacity: 0,
            marginRight: theme.spacing(0.5),
        },
        currentOrgCheckSelected: {
            opacity: 1,
        },
        orgMenuItem: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            padding: theme.spacing(1.5),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        noOrg: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.grey[500],
            '& svg': {
                marginRight: theme.spacing(1)
            }
        }
    }));



export function OrgSelector(props: { userData: AuthenticatedUserData, organization?: Organization | undefined }) {
    const amplitudeClient = useContext(AmplitudeContext);
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOrgMenuOpen = Boolean(anchorEl);

    const handleOrgMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuId = "selected-org-menu";

    const showSelectOrg = () => {
        history.push('/organizations');
        setAnchorEl(null);
    };

    const showCreateOrg = () => {
        amplitudeClient?.logEvent(UserEvent.ClickNewOrg);
        history.push('/createorg');
        setAnchorEl(null);
    };

    const showOrg = (org: Organization) => {
        history.push(`/organization/${org.slug}`);
        setAnchorEl(null);
    };

    const renderOrgMenu = (
        props.userData.authenticatedUser ? <Menu
            elevation={0}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{
                vertical: "top", horizontal: "right"
            }}
            id={menuId}
            keepMounted
            open={isOrgMenuOpen}
            onClose={handleMenuClose}
        >
            {props.userData.authenticatedUser.recentOrgs.length > 0 && <div>
                {props.userData.authenticatedUser.recentOrgs.map((org: Organization) => {
                    return <MenuItem key={org.id} className={classes.orgMenuItem} onClick={() => showOrg(org)}>
                        <ListItemIcon>
                            <OrgLogo organization={org} />
                        </ListItemIcon>
                        <Typography variant="inherit">{org.name}</Typography>
                        <CheckIcon className={clsx(classes.currentOrgCheck, { [classes.currentOrgCheckSelected]: org.slug === props.organization?.slug })} fontSize="small" />
                    </MenuItem>;
                })}
                {props.userData.authenticatedUser.recentOrgs.length >= 5 && <MenuItem className={classes.orgMenuItem} onClick={showSelectOrg}>
                    <ListItemIcon>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">Select Another Organization..</Typography>
                </MenuItem>}
                <Divider />
            </div>}
            <MenuItem className={classes.orgMenuItem} onClick={showCreateOrg}>
                <ListItemIcon>
                    <AddCircleIcon />
                </ListItemIcon>
                <Typography variant="inherit">Create New Organization</Typography>
            </MenuItem>
        </Menu > : <span />
    );

    return <div className={classes.orgContext}>
        <FontAwesomeIcon icon={faBuilding} size="lg" />
        <div
            aria-label="currently selected organization"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleOrgMenuOpen}
        >
            {props.organization &&
                <OrgReference organization={props.organization} inTopBar={true} />}
            {!props.organization && <span className={classes.noOrg}><AdjustIcon />No Organization Selected</span>}
        </div>
        {renderOrgMenu}
    </div>;
}
