import ExternalLink from "@code/trumpet/src/ExternalLink";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { PropsWithChildren } from "react";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        docLink: {
            padding: theme.spacing(0),
        },
    }));


/**
 * DocLink provides a link to documentation.
 */
export function DocLink(props: PropsWithChildren<{ to: string, className?: string }>) {
    const classes = useStyles();
    return <ExternalLink className={clsx(classes.docLink, props.className)} to={`https://docs.authzed.com/${props.to}`}>{props.children}</ExternalLink>;
}