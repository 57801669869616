import { Organization } from '@code/authzed-common/src/types/organization';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from "react";
import OrgLogo from './OrgLogo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            color: (props: { inTopBar: boolean }) => props.inTopBar ? 'white !important' : theme.palette.text.primary + '!important',
        },
        name: {
            marginLeft: theme.spacing(1)
        }
    }));

/**
 * Defines the properties for the OrgReference.
 */
interface OrgReferenceProps {
    /**
     * The organization for which to display the reference.
     */
    organization: Organization;

    /**
     * inTopBar indicates whether the org reference is in the top bar.
     */
    inTopBar?: boolean;
}

export default function OrgReference(props: OrgReferenceProps) {
    const classes = useStyles({ inTopBar: props.inTopBar || false });

    return <span className={classes.root}>
        <OrgLogo organization={props.organization} /> <span className={classes.name}>{props.organization.name}</span>
    </span>;
}