import TabLabel from '@code/trumpet/src/TabLabel';
import { AppBar, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Editor, { useMonaco } from "@monaco-editor/react";
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { ReactComponent as ZED } from '../assets/favicon.svg';
import { ReactComponent as GOLANG } from '../assets/go.svg';
import { ReactComponent as JAVA } from '../assets/java.svg';
import { ReactComponent as NODE } from '../assets/nodejs.svg';
import { ReactComponent as PYTHON } from '../assets/python.svg';
import { ReactComponent as RUBY } from '../assets/ruby.svg';


export function LanguageSample(props: { language: Language, children: string }) {
    return <div />;
}

export type Language = 'zed' | 'python' | 'go' | 'nodejs' | 'ruby' | 'java'

interface LangInfo {
    name: string
    logo: React.ReactNode | undefined
    monacoLang: string
}

const LANGUAGES: Record<Language, LangInfo> = {
    'zed': {
        name: 'zed CLI tool',
        logo: <ZED />,
        monacoLang: 'shell',
    },
    'python': {
        name: 'Python',
        logo: <PYTHON />,
        monacoLang: 'python',
    },
    'go': {
        name: 'Go',
        logo: <GOLANG />,
        monacoLang: 'go',
    },
    'nodejs': {
        name: 'Node',
        logo: <NODE />,
        monacoLang: 'javascript',
    },
    'ruby': {
        name: 'Ruby',
        logo: <RUBY />,
        monacoLang: 'ruby',
    },
    'java': {
        name: 'Java',
        logo: <JAVA />,
        monacoLang: 'java',
    },
}

interface LanguageDef {
    id: Language
    code: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tab: {
            '& svg': {
                width: '1.5em',
                height: '1.5em',
            }
        }
    }));

export function CodeSample(props: PropsWithChildren<{ height?: string }>) {
    const classes = useStyles();

    const monacoRef = useMonaco();
    const [monacoReady, setMonacoReady] = useState(false);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // NOTE: will happen if there is a single child.
    let children = props.children;
    if (children && !Array.isArray(children)) {
        children = [children];
    }

    const languages = (children as Array<React.ReactNode> || []).map((child: any): LanguageDef => {
        return {
            'id': child.props.language,
            'code': child.props.children.toString().trim(),
        }
    });

    const [currentTabName, setCurrentTabName] = useState(languages.length ? languages[0].id : '');
    const handleChangeTab = (event: React.ChangeEvent<{}>, selectedTabName: string) => {
        setCurrentTabName(selectedTabName);
    };

    // Effect: Register the languages in monaco.
    useEffect(() => {
        if (monacoRef) {
            setMonacoReady(true);
        }
        // NOTE: We only care if the monacoRef changes.
    }, [monacoRef]);

    const currentLanguage = languages.find((language: LanguageDef) => language.id === currentTabName);

    return <div>
        <AppBar position="static" color="default">
            <Tabs variant="fullWidth" value={currentTabName}
                onChange={handleChangeTab}
                indicatorColor="primary"
                aria-label="Tabs">
                {languages.map((language: LanguageDef) => {
                    return <Tab className={classes.tab} value={language.id} label={
                        <TabLabel icon={LANGUAGES[language.id].logo} title={LANGUAGES[language.id].name} />
                    } />
                })}
            </Tabs>
        </AppBar>

        {monacoReady && <Editor
            key={currentTabName}
            height={props.height ?? "50vh"}
            width='100%'
            defaultLanguage={LANGUAGES[currentLanguage?.id ?? 'zed'].monacoLang}
            theme={prefersDarkMode ? 'vs-dark' : 'vs'}
            value={currentLanguage?.code ?? ''}
            options={{
                readOnly: true,
                'semanticHighlighting.enabled': true,
                padding: {
                    top: 25,
                    bottom: 25,
                },
                mouseStyle: 'copy',
                minimap: {
                    enabled: false
                }
            }}
        />}</div>;
}