import { useEffect } from "react";

/**
 * useTitle is a helper hook for setting the title of the web app.
 */
export function useTitle(pieces: (string | undefined)[], deps: any[]) {
    useEffect(() => {
        const result = pieces.filter((piece: string | undefined) => !!piece).join(' | ');
        if (result) {
            document.title = result;
        }
    }, [deps]);
}