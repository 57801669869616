import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/link-context';


const buildClient = (apiEndpoint: string, getIDToken: () => Promise<string | undefined>) => {
    const httpLink = createHttpLink({
        uri: `${apiEndpoint}/graphql`,
    });

    const authLink = setContext(async (_, { headers }) => {
        const token = await getIDToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-and-network',
            },
        },
    });
    return client;
};

export default buildClient;
