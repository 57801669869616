import { gql } from "@apollo/client";
import { WITH_ERRORS_FRAGMENT } from "@code/trumpet/src/base";


/**
 * Deletes a token.
 * @param tokenId The ID of the token.
 */
export const DELETE_TOKEN = {
    query: gql`
    mutation DeleteToken($tokenId: ID!) {
      deleteToken(tokenId: $tokenId) {
        ${WITH_ERRORS_FRAGMENT}
      }
    }
  `,
    metadata: {
        workingMessage: 'Deleting token',
        errorMessage: 'Failed to delete token',
    },
    refetchQueries: ['ListClientTokens', 'ListUserTokens']
};

export interface DeleteTokenData {
    tokenId: string
}
