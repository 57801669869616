import { Organization } from "@code/authzed-common/src/types/organization";
import { useTitle } from "@code/trumpet/src/TitleHook";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { InfoBox } from "../components/InfoBox";
import OrgReference from "../components/OrgReference";
import PermissionsEditor from "../components/PermissionsEditor";
import { useSharedStyles } from "../sharedstyles";

export function OrgPermissions(props: { organization: Organization }) {
    const sharedClasses = useSharedStyles();
    const organization = props.organization;

    useTitle(['authzed', 'Permissions', organization.name], [organization]);

    return <div>
        <Breadcrumbs className={sharedClasses.breadcrumbs} aria-label="breadcrumb">
            <Link className={sharedClasses.link} to={`/organization/${organization.slug}`}>
                <OrgReference organization={organization} />
            </Link>
            <Typography color="textPrimary">Administrators</Typography>
        </Breadcrumbs>

        <InfoBox>
            The list of administrator users for the organization
        </InfoBox>

        <PermissionsEditor organization={organization} />
    </div>;
}