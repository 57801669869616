/**
 * User initiated events primarily used to log to Amplitude.
 * Event names generally follow the format: present-tense verb + object syntax
 */
export enum UserEvent {
    ViewedPage = 'Viewed Dashboard Page',
    ClickNewOrg = 'Click New Org',
    CreateNewOrg = 'Create New Org',
    ClickCreateTenant = 'Click Create Tenant',
    CreateNewClient= 'Create New Client',
    CreateNewToken = 'Create New Token',
    ClickQuickStart = 'Click Quick Start',
    ClickLoadPlayground = 'Click Load Playground',
    ClickUploadBundle = 'Click Upload Bundle',
    InteractGrantAccess = 'Interact Grant Access',
    ViewOrgTab = 'View Org Tab',
    // Tutorial
    SkipTutorial = 'Skip Dashboard Tutorial',
    CompleteTutorial = 'Complete Dashboard Tutorial',
    ViewTutorialStep = 'View Dashboard Tutorial Step',
}
