import { gql } from "@apollo/client";
import { WithErrors, WITH_ERRORS_FRAGMENT } from "@code/trumpet/src/base";

/**
 * Mutation: Invites a user to be a member of a tenant by e-mail address.
 * @param tenantId The id of the tenant.
 * @param emailAddress The email address being invited.
 * @param message The message for the invite.
 */
export const INVITE_TENANT_BY_EMAIL = {
    query: gql`
        mutation CreateTenantInvitation($tenantId: ID!, $emailAddress: String!, $message: String!) {
            createTenantInvitation(tenantId: $tenantId, emailAddress: $emailAddress, message: $message) {
                invitation {
                    id
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }`
    ,
    metadata: {
        workingMessage: 'Invite user to permissions system',
        errorMessage: 'Failed to invite user to permissions system',
    },
    refetchQueries: [],
};

/**
 * Mutation: Invites a user to be a member of an organization by e-mail address.
 * @param organizationId The id of the tenant.
 * @param emailAddress The email address being invited.
 * @param message The message for the invite.
 */
export const INVITE_ORGANIZATION_BY_EMAIL = {
    query: gql`
        mutation CreateOrganizationInvitation($organizationId: ID!, $emailAddress: String!, $message: String!) {
            createOrganizationInvitation(orgId: $organizationId, emailAddress: $emailAddress, message: $message) {
                invitation {
                    id
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }`
    ,
    metadata: {
        workingMessage: 'Invite user to organization',
        errorMessage: 'Failed to invite user to organization',
    },
    refetchQueries: [],
};


/**
 * Mutation: Accept an invitation to join a tenant or organization.
 * @param token The invitation token.
 */
export const ACCEPT_INVITATION = {
    query: gql`
        mutation AcceptInvitation($invitationToken: String!) {
            acceptInvitation(invitationToken: $invitationToken) {
                ${WITH_ERRORS_FRAGMENT}
                invitation {
                    organization {
                        id
                        slug
                    }
                    tenant {
                        id
                        slug
                        orgSlug
                    }
                }
            }
        }`,
    metadata: {
        workingMessage: 'Accepting invitation',
        errorMessage: 'Failed to accept invitation',
    },
    refetchQueries: [],
};

/**
 * AcceptInvitationParams are the parameters for the accept invitation call.
 */
export interface AcceptInvitationParams {
    invitationToken: string
}

export interface AcceptedInvitation {
    organization?: {
        id: string
        slug: string
    },
    tenant?: {
        id: string
        slug: string
        orgSlug: string
    },
}

export type AcceptInvitationData = {
    acceptInvitation: {
        invitation?: AcceptedInvitation,
    } & WithErrors
};