import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod, StripeError } from '@stripe/stripe-js';
import React, { useState } from 'react';

export interface StripePaymentFormProps {
    defaultEmailAddress: string | undefined | null
    paymentMethodCreated: (paymentMethod: PaymentMethod, billingEmail: string) => any;
    children: (renderForm: React.ReactChild, submitForm: (e: React.FormEvent<HTMLFormElement>) => void, isFormValid: boolean, isWorking: boolean) => React.ReactChild;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        fieldset: {
            border: 0,
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            alignItems: 'center',
            columnGap: theme.spacing(1),
            '& label': {
                padding: '10px',
                color: theme.palette.grey[400],
                fontWeight: 'bold',
                textAlign: 'right'
            },
        },
        billingField: {
            fontFamily: 'sans-serif',
            backgroundColor: 'transparent',
            padding: '4px',
            border: '0px',
            fontSize: '16px',
            color: theme.palette.text.primary,
            outline: 'none',
            '&::placeholder': {
                color: theme.palette.grey['500'],
            },
        },
        label: {
            fontWeight: 'bold',
            textAlign: 'right',
            padding: theme.spacing(2),
        },
    }));

export function StripePaymentForm(props: StripePaymentFormProps) {
    const classes = useStyles();
    const theme = useTheme();

    const stripe = useStripe();
    const elements = useElements();

    const stripeStyles = {
        base: {
            fontSize: '16px',
            color: theme.palette.text.primary,
            '::placeholder': {
                color: theme.palette.grey['500'],
            },
        },
        input: {
            padding: '4px',
        },
        invalid: {
            color: theme.palette.warning.main,
        },
    };

    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isExpiryValid, setIsExpiryValid] = useState(false);
    const [isCVVValid, setIsCVVValid] = useState(false);
    const [postalCode, setPostalCode] = useState('');
    const [emailAddress, setEmailAddress] = useState(props.defaultEmailAddress);
    const [stripeError, setStripeError] = useState<StripeError | undefined>(undefined);
    const [isWorking, setIsWorking] = useState(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsWorking(true);

        (async () => {
            if (elements === null || stripe === null) {
                setIsWorking(false);
                throw Error('Stripe is not loaded');
            }

            const cardNumberElement = elements.getElement(CardNumberElement)!;

            // Use your card Element with other Stripe.js APIs
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                billing_details: {
                    address: {
                        postal_code: postalCode,
                    },
                    email: emailAddress ?? ''
                },
                card: cardNumberElement,
            });

            if (error) {
                setIsWorking(false);
                setStripeError(error);
                return;
            }

            props.paymentMethodCreated(paymentMethod!, emailAddress!);
            setIsWorking(false);
        })();
    };

    const handleEmailAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(event.target.value);
    };

    const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostalCode(event.target.value);
    };

    const handleNumberChange = (event: any) => {
        setIsNumberValid(event.complete);
    };

    const handleExpiryChange = (event: any) => {
        setIsExpiryValid(event.complete);
    };

    const handleCVVChange = (event: any) => {
        setIsCVVValid(event.complete);
    };

    const isFormValid = isNumberValid && isExpiryValid && isCVVValid && !!postalCode && !!emailAddress;

    const rendered = props.children(
        <div className={classes.root}>
            {(stripe === undefined || elements === undefined) &&
                <Alert severity="info" icon={<CircularProgress size="1.5rem" />}>
                    Loading Stripe...
                </Alert>
            }
            {stripeError !== undefined &&
                <Alert severity="error">
                    {stripeError.message}
                </Alert>
            }
            {stripe !== undefined && elements !== undefined &&
                <fieldset className={classes.fieldset}>
                    <label>Credit Card Number</label>
                    <CardNumberElement
                        options={{
                            showIcon: true,
                            style: stripeStyles,
                        }}
                        onChange={handleNumberChange}
                    />
                    <label>Expiration</label>
                    <CardExpiryElement
                        options={{
                            style: stripeStyles,
                        }}
                        onChange={handleExpiryChange}
                    />
                    <label>CVV</label>
                    <CardCvcElement
                        options={{
                            style: stripeStyles,
                        }}
                        onChange={handleCVVChange}
                    />
                    <label>Postal/Zip Code</label>
                    <input className={classes.billingField} autoComplete="off" name="postalcode" type="text" placeholder="00000" value={postalCode} onChange={handlePostalCodeChange} disabled={isWorking} />
                    <label>Billing E-mail Address</label>
                    <input className={classes.billingField} autoComplete="off" name="email" type="email" id="email" pattern=".+@.+" value={emailAddress ?? ''} onChange={handleEmailAddressChange} placeholder="my@company.com" disabled={isWorking} />
                </fieldset>
            }
        </div>, handleSubmit, isFormValid, isWorking,
    );

    return <>{rendered}</>;
}
