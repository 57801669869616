import { gql } from "@apollo/client";
import { PAGE_INFO_FRAGMENT, PaginatedResponse, WithErrors, WITH_ERRORS_FRAGMENT } from "@code/trumpet/src/base";
import { TenantPermission } from "../services/permissionsservice";
import { Client } from "../types/client";
import { Tenant, TenantKind, TenantRole } from "../types/tenant";
import { User } from "../types/user";
import { USER_FRAGMENT } from "./authenticateduser";
import { ORGANIZATION_QUERIES } from "./organization";

/**
 * TENANT_FRAGMENT is the fragment containing all the fields to load for tenants.
 * 
 * All calls that (re)load tenants on the tenant page *must* use this fragment (at least)
 * to ensure that the Apollo cache plays nice. Otherwise, if a cached call requests
 * one of these fields (but the recent request does not contain said field), Apollo
 * will return undefined for the cached value, which is bad.
 */
const TENANT_FRAGMENT = `id
slug
name
description
kind
metricsViewToken
schema
definitions {
    path
}
addedClientDefaultRole
createdBy {
    ${USER_FRAGMENT}
}
permissions {
    ${Object.values(TenantPermission).join('\n')}
}`

/**
 * Retrieves a tenant by slug.
 * @param LookupTenantBySlugParams
 * @returns LookupTenantBySlugData
 */
export const LOOKUP_TENANT_BY_SLUG = {
    query: gql`
        query LookupTenantBySlug($organizationId: ID!, $tenantSlug: String!) {
            tenantBySlug(organizationId: $organizationId, tenantSlug: $tenantSlug) {
               ${TENANT_FRAGMENT}
            }
        }
    `,
    metadata: {
        workingMessage: 'Loading tenant',
        errorMessage: 'Failed to load tenant',
    }
};

export interface LookupTenantBySlugParams {
    /**
     * organizationId is the ID of the parent organization.
     */
    organizationId: string

    /**
     * tenantSlug is the slug of the tenant to lookup.
     */
    tenantSlug: string
}

export interface LookupTenantBySlugData {
    tenantBySlug: Tenant;
}


/**
 * Retrieves a tenant by ID.
 * @param LookupTenantByIDParams
 * @returns LookupTenantByIDData
 */
export const LOOKUP_TENANT_BY_ID = {
    query: gql`
        query LookupTenantById($tenantId: ID!) {
            tenantById(tenantId: $tenantId) {
                ${TENANT_FRAGMENT}
            }
        }
    `,
    metadata: {
        workingMessage: 'Loading tenant',
        errorMessage: 'Failed to load tenant',
    }
};

export interface LookupTenantByIDParams {
    /**
     * tenantId is the ID of the tenant to lookup.
     */
    tenantId: string
}

export interface LookupTenantByIDData {
    tenantById: Tenant;
}

/**
 * Query to lookup the tenants under an organization.
 */
export const ORGANIZATION_TENANTS_QUERY = {
    query: gql`
        query LookupOrganizationTenants($organizationId: ID, $first: Int!, $after: String) {
            organizationById(organizationId: $organizationId) {
                id
                tenants(first: $first, after: $after) {
                    ${PAGE_INFO_FRAGMENT}
                    edges {
                        node {
                            id
                            slug
                            name
                            description
                            kind
                            addedClientDefaultRole
                            createdBy {
                                ${USER_FRAGMENT}
                            }
                        }
                    }
                }
            }
        }
        `,
    metadata: {
        workingMessage: 'Loading tenants',
        errorMessage: 'Failed to load tenants'
    }
};

export interface LookupOrganizationTenantsParams {
    organizationId: string
    first: number
    after: string | undefined
}

export interface LookupOrganizationTenantsResponse {
    organizationById: {
        id: string
        tenants: PaginatedResponse<Tenant>
    }
}


/**
 * Creates a new tenant.
 * @param kind The kind of the tenant.
 * @param name The name of the tenant.
 * @param slug The unique slug for the tenant.
 * @param description The description for the tenant.
 * @returns CreatedTenant
 */
export const CREATE_TENANT = {
    query: gql`
    mutation CreateTenant($organizationId: ID!, $slug: String!, $kind: TenantKind!, $name: String!, $description: String!) {
      createTenant(organizationId: $organizationId, slug: $slug, kind: $kind, name: $name, description: $description) {
        ${WITH_ERRORS_FRAGMENT}
        tenant {
          id
          slug
          name
          description
          kind
          addedClientDefaultRole
          metricsViewToken
        }
        defaultToken
      }
    }
  `,
    metadata: {
        workingMessage: 'Creating tenant',
        errorMessage: 'Failed to create tenant',
    },
    refetchQueries: ['LookupOrganizationTenants', 'LookupOrganizationBySlug']
};

export interface CreateTenant {
    organizationId: string
    slug: string
    name: string
    kind: TenantKind
    description: string
}

export type CreatedTenantData = {
    tenant: Tenant
    defaultToken: string
} & WithErrors

export type CreatedTenant = {
    createTenant: CreatedTenantData
}



/**
 * Query to lookup the members of an tenant.
 */
export const TENANT_MEMBERS_QUERY = gql`
query LookupTenantMembers($tenantId: ID, $first: Int!, $after: String) {
    tenantById(tenantId: $tenantId) {
        id
        members(first: $first, after: $after) {
            ${PAGE_INFO_FRAGMENT}
            edges {
                node {
                    memberRole
                    ... on MemberDirectUser {
                        id
                        profile {
                            email
                            name
                            avatarUrl
                        }
                    }
                    ... on MemberIndirectUser {
                        id
                        profile {
                            email
                            name
                            avatarUrl
                        }
                    }
                    ... on MemberClient {
                        id
                        title
                        note
                    }
                }
            }
        }
    }
}
`

/**
 * Mutation: Sets or changes the role of a tenant member.
 * @param tenantId The id of the tenant.
 * @param memberId The id of the member (must be a user or client).
 * @param newRole The new role for the member in the tenant.
 * @param preventRoleChange Whether this role change is a new member.
 */
export const SET_TENANT_MEMBER_ROLE = {
    query: gql`
        mutation SetTenantMembershipAndRole($tenantId: ID!, $memberId: ID!, $preventRoleChange: Boolean, $newRole: TenantRoleType!) {
            setTenantMembershipAndRole(tenantId: $tenantId, memberId: $memberId, preventRoleChange: $preventRoleChange, newRole: $newRole) {
                tenant {
                    id
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }
        `,
    metadata: {
        workingMessage: 'Changing member role in tenant',
        errorMessage: 'Failed to add or change member in tenant',
    },
    refetchQueries: ['LookupTenantMembers'],
};

export interface SetTenantMemberRoleParams {
    tenantId: string
    memberId: string
    newRole: TenantRole
    preventRoleChange: boolean
}

export type SetTenantMemberRoleResponse = {
    setTenantMembershipAndRole: {
        tenant: Tenant
    } & WithErrors
}

/**
 * Mutation: Removes a member from a tenant.
 * @param tenantId The id of the tenant.
 * @param memberId The id of the member (must be a user or client).
 */
export const REMOVE_TENANT_MEMBER = {
    query: gql`
        mutation RemoveTenantMember($tenantId: ID!, $memberId: ID!) {
            removeTenantMember(tenantId: $tenantId, memberId: $memberId) {
                tenant {
                    id
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }`,
    metadata: {
        workingMessage: 'Removing member from tenant',
        errorMessage: 'Failed to remove member from tenant',
    },
    refetchQueries: ['LookupTenantMembers'],
};

/**
 * Retrieves possible tenant members matching the given query.
 * @param prefix The prefix string to use for matching.
 */
export const AUTOCOMPLETE_POSSIBLE_TENANT_MEMBERS_QUERY = gql`
  query AutocompletePossibleTenantMembers($tenantId: ID, $prefix: String!) {
    autocompletePossibleTenantMembers(tenantId: $tenantId, prefix: $prefix) {
        id
        __typename
        ... on ResultClient {
            title
        }
        ... on ResultUser {
            profile {
                email
                name
                avatarUrl
            }
        }
    }
  }
`

export type ResultClient = Client & {
    __typename: "ResultClient"
}

export type ResultUser = User & {
    __typename: "ResultUser"
}

export type PossibleTenantMember = ResultClient | ResultUser


/**
 * Mutation: Populate a tenant with schema and relationships.
 * @param tenantId The id of the tenant.
 * @param schema The schema to populate.
 * @param relationships The relationships to populate.
 */
export const POPULATE_TENANT = {
    query: gql`
        mutation PopulateTenant($tenantId: ID!, $schema: String!, $relationships: [String]!) {
            populateTenant(tenantId: $tenantId, schema: $schema, relationships: $relationships) {
                tenant {
                    ${TENANT_FRAGMENT}
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }
        `,
    metadata: {
        workingMessage: 'Populating tenant',
        errorMessage: 'Failed to populate tenant',
    },
    refetchQueries: ['LookupTenantBySlug', 'LookupTenantById'],
};

export interface PopulateTenantParameters {
    tenantId: string
    schema: string
    relationships: string[]
}

/**
 * Mutation: Deletes a (development) tenant.
 * @param tenantId The id of the tenant.
 */
export const DELETE_TENANT = {
    query: gql`
        mutation DeleteTenant($tenantId: ID!) {
            deleteTenant(tenantId: $tenantId) {
                ${WITH_ERRORS_FRAGMENT}
            }
        }
        `,
    metadata: {
        workingMessage: 'Deleting tenant',
        errorMessage: 'Failed to delete tenant',
    },
    refetchQueries: [
        'LookupTenantBySlug',
        'LookupTenantById',
        'LookupOrganizationTenants',
        ...ORGANIZATION_QUERIES,
    ],
};

export interface DeleteTenantParameters {
    tenantId: string
}
