import { gql } from "@apollo/client";
import { UserProfile } from "@code/authzed-common/src/types/authenticateduser";
import { PAGE_INFO_FRAGMENT, WITH_ERRORS_FRAGMENT } from "@code/trumpet/src/base";
import { Organization } from "../types/organization";
import { BASIC_ORG_FIELDS_FRAGMENT } from "./organization";

/**
 * USER_FRAGMENT holds the basic user fields.
 */
export const USER_FRAGMENT = `
id
profile {
  email
  name
  avatarUrl
}
`

/**
 * Retrieves information about the currently authenticated user, including their
 * their notifications.
 * @returns AuthenticatedUserData
 */
export const USER_DATA_QUERY = gql`
  query GetCurrentUserInformation {
    authenticatedUser {
      ${USER_FRAGMENT}
      authnId
      recentOrgs {
        ${BASIC_ORG_FIELDS_FRAGMENT}
      }
    }
  }
`;

/**
 * The data returned from the USER_DATA_QUERY.
 */
export interface AuthenticatedUserData {
  /**
   * authenticatedUser is information about the currently authenticated user.
   */
  authenticatedUser: AuthenticatedUser | null
}

/**
 * The authenticated user's information.
 */
export interface AuthenticatedUser {
  /**
   * id is the ID of the user.
   */
  id: string

  /**
   * authnId is the authentication system ID for the user.
   */
  authnId: string

  /**
   * profile is the profile information for the authenticated user.
   */
  profile?: UserProfile

  /**
   * recentOrgs are the recently accessed organizations of the user, if any.
   */
  recentOrgs: Organization[]
}


/**
 * Retrieves the tokens owned by a user.
 */
export const USER_TOKENS_QUERY = {
  query: gql`
      query ListUserTokens($first: Int!, $after: String) {
        authenticatedUser {
          tokens(first: $first, after: $after) {
            ${PAGE_INFO_FRAGMENT}
            edges {
              node {
                id
                title
                note
                createdOn
                createdBy {
                  ${USER_FRAGMENT}
                }
              }
            }
          }
        }
      }
    `,
  metadata: {
    workingMessage: 'Loading user tokens',
    errorMessage: 'Failed to load user tokens',
  }
};

/**
 * Creates a new user token.
 * @param title The title of the token.
 * @param note The note for the token.
 */
export const CREATE_USER_TOKEN = {
  query: gql`
  mutation CreateUserToken($title: String!, $note: String!) {
    createUserToken(title: $title, note: $note) {
      ${WITH_ERRORS_FRAGMENT}
      token {
        id
      }
      fullToken
    }
  }
`,
  metadata: {
    workingMessage: 'Creating user token',
    errorMessage: 'Failed to create user token',
  },
  refetchQueries: ['ListUserTokens']
};
