import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            textAlign: 'center',
            color: theme.palette.grey[500],
        },
    }));

/**
 * Defines the properties for the EmptyView.
 */
interface EmptyViewProps {
    /**
     * The message to display.
     */
    message: string | React.ComponentType<any> | React.ReactElement;
}

export default function EmptyView(props: EmptyViewProps) {
    const classes = useStyles();

    return <div className={classes.root}>
        <Typography variant="subtitle1">{props.message}</Typography>
    </div>
}