import { DeleteTokenData, DELETE_TOKEN } from "@code/authzed-common/src/queries/token";
import { UserEvent } from '@code/authzed-common/src/types/events';
import { CreatedTokenData, Token } from "@code/authzed-common/src/types/token";
import { AmplitudeContext } from '@code/trumpet/src/AmplitudeClient';
import { PaginatedResponse } from "@code/trumpet/src/base";
import { QueryInformation } from "@code/trumpet/src/Bound";
import BoundTable from "@code/trumpet/src/BoundTable";
import { useConfirmDialog } from "@code/trumpet/src/ConfirmDialogProvider";
import ExternalLink from '@code/trumpet/src/ExternalLink';
import { DocumentNodeAndMetadata, useManagedMutation, useManagedQuery } from "@code/trumpet/src/hooks";
import TabPanel from '@code/trumpet/src/TabPanel';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Tab, Tabs, Typography } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Alert from "@material-ui/lab/Alert";
import React, { useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import AppConfig from '../services/configservice';
import { InfoBox } from "./InfoBox";
import { UserReference } from "./UserReference";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        slug: {
            fontFamily: 'Roboto Mono, monospace'
        },
        buttonBar: {
            textAlign: 'right',
            display: 'none',
            marginBottom: theme.spacing(0.5),
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        createdToken: {
            display: 'block',
            '& input': {
                fontSize: '85%',
                fontFamily: 'Roboto Mono, monospace',
            }
        },
        createdTokenMessage: {
            marginBottom: theme.spacing(1.5),
        },
        actionButton: {
            padding: '4px',
            minWidth: '1rem'
        },
        adjacent: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            columnGap: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '510px',
        },
        tokenCaption: {
            marginTop: theme.spacing(1.25),
            display: 'block',
            color: theme.palette.grey[500],
        },
        tokenTabs: {
            marginTop: theme.spacing(2.5),
        },
        tokenTabPanel: {
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
        singleToken: {
            marginTop: theme.spacing(2.5),
        }
    }));


export interface TokenManagerProps {
    description: string
    tokenPrefix: string
    lookupTokenQuery: QueryInformation
    createTokenMutation: DocumentNodeAndMetadata
    createTokenMethodName: string
    createVariables?: Record<string, any>
    defaultTokenTitle?: string
    defaultTokenNote?: string
}

export function CreatedTokenDialog(props: { createdToken: string | undefined, tenantSlug?: string | undefined, message?: string | undefined, onClose: () => void }) {
    const [createdTokenCopied, setCreatedTokenCopied] = useState(false);
    const classes = useStyles();

    const zedCommand = `zed context set ${props.tenantSlug ?? ''} ${AppConfig().authzed.apiEndpoint || ''} ${props.createdToken || ''}`;

    const [currentTabName, setCurrentTabName] = useState('token');

    const handleChangeTab = (event: React.ChangeEvent<{}>, selectedTabName: string) => {
        setCurrentTabName(selectedTabName);
    };

    return <Dialog
        open={props.createdToken !== undefined}
        onClose={props.onClose}
        disableBackdropClick={!createdTokenCopied}
        disableEscapeKeyDown={!createdTokenCopied}
    >
        <DialogTitle>Token Created</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.message && <Typography className={classes.createdTokenMessage} variant="subtitle1">{props.message}</Typography>}
                <Alert severity="warning">
                    <strong>Copy the token now, as it will not be displayed again</strong>
                </Alert>

                {!props.tenantSlug && <>
                    <div className={classes.singleToken}>
                        <TextField variant="outlined" InputProps={{
                            readOnly: true,
                        }}
                            label="Token" fullWidth className={classes.createdToken} value={props.createdToken} />
                    </div>
                    <Typography className={classes.tokenCaption} variant="caption">Specify the token when initializing an Authzed Client Library to authenticate it to interact with the permissions system.</Typography>
                </>
                }

                {props.tenantSlug && <>
                    <AppBar className={classes.tokenTabs} position="static" color="default">
                        <Tabs variant="fullWidth" value={currentTabName}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            aria-label="Tabs">
                            <Tab value="token" label="Token" />
                            <Tab value="cli" label="Zed CLI Tool" />
                        </Tabs>
                    </AppBar>

                    <TabPanel className={classes.tokenTabPanel} index="token" value={currentTabName}>
                        <div className={classes.adjacent}>
                            <TextField variant="outlined" InputProps={{
                                readOnly: true,
                            }}
                                label="Token" fullWidth className={classes.createdToken} value={props.createdToken} />
                            <CopyToClipboard text={props.createdToken || ''} onCopy={() => setCreatedTokenCopied(true)}>
                                <Button variant={createdTokenCopied ? "text" : "contained"} color={createdTokenCopied ? "default" : "primary"} onClick={() => setCreatedTokenCopied(true)}>
                                    {createdTokenCopied ? "Copied" : "Copy"}
                                </Button>
                            </CopyToClipboard>
                        </div>
                        <Typography className={classes.tokenCaption} variant="caption">Use this token when initializing an Authzed Client Library to authenticate it to interact with the permissions system.</Typography>
                    </TabPanel>
                    <TabPanel className={classes.tokenTabPanel} index="cli" value={currentTabName}>
                        <div className={classes.adjacent}>
                            <TextField variant="outlined" InputProps={{
                                readOnly: true,
                            }}
                                label="zed CLI token command" fullWidth className={classes.createdToken} value={zedCommand} />
                            <CopyToClipboard text={zedCommand} onCopy={() => setCreatedTokenCopied(true)}>
                                <Button variant={createdTokenCopied ? "text" : "contained"} color={createdTokenCopied ? "default" : "primary"} onClick={() => setCreatedTokenCopied(true)}>
                                    {createdTokenCopied ? "Copied" : "Copy"}
                                </Button>
                            </CopyToClipboard>
                        </div>
                        <Typography className={classes.tokenCaption} variant="caption">Use this command to configure the <code>zed</code> tool to use this token when interacting with the permissions system. Don't have the <code>zed</code> CLI tool? <ExternalLink to="https://github.com/authzed/zed#installation">Install zed</ExternalLink></Typography>
                    </TabPanel>
                </>}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {!props.tenantSlug && <CopyToClipboard text={props.createdToken || ''} onCopy={() => setCreatedTokenCopied(true)}>
                <Button variant={createdTokenCopied ? "text" : "contained"} color={createdTokenCopied ? "default" : "primary"} onClick={() => setCreatedTokenCopied(true)}>
                    {createdTokenCopied ? "Token Copied" : "Copy Token"}
                </Button>
            </CopyToClipboard>}

            <Button variant={!createdTokenCopied ? "text" : "contained"} disabled={!createdTokenCopied} onClick={props.onClose}>
                Close
            </Button>
        </DialogActions>
    </Dialog>
}

/**
 * TokenManager displays the tokens page/panel for a user or client.
 */
export function TokenManager(props: TokenManagerProps) {
    const amplitudeClient = useContext(AmplitudeContext);
    const classes = useStyles();
    const { showConfirm } = useConfirmDialog();
    const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean | undefined>(undefined);
    const [newTokenTitle, setNewTokenTitle] = useState('');
    const [newTokenNote, setNewTokenNote] = useState('');
    const [createdToken, setCreatedToken] = useState<CreatedTokenData | undefined>(undefined);

    const [createToken, { running: creatingToken }] = useManagedMutation(props.createTokenMutation)
    const [deleteToken] = useManagedMutation<any, DeleteTokenData>(DELETE_TOKEN)

    const { data: initialTokenData } = useManagedQuery({
        query: props.lookupTokenQuery.gql,
        metadata: {
            workingMessage: 'Checking for tokens',
            errorMessage: 'Could not check for tokens'
        },
    }, {
        variables: { ...props.lookupTokenQuery.variables, first: 1 },
    });

    useEffect(() => {
        const getRecords = function (data: any): PaginatedResponse<any> {
            let currentData = data;
            props.lookupTokenQuery.recordsKey.forEach(function (keyName) {
                currentData = currentData[keyName];
            });
            return currentData;
        };

        if (isCreateDialogOpen === undefined && initialTokenData !== undefined && getRecords(initialTokenData).edges.length === 0) {
            setNewTokenTitle(props.defaultTokenTitle || 'default_token');
            setNewTokenNote(props.defaultTokenNote || 'The default token');
            setCreateDialogOpen(true);
        }

        // NOTE: We do not want to refire if the props change, as they should be static.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreateDialogOpen, initialTokenData]);

    const showRevokeToken = (token: Token) => {
        (async () => {
            const [response] = await showConfirm({
                'title': `Revoke token ${token.title}?`,
                'content': <div>
                    Are you sure you want to revoke token <code>{token.title}</code>? It will be
                    permanently deleted and all callers using it will no longer function.
                </div>,
                'buttons': [
                    { 'title': 'Cancel', 'value': 'undefined' },
                    { 'title': `Revoke ${token.title}`, 'variant': 'contained', 'color': 'secondary', 'value': 'revoke' },
                ],
            });
            if (response !== 'revoke') {
                return
            }

            await deleteToken({
                variables: {
                    tokenId: token.id
                }
            })
        })();
    };

    const startCreateToken = () => {
        (async () => {
            const result = await createToken({
                variables: {
                    ...(props.createVariables || {}),
                    title: newTokenTitle,
                    note: newTokenNote
                }
            });
            if (result && result.data && result.data[props.createTokenMethodName].ok) {
                setCreateDialogOpen(false);
                setCreatedToken(result.data[props.createTokenMethodName]);
            }
        })();
    };

    const showCreateDialog = () => {
        setNewTokenTitle('');
        setNewTokenNote('');
        setCreatedToken(undefined);
        setCreateDialogOpen(true);
    };

    const handleCreateToken = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        amplitudeClient?.logEvent(UserEvent.CreateNewToken);
        startCreateToken();
    }

    return <div>
        <InfoBox>
            Tokens are used to authenticate access to the Authzed API. {props.description}
        </InfoBox>
        <BoundTable<Token>
            columns={[
                {
                    id: "icon",
                    title: "",
                    render: (token: Token) => {
                        return <FontAwesomeIcon icon={faTicketAlt} />
                    }
                },
                {
                    id: "title",
                    title: "Token Prefix",
                    cellStyle: {
                        width: '25%',
                    },
                    render: (token: Token) => {
                        return <code>{`${props.tokenPrefix}_${token.title}`}</code>
                    }
                },
                {
                    id: "note", title: "Note",
                    cellStyle: {
                        width: '35%',
                    },
                    render: (token: Token) => {
                        return token.note
                    }
                },
                {
                    id: "createdon", title: "Created On",
                    cellStyle: {
                        width: '25%',
                    },
                    render: (token: Token) => {
                        return <div style={{ textAlign: 'center' }}>
                            {new Date(token.createdOn).toLocaleString()}
                        </div>;
                    }
                },
                {
                    id: "createdby", title: "Created By",
                    cellStyle: {
                        width: '15%',
                    },
                    render: (token: Token) => {
                        return <div style={{ textAlign: 'center' }}>
                            <UserReference user={token.createdBy} />
                        </div>;
                    }
                },
                {
                    id: "actions", title: "", isSortable: false,
                    render: (token: Token) => {
                        return <Tooltip title="Revoke" aria-label="revoke">
                            <span>
                                <Button className={classes.actionButton} onClick={() => showRevokeToken(token)}><DeleteForeverIcon /></Button>
                            </span>
                        </Tooltip>;
                    }
                },
            ]}
            query={
                props.lookupTokenQuery
            }
            toolbar={
                <Box className={classes.buttonBar} onClick={showCreateDialog}>
                    <Button variant="contained" color="primary" aria-label="add">
                        Create Token
                    </Button>
                </Box>
            }
        />

        {createdToken?.fullToken && <CreatedTokenDialog createdToken={createdToken?.fullToken} onClose={() => setCreatedToken(undefined)} />}

        <Dialog
            open={!!isCreateDialogOpen}
            onClose={() => setCreateDialogOpen(false)}
            disableBackdropClick={creatingToken}
            disableEscapeKeyDown={creatingToken}
        >
            <form onSubmit={handleCreateToken}>
                <DialogTitle>Create new token</DialogTitle>
                <DialogContent>
                    {creatingToken && <CircularProgress />}
                    {!creatingToken && <DialogContentText>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="token-title">Token Title</InputLabel>
                            <Input placeholder="my_token"
                                className={classes.slug}
                                value={newTokenTitle}
                                autoFocus={!newTokenTitle}
                                inputProps={{ 'pattern': '^[a-z][a-z0-9_]{0,23}?[a-z0-9]$', 'maxlength': 25, 'minlength': 1 }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewTokenTitle(event.target.value)} />
                            <FormHelperText id="token-title-text">Alphanumeric and lowercase, between 2-25 characters. This will appear in the generated token.</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="token-note">Token Note</InputLabel>
                            <Input placeholder="An important token"
                                value={newTokenNote}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewTokenNote(event.target.value)} />
                            <FormHelperText id="token-note-text">A descriptive note for the token.</FormHelperText>
                        </FormControl>
                    </DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button disabled={creatingToken} onClick={() => setCreateDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary"
                        disabled={creatingToken || !newTokenTitle || !newTokenNote}
                        autoFocus={Boolean(newTokenTitle && newTokenNote)}>
                        Create Token
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </div>;
}
