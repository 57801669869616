import { CreatedOrganizationData, CreateOrganizationData, CREATE_ORGANIZATION } from '@code/authzed-common/src/queries/organization';
import { UserEvent } from '@code/authzed-common/src/types/events';
import { Organization, OrganizationKind } from '@code/authzed-common/src/types/organization';
import { AmplitudeContext } from '@code/trumpet/src/AmplitudeClient';
import { useGoogleAnalytics } from '@code/trumpet/src/GoogleAnalyticsHook';
import { useManagedMutation } from '@code/trumpet/src/hooks';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { FormEvent, useContext, useState } from "react";
import { extractOrganizationInformation } from '../services/domainservice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        slug: {
            fontFamily: 'Roboto Mono, monospace'
        },
        paper: {
            padding: theme.spacing(2),
        },
        buttons: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'center'
        },
        button: {
            marginRight: theme.spacing(2),
        },
        form: {

        },
        fieldset: {
            border: '0px',
            '& > div': {
                paddingBottom: theme.spacing(2),
            }
        },
        dropzoneRoot: {
            minHeight: '0px',
            paddingBottom: '10px!important',
        },
        dropzoneText: {
            fontSize: theme.typography.fontSize,
            margin: theme.spacing(1),
        },
        dropzoneIcon: {
            width: '20px',
            height: '20px'
        },
        dropzonePreviewItem: {
            '& img': {
                maxWidth: '48px',
                maxHeight: '48px',
            }
        }
    }));

/**
 * CreateOrgFormProps are the properties for the CreateOrgForm.
 */
export interface CreateOrgFormProps {
    /**
     * orgCreated is the callback invoked once the organization has been created.
     */
    orgCreated: (org: Organization) => any

    /**
     * containerElement, if specified, is the constructor to use to create the container.
     */
    containerElement?: any;

    /**
     * orgDefaultEmail is the email to use for org defaults, if any.
     */
    orgDefaultEmail?: string | undefined | null
}

/**
 * CreateOrgForm provides a form for creating an organization.
 */
export default function CreateOrgForm(props: CreateOrgFormProps) {
    const classes = useStyles();
    const { pushEvent } = useGoogleAnalytics();
    const amplitudeClient = useContext(AmplitudeContext);

    const [createOrganization, { running: creating }] = useManagedMutation<
        CreatedOrganizationData,
        CreateOrganizationData
    >(CREATE_ORGANIZATION);

    const [logoFileContents, setLogoFileContents] = useState<string | undefined>(undefined);
    const orgInfo = props.orgDefaultEmail ? extractOrganizationInformation(props.orgDefaultEmail) : undefined;

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        (async () => {
            const data = new FormData(event.target as HTMLFormElement);
            const result = await createOrganization({
                variables: {
                    slug: data.get('org-slug') as string,
                    name: data.get('org-name') as string,
                    logo: logoFileContents || '',
                    kind: OrganizationKind.STANDARD,
                    companyDomain: orgInfo?.companyDomain ?? '',
                }
            });
            if (result !== undefined && result.data?.createOrganization.ok) {
                pushEvent('create-org', {
                    organizationId: result.data?.createOrganization.organization.id,
                });

                props.orgCreated(result.data?.createOrganization.organization!);

                amplitudeClient?.logEvent(UserEvent.CreateNewOrg);
            }
        })();
    };

    const handleLogoDropped = (files: File[]) => {
        (async () => {
            if (files.length) {
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = function (e) {
                    setLogoFileContents(reader.result as string);
                };
            } else {
                setLogoFileContents(undefined);
            }
        })();
    };

    const orgDefaultInput = orgInfo?.id;
    const slugRegex = new RegExp('^[a-z][a-z0-9]{3,63}$');
    const orgDefault = orgDefaultInput && slugRegex.test(orgDefaultInput.toLowerCase()) ? orgDefaultInput.toLowerCase() : '';

    const ContainerElement = props.containerElement || Paper;
    return <div className={classes.root}>
        <ContainerElement className={classes.paper} >
            <form className={classes.form} onSubmit={handleSubmit}>
                <fieldset className={classes.fieldset} disabled={creating}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="org-slug">Organization ID</InputLabel>
                        <Input className={classes.slug} id="org-slug" name="org-slug" placeholder="myorganization" aria-describedby="org-name-text" autoComplete="organization-slug" defaultValue={orgDefault} inputProps={{ pattern: "^[a-z][a-z0-9]{3,63}$" }} required />
                        <FormHelperText id="org-slug-text">A globally unique <strong>lowercased alphanumeric</strong> id for your organization. Typically, this would be your organization's domain name (without the tld). <strong>This cannot be changed.</strong></FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="org-name">Organization Name</InputLabel>
                        <Input id="org-name" name="org-name" aria-describedby="org-name-text" defaultValue={orgDefault} placeholder="My Organization" autoComplete="organization-name" required />
                        <FormHelperText id="org-name-text">The name of the organization. This can be changed later.</FormHelperText>
                    </FormControl>
                    <DropzoneArea
                        classes={{ root: classes.dropzoneRoot, text: classes.dropzoneText, icon: classes.dropzoneIcon }}
                        previewGridClasses={{ item: classes.dropzonePreviewItem }}
                        onChange={handleLogoDropped}
                        acceptedFiles={['image/svg+xml']}
                        showPreviews={false}
                        showPreviewsInDropzone={true}
                        filesLimit={1}
                        maxFileSize={500 * 1024}
                        dropzoneText="Drag and drop logo SVG"
                    />
                    <FormHelperText id="logo-text">The optional logo to use for the organization. Must be an SVG and no greater than 500K. This can be changed later.</FormHelperText>
                </fieldset>
                <div className={classes.buttons}>
                    <Button className={classes.button} disabled={creating} variant="contained" color="primary" type="submit">
                        Create Organization
                    </Button>
                    {creating && <CircularProgress />}
                </div>
            </form>
        </ContainerElement>
    </div>;
}
