import { gql } from '@apollo/client';
import { Edge } from '@code/trumpet/src/base';
import { MutationInformation, QueryInformation } from '@code/trumpet/src/Bound';
import BoundAutocomplete from '@code/trumpet/src/BoundAutocomplete';
import BoundTable from '@code/trumpet/src/BoundTable';
import { useConfirmDialog } from '@code/trumpet/src/ConfirmDialogProvider';
import { useGoogleAnalytics } from '@code/trumpet/src/GoogleAnalyticsHook';
import { useManagedMutationWithMetadata } from '@code/trumpet/src/hooks';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import * as EmailValidator from 'email-validator';
import { useConfirm } from 'material-ui-confirm';
import React, { useState } from 'react';
import { IDed } from './types';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.text.primary,
        },
        managerButton: {
            padding: '4px',
            minWidth: '1rem'
        },
        autocompleteResult: {
            display: 'flex',
            alignItems: 'center'
        },
        autocompleteTitle: {
            marginLeft: theme.spacing(0.5)
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        logo: {
            display: 'flex',
            alignItems: 'center',
            width: '1em',
            height: '1em',
            marginRight: theme.spacing(1),
            '& .MuiAvatar-root': {
                width: '1em',
                height: '1em',
            }
        },
        inviteEmailBtn: {
            width: '100%',
            marginTop: theme.spacing(1)
        },
        inviteSuccess: {
            marginRight: theme.spacing(1),
            alignItems: 'center'
        },
    }));


/**
 * MemberRole represents a single possible role for a member in the list.
 */
interface MemberRole {
    /**
     * id is the value ID for the role.
     */
    id: any

    /**
     * label is the label of the role.
     */
    label: string

    /**
     * isManager returns whether this role is a manager role.
     */
    isManager: boolean

    /**
     * selectedClassName, if specified, is the custom CSS class for the role button when
     * selected.
     */
    selectedClassName?: string
}

/**
 * MemberKind represents a single kind of member supported in the list.
 */
interface MemberKind<M extends Member> {
    /**
     * id is the unique ID for this member kind.
     */
    id: any

    /**
     * title is a human-readable title for the member kind.
     */
    title: string

    /**
     * getName returns the human-readable name of the member of this kind.
     */
    getName: (m: M) => string;

    /**
     * renderLogo renders the user-visible logo for displaying a member of this kind.
     */
    renderLogo: (m: M) => React.ReactNode;

    /**
     * renderInline renders the user-visible UI for displaying a member of this kind.
     */
    renderInline: (m: M) => React.ReactNode;

    /**
     * renderDescription renders the user-visible description for a member of this kind.
     */
    renderDescription: (m: M) => React.ReactNode;

    /**
     * renderToBeRemoved is the custom user-visible UI to display when
     * confirming that the member should be removed from the list.
     */
    renderToBeRemoved: (m: M) => React.ReactNode;

    /**
     * getInheritanceSource, if implemented, returns whether the membership was inherited. Inherited
     * membership will not be removable.
     */
    getInheritanceSource?: (m: M) => string | undefined;
}

/**
 * The properties for the base membership editor control.
 */
interface MembershipEditorBaseProps<M extends Member, A> {
    /**
     * lookupQuery is the query to run to populate the editor with members.
     */
    lookupQuery: QueryInformation

    /**
     * autocompleteQuery is the query to run to populate the autocomplete.
     */
    autocompleteQuery: QueryInformation

    /**
     * translateAutocompleteResult translates an autocomplete result into a member.
     */
    translateAutocompleteResult: (result: A) => M;

    /**
     * removeMemberMutation is the mutation to run to remove a member.
     */
    removeMemberMutation: MutationInformation

    /**
     * changeMemberRoleMutation is the mutation to run to change the role of a member.
     */
    changeMemberRoleMutation: MutationInformation

    /**
     * inviteEmailMutation is the mutation to run to invite an email, if any.
     */
    inviteEmailMutation?: MutationInformation

    /**
     * canMutate indicates whether the current user can mutate.
     */
    canMutate: boolean

    /**
     * removalShortText is the short text (one word) to describe the managed object.
     * @example group
     */
    removalShortText: string

    /**
     * removalLongText is the long text (sentence fragement) to describe what happens if a user
     * or group is removed from managing the object.
     * @example any resources shared with this group
     */
    removalLongText: string

    /**
     * autocompleteText is the text or element to display in the autocomplete as a placeholder.
     * @example Share with a user or group
     */
    autocompleteText: string

    /**
     * enterText is the text or element to display in the autocomplete as a placeholder when there
     * are no options availabe.
     * @example Share with a user or group
     */
    enterText?: string | React.ReactNode

    /**
     * getKindForMember returns the kind associated with the given member.
     */
    getKindForMember: (m: M) => MemberKind<M>

    /**
     * getRoleForMember returns the role associated with the given member.
     */
    getRoleForMember: (m: M) => MemberRole

    /**
     * memberRoles are the roles supported by members.
     */
    memberRoles: MemberRole[]

    /**
     * memberKinds are the kinds of supported members.
     */
    memberKinds: MemberKind<M>[]

    /**
     * defaultRole is the default role to give to members when they are added. This will
     * be passed directly to the add mutation.
     */
    defaultRole: MemberRole

    /**
     * allowedRoles, if specified, are the allowed roles that can be granted to members via
     * this control.
     */
    allowedRoles?: MemberRole[]

    /**
     * adornmentIcon is, if specified, the icon to display next to the autocomplete box
     * on the editor.
     */
    adornmentIcon?: React.ReactNode

    /**
     * removalSuffixText is the text to be displayed at the end of the removal dialog.
     */
    removalSuffixText?: string

    /**
     * isManager returns whether the member is a manager. If not specified, any member
     * with a manager role matches.
     */
    isManager?: (m: M) => boolean;
}

/**
 * Member represents a member in the list.
 */
export interface Member {
    /**
     * id is the unique of this member, for adding, removing and setting permissions.
     * This ID *MUST* be unique across *ALL* members, even those of different kinds.
     */
    id: string
}

interface DeleteMemberDialogProps<M extends Member> {
    member: M
    memberKind: MemberKind<M>
    removeMember: (member: M) => any;
    removalShortText: string
    removalLongText: string
    removalSuffixText: string | undefined
}

function DeleteMemberDialog<M extends Member>(props: DeleteMemberDialogProps<M>) {
    const [open, setOpen] = useState(true);

    const handleRemove = () => {
        props.removeMember(props.member);
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const name = props.memberKind.getName(props.member);
    const text = props.memberKind.renderToBeRemoved(props.member);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Remove {name} from this {props.removalShortText}?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text} will lose access to {props.removalLongText}
                        {props.removalSuffixText !== undefined ? props.removalSuffixText : ', unless they have access via another group or directly'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={handleRemove} color="secondary" variant="contained">
                        Remove {name}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

interface MembershipMetadata {
    hasSingleManager: boolean
}

/**
 * MembershipEditorBase is an editor for any object that has members and whose permissions can
 * be changed.
 * 
 * This is typically used as the basis of a configured MembershipEditor in an importing application.
 */
export default function MembershipEditorBase<M extends Member, A extends IDed>(props: MembershipEditorBaseProps<M, A>) {
    const confirm = useConfirm();
    const classes = useStyles();
    const { pushEvent } = useGoogleAnalytics();

    const [removeMember] = useManagedMutationWithMetadata(props.removeMemberMutation.gql,
        {
            workingMessage: 'Removing member',
            errorMessage: 'Could not remove member'
        },
        {
            refetchQueries: props.removeMemberMutation.refetchQueries
        });

    const [changeMemberRole] = useManagedMutationWithMetadata(props.changeMemberRoleMutation.gql,
        {
            workingMessage: 'Changing member role',
            errorMessage: 'Could not change member role'
        },
        {
            refetchQueries: props.changeMemberRoleMutation.refetchQueries
        });

    const [askDeleteMember, setMemberAskToDelete] = useState<M | undefined>(undefined);
    const [askDeleteIndex, setAskDeleteIndex] = useState(0);

    const showDeleteMember = (member: M) => {
        setMemberAskToDelete(member);
        setAskDeleteIndex(askDeleteIndex + 1);
    };

    const handleRemoveMember = (member: M) => {
        (async () => {
            const result = await removeMember({
                variables: {
                    ...props.removeMemberMutation.variables,
                    memberId: member.id,
                }
            });

            if (result !== undefined) {
                pushEvent('remove-member', {
                    ...props.removeMemberMutation.variables,
                    memberId: member.id
                });
            }
        })();
    };

    const handleAddMember = (result: A) => {
        const member = props.translateAutocompleteResult(result);
        (async () => {
            const result = await changeMemberRole({
                variables: {
                    ...props.changeMemberRoleMutation.variables,
                    memberId: member.id,
                    newRole: props.defaultRole.id,
                    preventRoleChange: true
                }
            });

            if (result !== undefined) {
                pushEvent('add-member', {
                    ...props.removeMemberMutation.variables,
                    memberId: member.id
                });
            }

            setIsGrantDialogOpen(false);
        })();
    };

    const handleMemberRoleToggle = (member: M, newRole: MemberRole) => {
        if (!props.canMutate) { return; }
        if (props.getRoleForMember(member) === newRole) { return; }

        const existingRole = props.getRoleForMember(member);
        const kind = props.getKindForMember(member);
        const name = kind.getName(member);

        (async () => {
            try {
                if (existingRole.isManager && !newRole.isManager) {
                    await confirm({
                        description: `Are you sure you want to downgrade ${name} to ${newRole.label}?\n\nThey will no longer be able to manage this ${props.removalShortText}`,
                        confirmationText: `Make ${newRole.label}`,
                        cancellationText: 'Cancel',
                        confirmationButtonProps: {
                            variant: 'contained'
                        }
                    });
                } else if (newRole.isManager) {
                    await confirm({
                        description: `Are you sure you want to change the role of ${name} to ${newRole.label}?\n\nThey will be able to fully manage this ${props.removalShortText}`,
                        confirmationText: `Make ${newRole.label}`,
                        cancellationText: 'Cancel',
                        confirmationButtonProps: {
                            variant: 'contained',
                            color: 'secondary'
                        }
                    });
                } else {
                    await confirm({
                        description: `Are you sure you want to change the role of ${name} to ${newRole.label}?`,
                        confirmationText: `Make ${newRole.label}`,
                        cancellationText: 'Cancel',
                        confirmationButtonProps: {
                            variant: 'contained',
                        }
                    });
                }
            } catch (e) {
                // Canceled.
                return;
            }

            const result = await changeMemberRole({
                variables: {
                    ...props.changeMemberRoleMutation.variables,
                    memberId: member.id,
                    newRole: newRole.id
                }
            });

            if (result !== undefined) {
                pushEvent('change-member-role', {
                    ...props.removeMemberMutation.variables,
                    memberId: member.id,
                    newRole: newRole.id
                });
            }
        })();
    };

    // From: https://spectrum.chat/apollo/react-apollo/usemutation-under-certain-conditions~88c6e45b-5697-472b-ad73-afd99893d016
    const PLACEHOLDER_MUTATION = gql`
        mutation {
        whatever
        }
    `
    const [invitedUser, setInvitedUser] = useState<string | undefined>(undefined);
    const [inviteEmail, { running: inviting }] = useManagedMutationWithMetadata(props.inviteEmailMutation?.gql || PLACEHOLDER_MUTATION,
        {
            workingMessage: 'Inviting user',
            errorMessage: 'Could not invite user'
        });

    const { showConfirm } = useConfirmDialog();

    const sendEmailInvite = (emailAddress: string) => {
        (async () => {
            const [response, message] = await showConfirm({
                'title': `Invite ${emailAddress}`,
                'content': <div>
                    Enter a message for the invitation to <code>{emailAddress}</code>
                </div>,
                'buttons': [
                    { 'title': 'Cancel', 'value': 'undefined' },
                    { 'title': `Invite ${emailAddress}`, 'variant': 'contained', 'color': 'primary', 'value': 'send' },
                ],
                withPrompt: 'Invitation message',
            });
            if (response !== 'send') {
                return
            }

            const result = await inviteEmail({
                variables: {
                    ...props.inviteEmailMutation!.variables,
                    emailAddress: emailAddress,
                    message: message
                }
            });
            if (result !== undefined) {
                setInvitedUser(emailAddress);
                setIsGrantDialogOpen(false);
            }
        })();
    };

    const computeEnterText = (enteredText: string) => {
        if (props.inviteEmailMutation !== undefined && EmailValidator.validate(enteredText)) {
            return <div>
                <div>A user with this e-mail is not part of your organization</div>
                <Button className={classes.inviteEmailBtn} variant="contained" color="primary" onMouseDown={() => sendEmailInvite(enteredText)}>Invite {enteredText}</Button>
            </div>;
        }
        return props.enterText || "Enter the name or full e-mail address of a valid user or the name of a valid group";
    };

    const isManager = props.isManager || function (m: M) {
        return props.getRoleForMember(m).isManager
    };

    const [isGrantDialogOpen, setIsGrantDialogOpen] = useState(false);

    const showGrantDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!props.canMutate) {
            return;
        }

        event.stopPropagation();
        setIsGrantDialogOpen(true);
    };

    return <div>
        {askDeleteMember !== undefined &&
            <DeleteMemberDialog<M> key={askDeleteIndex} member={askDeleteMember} memberKind={props.getKindForMember(askDeleteMember)} removeMember={handleRemoveMember} removalShortText={props.removalShortText} removalLongText={props.removalLongText} removalSuffixText={props.removalSuffixText} />}
        {props.canMutate && <Dialog
            open={isGrantDialogOpen}
            onClose={() => setIsGrantDialogOpen(false)}
            aria-labelledby="grant-dialog-title"
            aria-describedby="grant-dialog-description"
        >
            <DialogTitle id="grant-dialog-title">{props.autocompleteText}</DialogTitle>
            <DialogContent>
                <div style={{ minWidth: 500 }}>
                    <BoundAutocomplete<A> label={props.autocompleteText}
                        enterText={computeEnterText}
                        query={
                            props.autocompleteQuery
                        }
                        resultLabel={
                            (result: A) => {
                                const member = props.translateAutocompleteResult(result);
                                return props.getKindForMember(member).getName(member)
                            }
                        }
                        renderResult={
                            (result: A) => {
                                const member = props.translateAutocompleteResult(result);
                                const kind = props.getKindForMember(member);
                                return <span className={classes.autocompleteResult}>
                                    <span className={classes.logo}>{kind.renderLogo(member)}</span>
                                    <span className={classes.autocompleteTitle}>{kind.getName(member)}</span>
                                </span>;
                            }
                        }
                        startAdornment={
                            props.adornmentIcon ? props.adornmentIcon : <PersonAddIcon />
                        }
                        resultSelected={handleAddMember}
                        fullWidth
                        autoFocus
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsGrantDialogOpen(false)} color="inherit">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>}

        <BoundTable<M, MembershipMetadata>
            precompute={
                (results: Edge<M>[]) => {
                    return {
                        hasSingleManager: (results.filter((result: Edge<M>) => isManager(result.node)).length === 1)
                    }
                }
            }
            columns={[
                {
                    id: "icon",
                    title: "",
                    cellStyle: {
                        width: '1rem',
                    },
                    render: (member: M) => {
                        const kind = props.getKindForMember(member);
                        return <span className={classes.logo}>{kind.renderLogo(member)}</span>
                    }
                },
                {
                    id: "title",
                    title: "Member",
                    cellStyle: {
                        width: '50%',
                    },
                    render: (member: M) => {
                        const kind = props.getKindForMember(member);
                        return kind.renderInline(member)
                    }
                },
                {
                    id: "description",
                    title: "Description",
                    cellStyle: {
                        width: '50%',
                    },
                    render: (member: M) => {
                        const kind = props.getKindForMember(member);
                        return kind.renderDescription(member)
                    }
                },
                {
                    id: "role",
                    title: "",
                    cellStyle: {
                        width: props.canMutate ? '10%' : '0rem',
                    },
                    render: (member: M, precomputed: MembershipMetadata | undefined) => {
                        const infnc = props.getKindForMember(member).getInheritanceSource;
                        if (infnc !== undefined) {
                            const inheritance = infnc(member);
                            if (inheritance !== undefined) {
                                return <ToggleButtonGroup
                                    style={{ width: '100%' }}
                                    exclusive
                                    value={props.getRoleForMember(member)}
                                    size="small"
                                >
                                    {props.memberRoles.map((role: MemberRole) => {
                                        if (role.id !== props.getRoleForMember(member).id) {
                                            return undefined;
                                        }

                                        return <ToggleButton style={{ width: '100%', whiteSpace: 'nowrap' }} key={role.id} classes={{ selected: role.selectedClassName }} value={role} disabled={true} >
                                            {inheritance}
                                        </ToggleButton>;
                                    })}
                                </ToggleButtonGroup>;
                            }
                        }

                        return props.memberRoles.length > 1 ? <ToggleButtonGroup
                            style={{ width: '100%' }}
                            exclusive
                            value={props.getRoleForMember(member)}
                            onChange={(event: any, newRole: MemberRole | null) => newRole ? handleMemberRoleToggle(member, newRole) : undefined}
                            size="small"
                        >
                            {props.memberRoles.map((role: MemberRole) => {
                                const disabled = (props.getRoleForMember(member).isManager && precomputed?.hasSingleManager && isManager(member)) || !props.canMutate || (props.allowedRoles && !props.allowedRoles.includes(role));
                                return <ToggleButton key={role.id} classes={{ selected: role.selectedClassName }} value={role} disabled={disabled} >
                                    {role.label}
                                </ToggleButton>;
                            })}
                        </ToggleButtonGroup> : undefined;
                    }
                },
                {
                    id: "options",
                    title: "",
                    cellStyle: {
                        width: props.canMutate ? '1rem' : '0rem',
                    },
                    render: (member: M, precomputed: MembershipMetadata | undefined) => {
                        if (!props.canMutate) {
                            return <span></span>;
                        }

                        const infnc = props.getKindForMember(member).getInheritanceSource;
                        if (infnc !== undefined) {
                            const result = infnc(member);
                            if (result !== undefined) {
                                return <span></span>;
                            }
                        }

                        const deleteDisabled = (precomputed?.hasSingleManager && isManager(member)) || false;
                        return <Tooltip title="Remove" aria-label="remove">
                            <span>
                                <Button disabled={deleteDisabled} className={classes.managerButton} onClick={() => showDeleteMember(member)}><DeleteForeverIcon /></Button>
                            </span>
                        </Tooltip>;
                    }
                },
            ]}
            toolbar={
                <div className={classes.toolbar}>
                    {inviting && <CircularProgress size="1em" />}
                    {invitedUser !== undefined && <Alert className={classes.inviteSuccess} severity="success">{invitedUser} has been invited</Alert>}
                    {props.canMutate && <Button color="primary" variant="contained" onClick={showGrantDialog}>{props.autocompleteText}</Button>}
                </div>
            }
            query={
                props.lookupQuery
            }
            emptyMessage={
                <div>
                    No members found
                </div>
            }
        />
    </div >;
}