import { CLIENT_TOKENS_QUERY, CREATE_CLIENT_TOKEN, LookupClientByIDData, LOOKUP_CLIENT_BY_ID } from '@code/authzed-common/src/queries/client';
import { Organization } from "@code/authzed-common/src/types/organization";
import { QueryView } from '@code/trumpet/src/components';
import { useManagedQuery } from "@code/trumpet/src/hooks";
import { useTitle } from "@code/trumpet/src/TitleHook";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import OrgReference from "../components/OrgReference";
import { TokenManager } from '../components/TokenManager';
import { useSharedStyles } from "../sharedstyles";

export function OrgClient(props: { organization: Organization, clientId: string }) {
    const clientState = useManagedQuery<LookupClientByIDData>(LOOKUP_CLIENT_BY_ID, {
        variables: {
            clientId: props.clientId,
        }
    });

    const sharedClasses = useSharedStyles();
    const organization = props.organization;
    const client = clientState.data?.clientById;
    const defaultTokenTitle = `${client?.title.substr(0, 20).split(' ').join('_').toLowerCase()}_def`;

    useTitle(['authzed', client?.title, organization.name], [organization, client]);

    return <div>
        <Breadcrumbs className={sharedClasses.breadcrumbs} aria-label="breadcrumb">
            <Link className={sharedClasses.link} to={`/organization/${organization.slug}`}>
                <OrgReference organization={organization} />
            </Link>
            <Link className={sharedClasses.link} to={`/organization/${organization.slug}/clients`}>
                API Clients
            </Link>
            <Typography color="textPrimary"><code>{client?.title ? client.title : props.clientId}</code></Typography>
            <Typography color="textPrimary">Tokens</Typography>
        </Breadcrumbs>

        <QueryView state={clientState}>
            {client && <TokenManager
                tokenPrefix="tc"
                description="The tokens defined here will be used to authenticate this client."
                createTokenMutation={CREATE_CLIENT_TOKEN}
                lookupTokenQuery={{
                    gql: CLIENT_TOKENS_QUERY.query,
                    variables: { clientId: props.clientId },
                    recordsKey: ['clientById', 'tokens']
                }}
                createVariables={{
                    clientId: props.clientId
                }}
                createTokenMethodName='createClientToken'
                defaultTokenTitle={defaultTokenTitle}
                defaultTokenNote={`Default Token for ${client?.title}`}
            />}
        </QueryView>
    </div>;
}