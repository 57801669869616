import { CircularProgress, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Alert from '@material-ui/lab/Alert';
import React from "react";
import { PromQuery, PromQueryStatus, UpdateOption, usePrometheusQuery } from "./hook";
import PrometheusResultChart from "./PrometheusResultChart";

export interface PrometheusQueryChartProps {
    /**
     * endpoint is the Prometheus endpoint on which to execute the query. Must *not* include `/api/v1`.
     */
    endpoint: string

    /**
     * query is the Prometheus query to execute.
     */
    query: PromQuery

    /**
     * isRangeQuery should be set to true if the query is a range query.
     */
    isRangeQuery?: boolean

    /**
     * title is the (optional) title to display for the chart.
     */
    title?: string

    /**
     * legendTitle is the (optional) title for the legend.
     */
    legendTitle?: string

    /**
     * legendGenerator, if specified, is the generator for creating legends on the chart based
     * on the metric metadata.
     */
    legendGenerator?: (metric: Record<string, any>) => string

    /**
     * valueFormat, if specified, is the format suffix for the Y-axis of the chart.
     */
    valueFormat?: string

    /**
     * scalarFormatter, if specified, formats the scalar value before presenting on the chart
     */
    scalarFormatter?: (value: string) => string

    /**
     * parameters are the parameters to be added to the query call. If the value is a function, it is executed on each call.
     */
    parameters: Record<string, any>

    /**
     * authHeader, if defined, is the Authorization header to send on fetches.
     */
    authHeader?: string | undefined

    /**
     * refreshRateMs is the number of milliseconds after which to refresh the chart. Defaults to 30000ms (30 seconds).
     */
    refreshRateMs?: number

    /**
     * getDataColor returns the color for the dataset of the given index. If unspecified,
     * will use default colors.
     */
    getDataColor?: (index: number) => string
}


interface StyleProps {
    prefersDarkMode: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loading: {
        },
        title: {
            textAlign: 'center',
            padding: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        lastUpdated: {
            color: theme.palette.grey[500],
        },
        promChart: {
            width: '100%',
            minHeight: '300px',
            maxWidth: '600px',
            alignItems: 'center',
            justifyContent: 'center',
        },
        chartContainer: {
            backgroundColor: (props: StyleProps) => props.prefersDarkMode ? '#222' : '#fff',
            padding: theme.spacing(2),
        }
    }));

const DEFAULT_REFRESH_RATE = 30000 /* 30 seconds */

/**
 * PrometheusQueryChart is a chart component which, when given a Prometheus endpoint and query to run,
 * runs the query on an interval and graphically charts the results.
 */
export default function PrometheusQueryChart(props: PrometheusQueryChartProps) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const classes = useStyles({ prefersDarkMode: prefersDarkMode });
    const state = usePrometheusQuery(props.query, props.parameters, props.endpoint, props.authHeader, props.isRangeQuery ?? false, props.refreshRateMs ?? DEFAULT_REFRESH_RATE, UpdateOption.WITH_PAGE_VISIBLE);

    return <Paper className={classes.promChart}>
        {state.status === PromQueryStatus.LOADING && <div className={classes.loading}>
            <CircularProgress />
        </div>}
        {state.status === PromQueryStatus.LOAD_ERROR && <Alert severity="error">Could not load data</Alert>}
        {state.status === PromQueryStatus.LOADED && state.queryResult &&
            <div className={classes.chartContainer}>
                {props.title && <Typography className={classes.title} variant="subtitle2">{props.title}</Typography>}
                <div>
                    <PrometheusResultChart getDataColor={props.getDataColor} width={550} height={305} valueFormat={props.valueFormat} legendGenerator={props.legendGenerator} result={state.queryResult} legendTitle={props.legendTitle} scalarFormatter={props.scalarFormatter} />
                </div>
                {<Typography className={classes.lastUpdated} variant="caption">Last Updated: {state.lastUpdated?.toLocaleTimeString()}</Typography>}
            </div>
        }
    </Paper >;
}
