import { useAuthentication } from "@code/authzed-common/src/authn/useauthentication";
import { CancelBillingParams, CANCEL_BILLING, EnableBillingData, EnabledBillingData, ENABLE_BILLING, OrganizationBillingData, OrganizationBillingParams, OrganizationBillingPortalData, OrganizationBillingPortalParams, ORGANIZATION_BILLING_PORTAL_QUERY, ORGANIZATION_BILLING_QUERY, RequestEnhancedSupportData, RequestEnhancedSupportResponse, REQUEST_ENHANCED_SUPPORT } from "@code/authzed-common/src/queries/billing";
import { Organization } from "@code/authzed-common/src/types/organization";
import { useAlert } from "@code/trumpet/src/AlertProvider";
import { QueryView } from "@code/trumpet/src/components";
import { useConfirmDialog } from "@code/trumpet/src/ConfirmDialogProvider";
import { useImperativeQuery, useManagedMutation, useManagedQuery } from "@code/trumpet/src/hooks";
import { StripePaymentForm } from "@code/trumpet/src/StripePaymentForm";
import { useTitle } from "@code/trumpet/src/TitleHook";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress, createStyles, DialogActions, makeStyles, Paper, Theme } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { PaymentMethod } from "@stripe/stripe-js";
import clsx from 'clsx';
import React from "react";
import { Link } from "react-router-dom";
import { InfoBox } from "../components/InfoBox";
import OrgReference from "../components/OrgReference";
import { useSharedStyles } from "../sharedstyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        billingSection: {
            padding: theme.spacing(2)
        },
        billingSectionTitle: {
            paddingLeft: theme.spacing(1),
            marginTop: 0,
            marginBottom: theme.spacing(3),
            display: 'flex',
            alignItems: 'center',
        },
        billingSectionWithMargin: {
            marginBottom: theme.spacing(2)
        },
    }));


export function OrgBilling(props: { organization: Organization }) {
    const sharedClasses = useSharedStyles();
    const classes = useStyles();

    const organization = props.organization;
    const { user } = useAuthentication();

    useTitle(['authzed', 'Billing', organization.name], [organization]);

    const billingState = useManagedQuery<OrganizationBillingData, OrganizationBillingParams>(ORGANIZATION_BILLING_QUERY, {
        variables: {
            id: organization.id,
        }
    });

    const billingEnabled = billingState.data?.organizationById.billing?.enabled;

    const [enableBilling, { running: enablingBilling }] = useManagedMutation<EnabledBillingData, EnableBillingData>(ENABLE_BILLING);

    const handlePaymentMethodCreated = (paymentMethod: PaymentMethod, billingEmail: string) => {
        (async () => {
            await enableBilling({
                variables: {
                    organizationId: props.organization.id,
                    stripePaymentMethodId: paymentMethod.id,
                    billingEmail: billingEmail,
                }
            })
        })();
    };

    const { showConfirm } = useConfirmDialog();
    const [cancelBilling, { running: canceling }] = useManagedMutation<any, CancelBillingParams>(CANCEL_BILLING);
    const askCancelBilling = () => {
        (async () => {
            const [result] = await showConfirm({
                'title': `Cancel billing for ${organization.name}?`,
                'content': `Are you sure you want to cancel billing for the organization? All production permissions systems will be disabled after billing is canceled.`,
                'buttons': [
                    { 'title': 'Return to Settings', 'value': 'nevermind' },
                    {
                        'title': `Cancel Billing for ${organization.name}`,
                        'variant': 'contained',
                        'color': 'secondary',
                        'value': 'cancel-billing',
                    },
                ],
            });

            if (result === 'cancel-billing') {
                await cancelBilling({
                    variables: {
                        organizationId: organization.id,
                    },
                });
            }
        })();
    };

    const { showAlert } = useAlert();
    const { execute: lookupPortalUrl, loading: loadingPortalURL } = useImperativeQuery<OrganizationBillingPortalData, OrganizationBillingPortalParams>(ORGANIZATION_BILLING_PORTAL_QUERY);
    const handleOpenPortal = () => {
        (async () => {
            const result = await lookupPortalUrl({
                organizationId: organization.id,
            });

            if (result !== undefined) {
                window.location.assign(result.data.organizationById.billing.portalUrl);
            }
        })();
    };

    const [requestSupport, { running: requesting }] = useManagedMutation<RequestEnhancedSupportResponse, RequestEnhancedSupportData>(REQUEST_ENHANCED_SUPPORT);
    const handleRequestEnhancedSupport = () => {
        (async () => {
            const result = await requestSupport({
                variables: {
                    id: organization.id
                }
            });
            if (result?.data?.requestEnhancedSupport.ok) {
                showAlert({
                    'title': 'Enhanced support request sent',
                    'content': 'Your request for enhanced support has been sent to the Authzed team. We will reach out shortly via e-mail. No changes have been made to your account at this time.',
                    'buttonTitle': 'Okay',
                })
            }
        })();
    };

    return <div>
        <Breadcrumbs className={sharedClasses.breadcrumbs} aria-label="breadcrumb">
            <Link className={sharedClasses.link} to={`/organization/${organization.slug}`}>
                <OrgReference organization={organization} />
            </Link>
            <Typography color="textPrimary">Billing</Typography>
        </Breadcrumbs>

        <InfoBox>
            Billing settings for the organization
        </InfoBox>

        <QueryView state={billingState}>
            {billingState.data?.organizationById.billing !== undefined &&
                <div>
                    {billingEnabled && <>
                        <Paper className={clsx(classes.billingSection, classes.billingSectionWithMargin)}>
                            <h2 className={classes.billingSectionTitle}>Invoices and Settings</h2>
                            <div>
                                <Button color="primary" disabled={loadingPortalURL || canceling || requesting} startIcon={<FontAwesomeIcon icon={faLink} />} onClick={handleOpenPortal} variant="contained">
                                    {loadingPortalURL && <>
                                        Loading...
                                    </>}
                                    {!loadingPortalURL &&
                                        <>
                                            View Billing Portal
                                        </>}
                                </Button>
                            </div>
                        </Paper>
                        <Paper className={clsx(classes.billingSection, classes.billingSectionWithMargin)}>
                            <h2 className={classes.billingSectionTitle}>Enhanced Support</h2>
                            <p>
                                Need 24x7 support with an SLA for your organization? Use this button to request access to our early access enhanced support program. No changes will be made to your organization's subscription at this time.
                            </p>
                            <div>
                                <Button color="primary" style={{ backgroundColor: 'green' }} disabled={canceling || requesting} onClick={handleRequestEnhancedSupport} variant="contained">
                                    Request Enhanced Support
                                </Button>
                            </div>
                        </Paper>
                        <Paper className={clsx(classes.billingSection)} style={{ border: '1px solid #7c0303' }}>
                            <h2 className={classes.billingSectionTitle}>Cancel Billing</h2>
                            <div>
                                <Alert variant="standard" severity="error">Canceling billing will disable your production permissions system(s). Please be certain!</Alert>
                                <Button style={{ border: '0px', marginTop: '10px' }} size="large" variant="contained" color="default" disabled={canceling || requesting} onClick={askCancelBilling}>Disable Production Permissions Systems and Cancel Billing</Button>
                            </div>
                        </Paper>
                    </>}
                    {!billingEnabled && <Paper className={clsx(classes.billingSection)}>
                        <StripePaymentForm defaultEmailAddress={user?.emailAddress} paymentMethodCreated={handlePaymentMethodCreated}>
                            {
                                (renderForm: React.ReactChild, submitForm: (e: React.FormEvent<HTMLFormElement>) => void, isFormValid: boolean, isWorking: boolean) => {
                                    return <form onSubmit={submitForm}>
                                        <div style={{ paddingTop: 0 }}>
                                            <Alert variant="filled" severity="info">
                                                Billing information is required to enable creation of production systems
                                            </Alert>
                                            {renderForm}
                                        </div>
                                        <DialogActions>
                                            {(isWorking || enablingBilling) && <CircularProgress size="1.5rem" />}
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isWorking || !isFormValid || enablingBilling}>
                                                Enable Billing
                                            </Button>
                                        </DialogActions>
                                    </form>;
                                }
                            }
                        </StripePaymentForm>
                    </Paper>}
                </div>
            }
        </QueryView>
    </div>;
}