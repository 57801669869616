import React, { PropsWithChildren } from "react";
import { Organization } from "../types/organization";
import { Tenant } from "../types/tenant";

export enum OrganizationPermission {
    ChangeMemberRole = 'changeMemberRole',
    ManageBilling = 'manageBilling',
    CreateClient = 'createClient',
}

export enum TenantPermission {
    ReadRelationships = 'readRelationships',
    WriteRelationships = 'writeRelationships',
    ManageAccess = 'manageAccess',
    CloneTenant = 'cloneTenant',
    PopulateTenant = 'populateTenant',
    DeleteTenant = 'deleteTenant',
}

export interface PermissionsService {
    checkTenant(tenant: Tenant | undefined, permission: TenantPermission, ...other: TenantPermission[]): boolean
    checkOrg(org: Organization | undefined, permission: OrganizationPermission, ...other: OrganizationPermission[]): boolean
}

/**
 * usePermissionsService is a service which retrieves the permissions for Authzed UI
 * objects, such as organizations and tenants.
 */
export function usePermissionsService(): PermissionsService {
    // TODO(jschorr): If the number of permissions necessary grows too large,
    // change to a lazy loading system.
    function check<T extends string>(checkPermissions: T[], userPermissions: Record<T, boolean>, kind: string) {
        return checkPermissions.find((permission: T) => {
            const result = userPermissions[permission];
            if (result === undefined) {
                throw Error(`missing expected ${kind} permission ${permission}`)
            }
            return result === true;
        }) !== undefined;
    };

    return {
        'checkOrg': (org: Organization | undefined, permission: OrganizationPermission, ...other: OrganizationPermission[]) => {
            if (org === undefined) {
                return false;
            }

            const permissions = [permission, ...other];
            return check(permissions, org.permissions, 'org');
        },
        'checkTenant': (tenant: Tenant | undefined, permission: TenantPermission, ...other: TenantPermission[]) => {
            if (tenant === undefined) {
                return false;
            }

            const permissions = [permission, ...other];
            return check(permissions, tenant.permissions, 'tenant');
        },
    }
}

/**
 * WithTenantPermission renders the children of the component if and only if the user has
 * the specified permission on the tenant.
 */
export function WithTenantPermission(props: PropsWithChildren<{ tenant: Tenant, permission: TenantPermission }>) {
    const service = usePermissionsService();
    if (service.checkTenant(props.tenant, props.permission)) {
        return <>{props.children}</>;
    }

    return <></>;
}


/**
 * WithoutTenantPermission renders the children of the component if and only if the user does not have
 * the specified permission on the tenant.
 */
export function WithoutTenantPermission(props: PropsWithChildren<{ tenant: Tenant, permission: TenantPermission }>) {
    const service = usePermissionsService();
    if (!service.checkTenant(props.tenant, props.permission)) {
        return <>{props.children}</>;
    }

    return <></>;
}


/**
 * WithOrgPermission renders the children of the component if and only if the user has
 * the specified permission on the organization.
 */
export function WithOrgPermission(props: PropsWithChildren<{ organization: Organization, permission: OrganizationPermission }>) {
    const service = usePermissionsService();
    if (service.checkOrg(props.organization, props.permission)) {
        return <>{props.children}</>;
    }

    return <></>;
}