import { FavIconServiceContext } from '@code/authzed-common/src/services/faviconservice';
import Avatar from '@material-ui/core/Avatar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from "react";
import stc from 'string-to-color';


interface styleProps {
    avatarColor: string
    hasLogo: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: (props: styleProps) => props.avatarColor,
            color: (props: styleProps) => theme.palette.getContrastText(props.avatarColor),
            height: '1.5rem',
            width: '1.5rem',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            position: 'relative',
        },
        loader: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            zIndex: 2,
            color: 'rgba(255, 255, 255, 0.2)',
        }
    }));

/**
 * Defines the properties for the OrgLogo.
 */
interface OrgLogoProps {
    /**
     * The organization.
     */
    organization: {
        name: string
        logo?: string,
        companyDomain?: string | undefined | null,
    };

    /**
     * className is the custom CSS class name for this component, if any.
     */
    className?: string
}

const SVG_DATA_URL_PREFIX = "data:image/svg+xml;base64,"

export default function OrgLogo(props: OrgLogoProps) {
    const avatarColor = stc(props.organization.name);
    const [logo, setLogo] = useState((props.organization.logo ?? '').startsWith(SVG_DATA_URL_PREFIX) ? props.organization.logo : '');
    const classes = useStyles({ 'hasLogo': !!logo, 'avatarColor': logo ? 'rgba(0, 0, 0, 0)' : avatarColor });

    const faviconService = useContext(FavIconServiceContext)!;
    useEffect(() => {
        if (!logo && props.organization.companyDomain) {
            (async () => {
                const favIcon = await faviconService.lookup(props.organization.companyDomain!);
                setLogo(favIcon ?? '');
            })();
        }
    }, [logo, props.organization.companyDomain, faviconService]);

    return <Avatar src={logo} className={clsx(classes.avatar, props.className)}>
        {props.organization.name[0].toLocaleUpperCase()}
    </Avatar>;
}