import { OrganizationPermission } from "../services/permissionsservice";
import { Permissions } from "./permissions";
import { Tenant } from "./tenant";
import { User } from "./user";

/**
 * OrgRole holds the different roles for a user in an organization.
 */
export enum OrgRole {
    MEMBER = "MEMBER",
    ADMIN = "ADMIN"
}

/**
 * OrganizationKind holds the different kinds for an organization.
 */
export enum OrganizationKind {
    STANDARD = "STANDARD",
    DEFAULT_PERSONAL = "DEFAULT_PERSONAL"
}

export enum ProdTenantStatus {
    DISABLED = 'DISABLED',
    BILLING_REQUIRED = 'BILLING_REQUIRED',
    ENABLED = 'ENABLED',
}


/**
 * Defines the data found for an organization.
 */
export interface Organization {
    /**
     * id is the organization's unique ID.
     */
    id: string

    /**
     * kind is the kind of the organization.
     */
    kind: OrganizationKind

    /**
     * name is the name of the organization.
     */
    name: string

    /**
     * slug is the unique URL-safe slug for this organization.
     */
    slug: string

    /**
     * logo is the URL of the logo for this organization, or empty if none.
     */
    logo: string

    /**
     * companyDomain is the domain of the company for which this org exists.
     */
    companyDomain?: string | undefined | null

    /**
     * prodTenantsStatus indicates whether production tenants can be created
     * under the organization.
     */
    prodTenantsStatus?: ProdTenantStatus

    /**
     * recentTenants are the recent tenants, if any, for the organization.
     */
    recentTenants?: Tenant[]

    /**
     * billing, if present, is the billing information for the organization.
     */
    billing?: BillingInfo

    /**
     * permissions are the permissions loaded for the organization.
     */
    permissions: Permissions<OrganizationPermission>
}

/**
 * BillingInfo is information regarding billing for an organization.
 */
export interface BillingInfo {
    /**
     * enabled indicates whether the organization has billing enabled.
     */
    enabled: boolean;
}

export type OrgMember = User & {
    // TODO: uncomment once we have member roles.
    // memberRole: OrgRole
};