import { gql } from "@apollo/client";
import { WithErrors, WITH_ERRORS_FRAGMENT } from "@code/trumpet/src/base";
import { BillingInfo, Organization } from "../types/organization";
import { LOOKUP_ORG_FIELDS_FRAGMENT } from "./organization";

/**
 * Mutation: Cancel billing on an organization.
 * @param organizationId The id of the organization.
 */
export const CANCEL_BILLING = {
    query: gql`
        mutation CancelBilling($organizationId: ID!) {
            cancelBilling(organizationId: $organizationId) {
                billing {
                    enabled
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }`,
    metadata: {
        workingMessage: 'Canceling billing',
        errorMessage: 'Failed to cancel billing',
    },
    refetchQueries: ['LookupOrganizationBilling']
};

/**
 * Params for the CANCEL_BILLING mutation.
 */
export interface CancelBillingParams {
    /**
     * organizationId is the ID of the organization in which to cancel billing.
     */
    organizationId: string
}


/**
 * Mutation: Start the billing flow.
 * @param organizationId The id of the organization.
 */
export const ENABLE_BILLING = {
    query: gql`
        mutation EnableBilling($organizationId: ID!, $stripePaymentMethodId: String!, $billingEmail: String!) {
            enableBilling(organizationId: $organizationId, stripePaymentMethodId: $stripePaymentMethodId, billingEmail: $billingEmail) {
                billing {
                    enabled
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }`,
    metadata: {
        workingMessage: 'Enabling billing',
        errorMessage: 'Failed to enable billing',
    },
    refetchQueries: ['LookupOrganizationBilling', 'LookupOrganizationBySlug']
};

/**
 * Data for the ENABLE_BILLING mutation.
 */
export interface EnableBillingData {
    /**
     * organizationId is the ID of the organization in which to enable billing.
     */
    organizationId: string

    /**
     * stripePaymentMethodId is the payment method ID from Stripe.
     */
    stripePaymentMethodId: string

    /**
     * billingEmail is the billing e-mail address.
     */
    billingEmail: string
}

/**
 * Response from the ENABLE_BILLING mutation.
 */
export type EnabledBillingData = {
    /**
     * enableBilling holds the returned billing info.
     */
    enableBilling: BillingInfo & WithErrors;
};

/**
 * Retrieves the URL for the billing portal for an organization.
 * @returns OrganizationBillingPortalData
 */
export const ORGANIZATION_BILLING_PORTAL_QUERY = gql`
query LookupOrganizationBillingPortal($organizationId: ID!) {
  organizationById(organizationId: $organizationId) {
    ${LOOKUP_ORG_FIELDS_FRAGMENT}
    billing {
      enabled
      portalUrl
    }    
  }
}
`;

export interface OrganizationBillingPortalParams {
    organizationId: string
}

/**
 * Returns the portal URL for an organization's billing.
 */
export interface OrganizationBillingPortalData {
    /**
     * organizationById is the organization found.
     */
    organizationById: { billing: { portalUrl: string } }
}

/**
 * Retrieves information about an organization, including its billing information (which
 * requires admin permission).
 * @returns OrganizationData
 */
export const ORGANIZATION_BILLING_QUERY = {
    query: gql`
        query LookupOrganizationBilling($id: ID!) {
            organizationById(organizationId: $id) {
                ${LOOKUP_ORG_FIELDS_FRAGMENT}
                billing {
                    enabled
                }
            }
        }`,
    metadata: {
        workingMessage: 'Loading billing information',
        errorMessage: 'Could not load billing information'
    }
};

export interface OrganizationBillingParams {
    id: string
}


export interface OrganizationBillingData {
    organizationById: Organization
}

/**
 * Mutation: Requests enhanced support for an organization.
 */
export const REQUEST_ENHANCED_SUPPORT = {
    query: gql`
        mutation RequestEnhancedSupport($id: ID!) {
            requestEnhancedSupport(organizationId: $id) {
                ${WITH_ERRORS_FRAGMENT}
            }
        }
    `,
    metadata: {
        workingMessage: 'Requesting enhanced support',
        errorMessage: 'Failed to request enhanced support'
    },
};

export interface RequestEnhancedSupportData {
    id: string
}

export type RequestEnhancedSupportResponse = {
    requestEnhancedSupport: WithErrors
};