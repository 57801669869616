import { ORGANIZATION_TENANTS_QUERY } from "@code/authzed-common/src/queries/tenant";
import { Organization } from "@code/authzed-common/src/types/organization";
import { Tenant, TenantKind } from "@code/authzed-common/src/types/tenant";
import BoundTable from "@code/trumpet/src/BoundTable";
import { useTitle } from "@code/trumpet/src/TitleHook";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import React, { useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { CreateTenantForm } from "../components/CreateTenantForm";
import { InfoBox } from "../components/InfoBox";
import OrgReference from "../components/OrgReference";
import TenantLogo from "../components/TenantLogo";
import { UserReference } from "../components/UserReference";
import { useSharedStyles } from "../sharedstyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tenantLink: {
            color: theme.palette.text.primary,
        },
        slug: {
            fontFamily: 'Roboto Mono, monospace'
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        formControl: {
            marginTop: theme.spacing(2)
        },
        kindItem: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            alignItems: 'center',
            columnGap: '6px'
        },
        kindCircle: {
            width: '12px',
            height: '12px',
            borderRadius: '50%'
        },
        development: {
            backgroundColor: theme.palette.warning.main,
        },
        production: {
            backgroundColor: theme.palette.success.main,
        },
        tenantKind: {
            display: 'flex',
            alignItems: 'center',
            '&::before': {
                content: '""',
                display: 'inline-block',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                marginRight: '6px',
            }
        },
        tenantProduction: {
            '&::before': {
                backgroundColor: theme.palette.success.dark,
            },
        },
        tenantDevelopment: {
            '&::before': {
                backgroundColor: theme.palette.warning.dark,
            },
        }
    }));

interface OrgTenantsState {
    /**
     * showCreate indicates whether the create dialog should be shown on page load.
     */
    showCreate: boolean
}


export function OrgTenants(props: RouteComponentProps<any> & { organization: Organization }) {
    const sharedClasses = useSharedStyles();
    const classes = useStyles();
    const organization = props.organization;
    const history = useHistory();
    const showCreate = (history.location.state as (OrgTenantsState | undefined))?.showCreate;

    useTitle(['authzed', 'Systems', props.organization.name], [props.organization]);

    const [isCreateDialogOpen, setCreateDialogOpen] = useState(!!showCreate);
    const [creatingTenant, setCreatingTenant] = useState(false);

    const showCreateDialog = () => {
        setCreateDialogOpen(true);
    };

    const handleTenantCreated = (tenant: Tenant, defaultToken: string) => {
        history.push(`/organization/${organization.slug}/systems/${tenant.slug}?tab=schema`, {
            defaultToken: defaultToken,
        });
    };

    return <div>
        <Breadcrumbs className={sharedClasses.breadcrumbs} aria-label="breadcrumb">
            <Link className={sharedClasses.link} to={`/organization/${organization.slug}`}>
                <OrgReference organization={organization} />
            </Link>
            <Typography color="textPrimary">Permission Systems</Typography>
        </Breadcrumbs>
        <InfoBox>
            <strong>Permissions Systems</strong> represent a schema, defining the permissions model and a set of relationships defining its data
        </InfoBox>
        <BoundTable<Tenant>
            columns={[
                {
                    id: "icon",
                    title: "",
                    render: (tenant: Tenant) => {
                        return <TenantLogo tenant={tenant} />;
                    }
                },
                {
                    id: "name",
                    title: "Name",
                    cellStyle: {
                        width: '25%',
                    },
                    render: (tenant: Tenant) => {
                        return <Link to={`/organization/${organization.slug}/systems/${tenant.slug}`} className={classes.tenantLink}>{tenant.name}</Link>
                    }
                },
                {
                    id: "description", title: "Description",
                    cellStyle: {
                        width: '30%',
                    },
                    render: (tenant: Tenant) => {
                        return tenant.description
                    }
                },
                {
                    id: "slug", title: "Schema Prefix",
                    cellStyle: {
                        width: '15%',
                    },
                    render: (tenant: Tenant) => {
                        return <code>{tenant.slug}/</code>
                    }
                },
                {
                    id: "kind", title: "Kind",
                    cellStyle: {
                        width: '15%',
                    },
                    render: (tenant: Tenant) => {
                        return <>
                            {tenant.kind === TenantKind.PRODUCTION && <Typography variant="subtitle2" className={clsx(classes.tenantKind, classes.tenantProduction)}>Production</Typography>}
                            {tenant.kind === TenantKind.DEVELOPMENT && <Typography variant="subtitle2" className={clsx(classes.tenantKind, classes.tenantDevelopment)}>Development</Typography>}
                        </>;
                    }
                },
                {
                    id: "createdby", title: "Created By",
                    cellStyle: {
                        width: '15%',
                    },
                    render: (tenant: Tenant) => {
                        return <div>
                            {tenant.createdBy && <UserReference user={tenant.createdBy} />}
                        </div>;
                    }
                },
                {
                    id: "actions", title: "", isSortable: false,
                    render: (tenant: Tenant) => {
                        return ''
                    }
                },
            ]}
            query={
                {
                    gql: ORGANIZATION_TENANTS_QUERY.query,
                    variables: {
                        organizationId: organization.id,
                    },
                    recordsKey: ['organizationById', 'tenants']
                }
            }
            toolbar={
                <Box className={classes.toolbar} onClick={showCreateDialog}>
                    <Button variant="contained" startIcon={<AddIcon />} color="primary" aria-label="add">
                        Create Permissions System
                    </Button>
                </Box>
            }
        />

        <Dialog
            open={isCreateDialogOpen}
            onClose={() => setCreateDialogOpen(false)}
            disableBackdropClick={creatingTenant}
            disableEscapeKeyDown={creatingTenant}
        >
            <DialogTitle>
                Create new Permissions System
                {creatingTenant && <CircularProgress size="1em" />}
            </DialogTitle>
            <CreateTenantForm
                tenantCreating={setCreatingTenant}
                organization={props.organization}
                tenantCreated={handleTenantCreated}
                formCanceled={() => setCreateDialogOpen(false)}
                formSubmitted={() => setCreateDialogOpen(false)}
                allowProduction />
        </Dialog>
    </div >;
}
