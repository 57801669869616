import { Tenant, TenantKind } from "@code/authzed-common/src/types/tenant";
import Typography from "@material-ui/core/Typography";
import React from 'react';
import { useSharedTenantStyles } from "../sharedstyles";

export function TenantSummary(props: { tenant: Tenant }) {
    const tenant = props.tenant;
    const sharedClasses = useSharedTenantStyles({ tenant: tenant });
    return <>
        {tenant.kind === TenantKind.SYSTEM && <Typography variant="subtitle2" className={sharedClasses.tenantKind}>System-Defined</Typography>}
        {tenant.kind === TenantKind.PRODUCTION && <Typography variant="subtitle2" className={sharedClasses.tenantKind}>Production</Typography>}
        {tenant.kind === TenantKind.DEVELOPMENT && <Typography variant="subtitle2" className={sharedClasses.tenantKind}>Development</Typography>}
    </>;
}
