import { ApolloError } from '@apollo/client';
import { useAuthentication } from '@code/authzed-common/src/authn/useauthentication';
import { MutationError } from '@code/trumpet/src/base';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as AUTHZED_LOGO } from '../assets/logo.svg';
import { useSharedAppStyles, useSharedStyles } from '../sharedstyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        warningDisplay: {
            padding: theme.spacing(2),
        },
        warningHeader: {
            padding: theme.spacing(1),
            '& >span': {
                marginLeft: theme.spacing(1),
            },
            '& svg': {
                color: theme.palette.warning.main,
            }
        },
        warningMessage: {
            padding: theme.spacing(1),
        },
        warningAction: {
            display: 'grid',
            padding: theme.spacing(1),
            gridTemplateColumns: 'auto auto',
            columnGap: theme.spacing(1),
        },
        warningLink: {
            color: theme.palette.text.primary,
        }
    }));


/**
 * MutationErrorsDisplay is a helper component for displaying mutation errors as a global
 * view.
 */
export function MutationErrorsDisplay(props: { title: string, closeWarning: () => void, errors?: MutationError[] | undefined, error?: ApolloError | undefined }) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const classes = useStyles({ prefersDarkMode: prefersDarkMode });
    const sharedClasses = useSharedStyles();
    const sharedAppClasses = useSharedAppStyles({ prefersDarkMode: prefersDarkMode });

    return (
        <div className={sharedAppClasses.root}>
            <div className={sharedAppClasses.contentRoot}>
                <div className={sharedClasses.rootError}>
                    <div className={sharedClasses.inlineLogoContainer}>
                        <AUTHZED_LOGO />
                    </div>

                    <Paper className={classes.warningDisplay}>
                        <Typography variant="h5" className={classes.warningHeader}>
                            <h3 style={{ marginTop: '0px' }}><FontAwesomeIcon icon={faExclamationTriangle} /> {props.title}</h3>
                            {props.error !== undefined && <div>{props.error.message}</div>}
                            {props.errors?.map((error: MutationError) => {
                                return <div>{error.details}</div>
                            })}
                        </Typography>
                        <div className={classes.warningMessage}>
                            Return to your <a href="javascript:void(0)" onClick={props.closeWarning} role="link" className={classes.warningLink}>Dashboard</a>
                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    );
}

/**
 * PageNotFound is a page not found display.
 */
export function PageNotFound() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const classes = useStyles({ prefersDarkMode: prefersDarkMode });
    const sharedClasses = useSharedStyles();
    return (
        <div className={sharedClasses.rootError}>
            <div className={sharedClasses.inlineLogoContainer}>
                <AUTHZED_LOGO />
            </div>

            <Paper className={classes.warningDisplay}>
                <Typography variant="h5" className={classes.warningHeader}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span>Page Not Found</span>
                </Typography>
                <div className={classes.warningMessage}>
                    Return to your <Link to="/" role="link" className={classes.warningLink}>Dashboard</Link>
                </div>
            </Paper>
        </div>
    );
}

/**
 * AuthErrorDisplay is an error display when an auth error occurred.
 */
export function AuthErrorDisplay(props: { error: ApolloError, refetch: () => void }) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const classes = useStyles({ prefersDarkMode: prefersDarkMode });
    const sharedClasses = useSharedStyles();
    const { logout } = useAuthentication();

    const isUnverifiedError = props.error.graphQLErrors.length === 1 &&
        props.error.graphQLErrors[0].extensions &&
        props.error.graphQLErrors[0].extensions['code'] === 'UNVERIFIED_EMAIL';

    const isDuplicateError = props.error.graphQLErrors.length === 1 &&
        props.error.graphQLErrors[0].extensions &&
        props.error.graphQLErrors[0].extensions['code'] === 'DUPLICATE_EMAIL';

    const reload = () => {
        window.location.reload();
    };

    if (isUnverifiedError) {
        return <div className={sharedClasses.rootError}>
            <div className={sharedClasses.inlineLogoContainer}>
                <AUTHZED_LOGO />
            </div>

            <Paper className={classes.warningDisplay}>
                <Typography variant="h5" className={classes.warningHeader}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span> Unverified E-mail Address</span>
                </Typography>
                <div className={classes.warningMessage}>
                    Please verify your e-mail address to complete the sign up process
                </div>
                <div className={classes.warningAction}>
                    <Button variant="contained" onClick={logout}>Logout</Button>
                    <Button variant="contained" color="primary" onClick={reload}>I've verified my e-mail address</Button>
                </div>
            </Paper>
        </div>
    }

    if (isDuplicateError) {
        return <div className={sharedClasses.rootError}>
            <div className={sharedClasses.inlineLogoContainer}>
                <AUTHZED_LOGO />
            </div>

            <Paper className={classes.warningDisplay}>
                <Typography variant="h5" className={classes.warningHeader}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span>Duplicate E-mail Address</span>
                </Typography>
                <div className={classes.warningMessage}>
                    The e-mail address associated with this account is already used on another account. Please login with that account.
                </div>
                <div className={classes.warningAction}>
                    <Button variant="contained" onClick={logout}>Logout</Button>
                </div>
            </Paper>
        </div>
    }

    return <div className={sharedClasses.rootError}>
        <Alert severity="error">Could not load user information. Please reload and try again. If this problem persists, please contact support.</Alert>
    </div>;
}
