import { gql } from "@apollo/client";
import { PAGE_INFO_FRAGMENT, PaginatedResponse, WithErrors, WITH_ERRORS_FRAGMENT } from "@code/trumpet/src/base";
import { OrganizationPermission } from "../services/permissionsservice";
import { Organization, OrganizationKind } from "../types/organization";


export const BASIC_ORG_FIELDS_FRAGMENT = `
id
name
logo
slug
kind
companyDomain
`

export const ORGANIZATION_QUERIES = ['LookupOrganizationBySlug', 'LookupOrganizations', 'GetCurrentUserInformation']

export const LOOKUP_ORG_FIELDS_FRAGMENT = `
    ${BASIC_ORG_FIELDS_FRAGMENT}
    prodTenantsStatus
    recentTenants {
        id
        slug
        kind
        name
        description
        addedClientDefaultRole
    }
    permissions {
        ${Object.values(OrganizationPermission).join('\n')}
    }`


/**
 * Retrieves an organization by slug.
 * @param LookupOrganizationBySlugDataParams
 * @returns LookupOrganizationBySlugData
 */
export const LOOKUP_ORG_BY_SLUG = {
    query: gql`
        query LookupOrganizationBySlug($slug: String!) {
            organizationBySlug(slug: $slug) {
                ${LOOKUP_ORG_FIELDS_FRAGMENT}                
            }
        }
    `,
    metadata: {
        workingMessage: 'Loading organization',
        errorMessage: 'Failed to load organization',
    }
};

export interface LookupOrganizationBySlugDataParams {
    slug: string
}

export interface LookupOrganizationBySlugData {
    organizationBySlug: Organization;
}

/**
 * Retrieves the current user's organizations.
 * @returns LookupOrganizationsData
 */
export const ORGANIZATIONS_QUERY = {
    query: gql`
        query LookupOrganizations($first: Int!, $after: String) {
            organizations(first: $first, after: $after) {
                ${PAGE_INFO_FRAGMENT}
                edges {
                    node {
                        ${BASIC_ORG_FIELDS_FRAGMENT}
                    }
                }
            }
        }
    `,
    metadata: {
        workingMessage: 'Loading organizations',
        errorMessage: 'Failed to load organizations',
    }
};

/**
 * The data returned from the ORGANIZATIONS_QUERY.
 */
export interface LookupOrganizationsData {
    organizations: PaginatedResponse<Organization>
}


/**
 * Retrieves organization users matching the given query.
 * @param prefix The prefix string to use for matching.
 */
export const AUTOCOMPLETE_ORGANIZATION_USERS_QUERY = gql`
  query AutocompleteOrgUsers($organizationId: ID, $prefix: String!) {
    autocompleteOrgUsers(organizationId: $organizationId, prefix: $prefix) {
      id
      profile {
        email
        name
        avatarUrl
     }
    }
  }
`


/**
 * Query to lookup the members of an organization.
 */
export const ORGANIZATION_MEMBERS_QUERY = gql`
query LookupOrganizationMembers($organizationId: ID, $first: Int!, $after: String) {
    organizationById(organizationId: $organizationId) {
        id
        members(first: $first, after: $after) {
            ${PAGE_INFO_FRAGMENT}
            edges {
                node {
                    id
                    profile {
                        email
                        name
                        avatarUrl
                    }
                }
            }
        }
    }
}
`


/**
 * Mutation: Sets or changes the role of an organization member.
 * @param organizationId The id of the organization.
 * @param memberId The id of the member (must be a user).
 * @param preventRoleChange Whether this role change is a new member.
 */
export const SET_ORGANIZATION_MEMBER_ROLE = {
    query: gql`
        mutation SetOrganizationMembershipAndRole($organizationId: ID!, $memberId: ID!, $preventRoleChange: Boolean) {
            setOrganizationMembershipAndRole(organizationId: $organizationId, memberId: $memberId, preventRoleChange: $preventRoleChange) {
                organization {
                    ${BASIC_ORG_FIELDS_FRAGMENT}
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }
        `,
    metadata: {
        workingMessage: 'Changing member role in organization',
        errorMessage: 'Failed to add or change member in organization',
    },
    refetchQueries: ['LookupOrganizationMembers'],
};



/**
 * Mutation: Removes a member from an organization.
 * @param organizatioId The id of the organization.
 * @param memberId The id of the member (must be a user).
 */
export const REMOVE_ORGANIZATION_MEMBER = {
    query: gql`
        mutation RemoveOrganizationMember($organizationId: ID!, $memberId: ID!) {
            removeOrganizationMember(organizationId: $organizationId, memberId: $memberId) {
                organization {
                    ${BASIC_ORG_FIELDS_FRAGMENT}
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }`,
    metadata: {
        workingMessage: 'Removing member from organization',
        errorMessage: 'Failed to remove member from organization',
    },
    refetchQueries: ['LookupOrganizationMembers'],
};



/**
 * Mutation: Creates a new organization.
 * 
 * @param name The name of the organization.
 * @returns CreatedOrganizationData
 */
export const CREATE_ORGANIZATION = {
    query: gql`
        mutation CreateOrganization($name: String!, $logo: String!, $slug: String!, $kind: OrganizationKind!, $companyDomain: String!, $normalizeSlug: Boolean) {
            createOrganization(name: $name, logo: $logo, slug: $slug, kind: $kind, companyDomain: $companyDomain, normalizeSlug: $normalizeSlug) {
                organization {
                    ${BASIC_ORG_FIELDS_FRAGMENT}
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }
    `,
    metadata: {
        workingMessage: 'Creating organization',
        errorMessage: 'Failed to create organization'
    },
    refetchQueries: ORGANIZATION_QUERIES
}

/**
 * The parameters for CREATE_ORGANIZATION.
 */
export interface CreateOrganizationData {
    slug: string
    name: string
    logo: string
    kind: OrganizationKind
    companyDomain: string
    normalizeSlug?: boolean
}

/**
 * The return value from CREATE_ORGANIZATION.
 */
export type CreatedOrganizationData = {
    /**
     * createOrganization holds information about the created organization.
     */
    createOrganization: { organization: Organization } & WithErrors
};


/**
 * Mutation: Renames an organization.
 * 
 * @param id The ID of the organization to rename.
 * @param name The name of the organization to rename.
 */
export const RENAME_ORGANIZATION_QUERY = {
    query: gql`
        mutation RenameOrganization($id: ID!, $name: String!) {
            renameOrganization(organizationId: $id, name: $name) {
                organization {
                    ${BASIC_ORG_FIELDS_FRAGMENT}
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }
    `,
    metadata: {
        workingMessage: 'Loading organization',
        errorMessage: 'Failed to find the specified organization'
    },
};

export interface RenameOrganizationData {
    id: string
    name: string
}

export type RenamedOrganizationData = {
    renameOrganization: WithErrors & { organization: Organization };
};


/**
 * Mutation: Requests prod access under an organization.
 */
export const REQUEST_PROD_ACCESS = {
    query: gql`
        mutation RequestProdAccess($id: ID!, $businessType: String!, $numEmployees: String!, $greenfield: String!, $techStack: String!, $intendedUse: String!) {
            requestProdAccess(organizationId: $id, businessType: $businessType, numEmployees: $numEmployees, greenfield: $greenfield, techStack: $techStack, intendedUse: $intendedUse) {
                organization {
                    ${BASIC_ORG_FIELDS_FRAGMENT}
                }
                ${WITH_ERRORS_FRAGMENT}
            }
        }
    `,
    metadata: {
        workingMessage: 'Requesting prod access',
        errorMessage: 'Failed to request prod access'
    },
};

export interface RequestProdAccessData {
    id: string
    businessType: string
    numEmployees: string
    greenfield: string
    techStack: string
    intendedUse: string
}

export type RequestProdAccessResponse = {
    requestProdAccess: WithErrors & { organization: Organization };
};