import { UserProps } from '@code/authzed-common/src/authn/user';
import { AuthenticatedUserData } from "@code/authzed-common/src/queries/authenticateduser";
import { CreatedOrganizationData, CreateOrganizationData, CREATE_ORGANIZATION, ORGANIZATIONS_QUERY } from "@code/authzed-common/src/queries/organization";
import { UserEvent } from '@code/authzed-common/src/types/events';
import { Organization, OrganizationKind } from "@code/authzed-common/src/types/organization";
import { AmplitudeContext } from '@code/trumpet/src/AmplitudeClient';
import BoundTable from "@code/trumpet/src/BoundTable";
import { useGoogleAnalytics } from '@code/trumpet/src/GoogleAnalyticsHook';
import { useManagedMutation } from '@code/trumpet/src/hooks';
import LoadingView from "@code/trumpet/src/LoadingView";
import { StatusPageDisplay } from '@code/trumpet/src/StatusPageDisplay';
import { useStatusPage } from '@code/trumpet/src/StatusPageHook';
import Trumpet from "@code/trumpet/src/Trumpet";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useCallback, useContext, useEffect } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import AppConfig from "../services/configservice";
import { extractOrganizationInformation } from "../services/domainservice";
import { TRUMPET_COLORS } from "../theme";
import AppTitle from "./AppTitle";
import CreateOrgForm from "./CreateOrgForm";
import OrgLogo from "./OrgLogo";
import { OrgSelector } from "./OrgSelector";
import { UserMenuOptions } from "./UserMenuOptions";

interface NoOrganizationViewProps {
    user: UserProps
    userData: AuthenticatedUserData
    signoutAction?: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orgList: {
            listStyle: 'none',
        },
        orgLink: {
            color: theme.palette.text.primary,
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        iconButton: {
            '& svg': {
                marginRight: theme.spacing(1)
            }
        },
        createOrgHeader: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                marginRight: theme.spacing(1)
            }
        },
        options: {
            padding: theme.spacing(2)
        },
        orgRef: {
            display: 'inline-grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto auto',
            columnGap: '6px',
            marginLeft: theme.spacing(1),
            verticalAlign: 'middle',
        }
    }));

function Routes(props: { userData: AuthenticatedUserData }) {
    const history = useHistory();
    const handleOrgCreated = (organization: Organization) => {
        history.push(`/organization/${organization.slug}`);
    };

    const showCreateOrg = () => {
        history.push(`/createorg`);
    }

    const classes = useStyles();

    return <>
        <Route path="/organizations" exact>
            <BoundTable<Organization>
                columns={[
                    {
                        id: "icon",
                        title: "",
                        isSortable: false,
                        cellStyle: {
                            width: '1rem',
                        },
                        render: (org: Organization) => {
                            return <OrgLogo organization={org} />
                        }
                    },
                    {
                        id: "title",
                        title: "Organization",
                        isPrimary: true,
                        cellStyle: {
                            width: '100%',
                        },
                        render: (org: Organization) => {
                            return <Link to={`/organization/${org.slug}`} className={classes.orgLink}>{org.name}</Link>
                        }
                    },
                ]}
                toolbar={
                    <div className={classes.toolbar}>
                        <Button className={classes.iconButton} variant="contained" color="primary" onClick={showCreateOrg}>
                            <AddCircleIcon />
                            Create Organization
                        </Button>
                    </div>
                }
                query={
                    {
                        gql: ORGANIZATIONS_QUERY.query,
                        recordsKey: ['organizations',],
                        variables: {}
                    }
                } />
        </Route>
        <Route path="/createorg" exact>
            {!!props.userData.authenticatedUser && !props.userData.authenticatedUser.recentOrgs.length &&
                <FirstOrgView userData={props.userData} orgCreated={handleOrgCreated} />
            }
            {!!props.userData.authenticatedUser && props.userData.authenticatedUser.recentOrgs.length > 0 &&
                <>
                    <Breadcrumbs className={classes.createOrgHeader} aria-label="breadcrumb">
                        <Typography variant="h5" color="textPrimary">Create Organization</Typography>
                    </Breadcrumbs>
                    <CreateOrgForm orgCreated={handleOrgCreated} />
                </>
            }
        </Route>
    </>;
}

const EMTPY_LOGO = '';

function FirstOrgView(props: { userData: AuthenticatedUserData, orgCreated: (org: Organization) => any }) {
    const classes = useStyles();

    const [value, setValue] = React.useState('company');
    const [isCreating, setIsCreating] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const orgDefaultEmail = props.userData.authenticatedUser?.profile?.email ?? '';
    const orgInfo = extractOrganizationInformation(orgDefaultEmail);

    const { pushEvent } = useGoogleAnalytics();
    const amplitudeClient = useContext(AmplitudeContext);

    const [createOrganization, { running: creating }] = useManagedMutation<
        CreatedOrganizationData,
        CreateOrganizationData
    >(CREATE_ORGANIZATION);

    const handleCreateOrg = () => {
        createOrg(value);
    }

    const createOrg = useCallback((currentValue: string) => {
        (async () => {
            const slug = currentValue === 'personal' ? orgInfo.userId : orgInfo.id;
            const kind = currentValue === 'personal' ? OrganizationKind.DEFAULT_PERSONAL : OrganizationKind.STANDARD;
            const companyDomain = currentValue === 'personal' ? '' : orgInfo.companyDomain;
            const result = await createOrganization({
                variables: {
                    slug: slug,
                    name: slug,
                    logo: EMTPY_LOGO,
                    kind: kind,
                    companyDomain: companyDomain,
                    normalizeSlug: true,
                }
            });
            if (result !== undefined && result.data?.createOrganization.ok) {
                pushEvent('create-org', {
                    organizationId: result.data?.createOrganization.organization.id,
                });

                props.orgCreated(result.data?.createOrganization.organization!);
                amplitudeClient?.logEvent(UserEvent.CreateNewOrg);
            }
        })();
    }, [orgInfo, props, pushEvent, createOrganization, amplitudeClient]);

    useEffect(() => {
        if (isCreating) {
            return;
        }

        if (orgDefaultEmail && !orgInfo.isCompanyEmail) {
            setIsCreating(true);
            createOrg('personal');
        }
    }, [props, isCreating, orgDefaultEmail, orgInfo, createOrg]);

    if (!orgDefaultEmail) {
        // Should never happen, but if it does, just show the create org page.
        return <CreateOrgForm orgCreated={props.orgCreated} />;
    }

    if (orgInfo.isCompanyEmail) {
        return <div>
            <Typography variant="subtitle1">
                To begin using Authzed, an organization is required to contain your permissions systems:
            </Typography>
            <FormControl className={classes.options} component="fieldset">
                <RadioGroup aria-label="orgoption" name="orgoption" value={value} onChange={handleChange}>
                    <FormControlLabel value="company" control={<Radio disabled={creating} />} label={<div>
                        Create an organization for my company:
                        <div className={classes.orgRef}>
                            <OrgLogo organization={
                                {
                                    name: orgInfo.id,
                                    companyDomain: orgInfo.companyDomain,
                                }
                            } />
                            <strong>{orgInfo.id}</strong>
                        </div>
                    </div>} />
                    <FormControlLabel value="personal" control={<Radio disabled={creating} />} label={<div>
                        Create a personal organization for me
                    </div>} />
                    <FormControlLabel value="customize" control={<Radio disabled={creating} />} label="Let me customize an organization" />
                </RadioGroup>
            </FormControl>
            {value === 'customize' && <CreateOrgForm orgDefaultEmail={orgDefaultEmail} orgCreated={props.orgCreated} />}
            {value !== 'customize' && <div>
                <Button disabled={creating} variant="contained" color="primary" onClick={handleCreateOrg}>Create Organization</Button>
            </div>}
        </div>
    }

    return <LoadingView message="Creating your organization..." />
}

export function NoOrganizationView(props: NoOrganizationViewProps) {
    const { info: statusPageInfo } = useStatusPage(AppConfig().statuspage.pageId);

    let titleBarState = {
        title: <AppTitle />,
        notificationCount: 0, /* FILL ME IN */
        hasAdditionalNotifications: false /*HasNextPage(data?.authenticatedUser.notifications)*/,
        user: props.user,
        signoutAction: props.signoutAction || (() => null),
        notificationElement: undefined, // {notificationViews}
        statusElement: <StatusPageDisplay info={statusPageInfo} componentIds={AppConfig().statuspage.componentIds} />,
        additionalUserMenuOptions: <UserMenuOptions />,
        userContext: <OrgSelector userData={props.userData} />
    }

    return <Trumpet themedProps={TRUMPET_COLORS} titleBarState={titleBarState} drawerContent={undefined}>
        <Routes userData={props.userData} />
    </Trumpet>;
}

