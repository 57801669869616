import { INVITE_TENANT_BY_EMAIL } from '@code/authzed-common/src/queries/invitations';
import { AUTOCOMPLETE_POSSIBLE_TENANT_MEMBERS_QUERY, PossibleTenantMember, REMOVE_TENANT_MEMBER, SET_TENANT_MEMBER_ROLE, TENANT_MEMBERS_QUERY } from '@code/authzed-common/src/queries/tenant';
import { TenantPermission, usePermissionsService } from '@code/authzed-common/src/services/permissionsservice';
import { UserEvent } from '@code/authzed-common/src/types/events';
import { Organization } from '@code/authzed-common/src/types/organization';
import { MemberClient, MemberDirectUser, MemberIndirectUser, Tenant, TenantKind, TenantMember, TenantRole } from '@code/authzed-common/src/types/tenant';
import { getUserTitle } from '@code/authzed-common/src/types/user';
import { AmplitudeContext } from '@code/trumpet/src/AmplitudeClient';
import MembershipEditorBase from '@code/trumpet/src/MembershipEditorBase';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import ComputerIcon from '@material-ui/icons/Computer';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import UserLogo from '../components/UserLogo';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.text.primary,
        },
        writerRole: {
            backgroundColor: fade(theme.palette.success.main, 0.5) + '!important' as any,
            color: theme.palette.success.contrastText + '!important' as any,
        },
        adminRole: {
            backgroundColor: fade(theme.palette.secondary.main, 0.5) + '!important' as any,
            color: theme.palette.secondary.contrastText + '!important' as any,
        },
        applicableClient: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(2),
            marginBottom: theme.spacing(1.5),
            borderTop: '1px solid #666',
        },
        applicableClientBtn: {
            width: '100%'
        },
    }));


/**
 * The properties for the tenant permissions editor control.
 */
interface TenantPermissionEditorProps {
    organization: Organization
    tenant: Tenant
}

/**
 * TenantPermissionEditor is the editor for a tenant's permission.
 */
export default function TenantPermissionEditor(props: TenantPermissionEditorProps) {
    const classes = useStyles();
    const history = useHistory();
    const amplitudeClient = useContext(AmplitudeContext);
    const permissionsService = usePermissionsService();

    const roles = [
        {
            id: TenantRole.VIEWER,
            label: 'Viewer',
            isManager: false,
        },
        {
            id: TenantRole.WRITER,
            label: 'Writer',
            isManager: false,
            selectedClassName: classes.writerRole,
        },
        {
            id: TenantRole.ADMIN,
            label: 'Admin',
            isManager: true,
            selectedClassName: classes.adminRole,
        }
    ];

    const kinds = [
        // DirectUser.
        {
            id: "MemberDirectUser",
            title: "User",
            getName: (m: TenantMember) => {
                const u = m as MemberDirectUser;
                return `${getUserTitle(u)} - ${u.profile?.email}`;
            },
            renderLogo: (m: TenantMember) => {
                const u = m as MemberDirectUser;
                return <UserLogo user={u} size="small" />;
            },
            renderInline: (m: TenantMember) => {
                const u = m as MemberDirectUser;
                return getUserTitle(u);
            },
            renderDescription: (m: TenantMember) => {
                const u = m as MemberDirectUser;
                if (u.profile?.email) {
                    if (u.profile.email.indexOf('•') >= 0) {
                        return u.profile.email;
                    } else {
                        return <a className={classes.link} href={`mailto:${u.profile?.email}`}>{u.profile?.email}</a>;
                    }
                }
                return <span></span>;
            },
            renderToBeRemoved: (m: TenantMember) => {
                const u = m as MemberDirectUser;
                return getUserTitle(u);
            },
        },

        // IndirectUser.
        {
            id: "MemberIndirectUser",
            title: "User",
            getName: (m: TenantMember) => {
                const u = m as MemberIndirectUser;
                return `${getUserTitle(u)} - ${u.profile?.email}`;
            },
            renderLogo: (m: TenantMember) => {
                const u = m as MemberIndirectUser;
                return <UserLogo user={u} size="small" />;
            },
            renderInline: (m: TenantMember) => {
                const u = m as MemberIndirectUser;
                return getUserTitle(u);
            },
            renderDescription: (m: TenantMember) => {
                const u = m as MemberIndirectUser;
                if (u.profile?.email) {
                    if (u.profile.email.indexOf('•') >= 0) {
                        return u.profile.email;
                    } else {
                        return <a className={classes.link} href={`mailto:${u.profile?.email}`}>{u.profile?.email}</a>;
                    }
                }
                return <span></span>;
            },
            renderToBeRemoved: (m: TenantMember) => {
                const u = m as MemberIndirectUser;
                return getUserTitle(u);
            },
            getInheritanceSource: (m: TenantMember) => {
                return `Admin of ${props.organization.name}`;
            }
        },

        // Client.
        {
            id: "MemberClient",
            title: "Client",
            getName: (m: TenantMember) => {
                const c = m as MemberClient;
                return c.title;
            },
            renderLogo: (m: TenantMember) => {
                return <ComputerIcon />;
            },
            renderInline: (m: TenantMember) => {
                const c = m as MemberClient;
                return <Link className={classes.link} to={`/organization/${props.organization.slug}/clients/${c.id}`}>{c.title}</Link>;
            },
            renderDescription: (m: TenantMember) => {
                const c = m as MemberClient;
                return c.note;
            },
            renderToBeRemoved: (m: TenantMember) => {
                const c = m as MemberClient;
                return c.title;
            },
        },
    ];

    const getKindForMember = (member: TenantMember) => {
        switch (member.__typename) {
            case "MemberDirectUser":
                return kinds[0];

            case "MemberIndirectUser":
                return kinds[1];

            case "MemberClient":
                return kinds[2]

            default:
                throw Error('Unknown member kind')
        }
    };

    const getRoleForMember = (member: TenantMember) => {
        switch (member.memberRole) {
            case TenantRole.ADMIN:
                return roles[2]

            case TenantRole.WRITER:
                return roles[1]

            case TenantRole.VIEWER:
                return roles[0];

            default:
                throw Error('Unknown role')
        }
    };

    const translateAutocompleteResult = (result: PossibleTenantMember) => {
        switch (result.__typename) {
            case "ResultClient":
                return {
                    __typename: "MemberClient",
                    id: result.id,
                    title: result.title,
                    memberRole: TenantRole.VIEWER
                } as TenantMember;

            case "ResultUser":
                return {
                    __typename: "MemberDirectUser",
                    id: result.id,
                    profile: result.profile,
                    memberRole: TenantRole.VIEWER
                } as TenantMember;

            default:
                throw Error('Unknown member type')
        }
    };

    const isManager = (member: TenantMember) => {
        return ((member.__typename === "MemberDirectUser" ||
            member.__typename === "MemberIndirectUser") &&
            member.memberRole === TenantRole.ADMIN);
    };

    const showCreateClient = () => {
        amplitudeClient?.logEvent(UserEvent.InteractGrantAccess);
        history.push(`/organization/${props.organization.slug}/clients`, {
            tenantForNewClient: props.tenant
        });
    };

    return <MembershipEditorBase<TenantMember, PossibleTenantMember>
        memberKinds={kinds}
        memberRoles={roles}
        defaultRole={roles[0]}

        // If we have a SYSTEM tenant, then only VIEWER can be granted.
        allowedRoles={props.tenant.kind === TenantKind.SYSTEM ? [roles[0]] : undefined}
        translateAutocompleteResult={translateAutocompleteResult}
        getKindForMember={getKindForMember}
        getRoleForMember={getRoleForMember}

        lookupQuery={{
            gql: TENANT_MEMBERS_QUERY,
            variables: {
                tenantId: props.tenant.id,
            },
            recordsKey: ['tenantById', 'members']
        }}

        autocompleteQuery={{
            gql: AUTOCOMPLETE_POSSIBLE_TENANT_MEMBERS_QUERY,
            variables: {
                tenantId: props.tenant.id,
            },
            recordsKey: ['autocompletePossibleTenantMembers']
        }}

        removeMemberMutation={{
            gql: REMOVE_TENANT_MEMBER.query,
            variables: {
                tenantId: props.tenant.id,
            },
            refetchQueries: ['LookupTenantMembers'],
        }}

        changeMemberRoleMutation={{
            gql: SET_TENANT_MEMBER_ROLE.query,
            variables: {
                tenantId: props.tenant.id,
            },
            refetchQueries: ['LookupTenantMembers', 'LookupTenantBySlug'],
        }}

        inviteEmailMutation={
            {
                gql: INVITE_TENANT_BY_EMAIL.query,
                variables: {
                    tenantId: props.tenant.id
                },
                refetchQueries: INVITE_TENANT_BY_EMAIL.refetchQueries
            }
        }

        canMutate={permissionsService.checkTenant(props.tenant, TenantPermission.ManageAccess)}
        isManager={isManager}

        removalShortText="permissions system"
        removalLongText="all API access"
        autocompleteText="Grant access to a client or user"
        removalSuffixText=""

        enterText={
            <div>
                <div>Enter the name of a client or the name or full e-mail address of a user to grant access to this permissions system</div>
                <Typography variant="subtitle2" className={classes.applicableClient}>Don't have a client? A client is recommended for API access</Typography>
                <Button className={classes.applicableClientBtn} variant="contained" color="primary" onMouseDown={showCreateClient}>Create a Client</Button>
            </div>
        }
    />
}
