import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React, { PropsWithChildren } from "react";
import { DocLink } from "./DocLink";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoBox: {
            marginBottom: theme.spacing(2),

        },
        learnMore: {
            display: 'inline-block',
            '& svg': {
                verticalAlign: 'middle'
            }
        }
    }));

interface InfoBoxProps {
    learnMoreLink?: string | undefined
}

export function InfoBox(props: PropsWithChildren<InfoBoxProps>) {
    const classes = useStyles();
    return <div className={classes.infoBox}>
        {props.children}{props.learnMoreLink !== undefined && <>&nbsp;
        <DocLink to={props.learnMoreLink} className={classes.learnMore}>
                Learn More
            <ArrowRightIcon />
            </DocLink></>}
    </div>;
}