import {
  LookupDefinitionByPathData,
  LOOKUP_DEFINITION_BY_PATH,
} from "@code/authzed-common/src/queries/definition";
import {
  TenantPermission,
  WithTenantPermission,
} from "@code/authzed-common/src/services/permissionsservice";
import { ObjectDefinition } from "@code/authzed-common/src/types/definition";
import { Organization } from "@code/authzed-common/src/types/organization";
import { Tenant, TenantKind } from "@code/authzed-common/src/types/tenant";
import { QueryView } from "@code/trumpet/src/components";
import ExternalLink from "@code/trumpet/src/ExternalLink";
import { useManagedQuery } from "@code/trumpet/src/hooks";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import TableRow from "@material-ui/core/TableRow/TableRow";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Alert from "@material-ui/lab/Alert";
import React, { ChangeEvent, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    definitions: {
      padding: theme.spacing(1),
    },
    nsItem: {
      color: "#92beff",
    },
    relTable: {
      backgroundColor: theme.palette.background.default,
    },
  })
);

const useStylesPageActions = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) {
  const classes = useStylesPageActions();

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

function DefinitionRelationshipsList(props: {
  organization: Organization;
  tenant: Tenant;
  definition: ObjectDefinition;
}) {
  const { tenant, definition } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const classes = useStyles();

  const toggleDefinition = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => toggleDefinition()}>
            {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <code className={classes.nsItem}>DEF</code>
        </TableCell>
        <TableCell>
          <code>{definition.path.split("/")[1]}</code>
        </TableCell>
        <TableCell>
          <code>{definition.path}</code>
        </TableCell>
      </TableRow>
      <WithTenantPermission
        tenant={tenant}
        permission={TenantPermission.ReadRelationships}
      >
        <TableCell colSpan={4}>
          <TableContainer component={Paper}>
            {expanded && <RelationshipsTable {...props} />}
          </TableContainer>
        </TableCell>
      </WithTenantPermission>
    </>
  );
}

function RelationshipsTable(props: {
  organization: Organization;
  tenant: Tenant;
  definition: ObjectDefinition;
}) {
  const { organization, tenant, definition } = props;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const classes = useStyles();

  const relState = useManagedQuery<LookupDefinitionByPathData>(
    {
      query:
        tenant.kind !== TenantKind.DEVELOPMENT
          ? LOOKUP_DEFINITION_BY_PATH.withRelationshipsQuery
          : LOOKUP_DEFINITION_BY_PATH.withDevRelationshipsQuery,
      metadata: LOOKUP_DEFINITION_BY_PATH.metadata,
    },
    {
      variables: {
        organizationId: organization.id,
        definitionPath: definition.path,
      },
    }
  );

  const relationshipData =
    tenant.kind !== TenantKind.DEVELOPMENT
      ? relState?.data?.definitionByPath?.relationships
      : relState?.data?.definitionByPath?.developmentRelationships;

  const handleChangePage = (_event: any, page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <QueryView state={relState}>
      <Table className={classes.relTable} aria-label="relationships">
        <TableBody>
          {relationshipData === null && (
            <Alert severity="warning">
              System is currently in read only mode and cannot load
              relationships
            </Alert>
          )}
          {relationshipData !== null &&
            relationshipData &&
            relationshipData.length === 0 && (
              <TableRow key="empty">
                <TableCell component="th" scope="row">
                  No relationships found for this object definition
                </TableCell>
              </TableRow>
            )}
          {relationshipData !== null &&
            relationshipData &&
            (rowsPerPage > 0
              ? relationshipData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : relationshipData
            ).map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        {relationshipData !== null &&
          relationshipData &&
          relationshipData.length > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={relationshipData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
      </Table>
    </QueryView>
  );
}

export default function RelationshipsList(props: {
  organization: Organization;
  tenant: Tenant;
}) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.definitions}>
        <Alert severity="info">
          NOTE: The table below will show a maximum of 100 relationships. To
          view all relationships, it is recommended to use the{" "}
          <ExternalLink to="https://github.com/authzed/zed">
            zed CLI tool
          </ExternalLink>{" "}
          or the{" "}
          <ExternalLink to="https://buf.build/authzed/api/docs/main:authzed.api.v1#authzed.api.v1.PermissionsService.ReadRelationships">
            ReadRelationships API
          </ExternalLink>{" "}
          with filters.
        </Alert>

        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 0 }}></TableCell>
              <TableCell style={{ width: 0 }}></TableCell>
              <TableCell style={{ width: "30%" }}>Name</TableCell>
              <TableCell style={{ width: "70%" }}>Full Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.tenant.definitions || []).map(
              (objDef: ObjectDefinition) => {
                return (
                  <DefinitionRelationshipsList
                    key={objDef.path}
                    definition={objDef}
                    {...props}
                  />
                );
              }
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
