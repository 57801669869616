import { useMutation } from "@apollo/client";
import { AcceptInvitationData, AcceptInvitationParams, ACCEPT_INVITATION } from "@code/authzed-common/src/queries/invitations";
import LoadingView from "@code/trumpet/src/LoadingView";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MutationErrorsDisplay } from "./WarningDisplay";

const LOCAL_STORAGE_KEY_PREFIX = "invitetoken";
const LOCAL_STORAGE_VERSION = "0.1";
const LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_KEY_PREFIX}-${LOCAL_STORAGE_VERSION}`;

/**
 * InvitationTracker checks for an invitation code in the URL's search query, and,
 * if present, stores it for later retrieval by the InvitationHandler once the user
 * has been authenticated.
 */
export function InvitationTracker(props: PropsWithChildren<{}>) {
    const [isSaved, setIsSaved] = useState(false);
    const query = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const invitationToken = query.get('invitation');

    useEffect(() => {
        if (!isSaved && invitationToken) {
            localStorage.setItem(LOCAL_STORAGE_KEY, invitationToken);
            setIsSaved(true);
        }
    }, [isSaved, setIsSaved, invitationToken]);

    if (!isSaved && invitationToken) {
        return <LoadingView message="Loading" />;
    }

    return <div>{props.children}</div>
}

/**
 * InvitationHandler is a component which handles an invitation token as specified
 * in the local storage. If no invitation token is found, the children
 * of the component are rendered.
 */
export function InvitationHandler(props: PropsWithChildren<{}>) {
    // NOTE: We use a useState here to ensure that when we clear out the local storage
    // below, the existing token is held by React.
    const [invitationToken] = useState(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '');
    const [isClosed, setIsClosed] = useState(false);
    const [acceptInvitation, { loading: accepting, called: called, error: error, data: accepted }] = useMutation<AcceptInvitationData, AcceptInvitationParams>(ACCEPT_INVITATION.query);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (!called && !accepting && invitationToken) {
            (async () => {
                const result = await acceptInvitation({
                    variables: {
                        invitationToken: invitationToken
                    }
                });
                localStorage.setItem(LOCAL_STORAGE_KEY, '');
                if (result.data?.acceptInvitation.ok) {
                    if (result.data.acceptInvitation.invitation?.tenant?.slug) {
                        history.push(`/organization/${result.data.acceptInvitation.invitation.tenant.orgSlug}/systems/${result.data.acceptInvitation.invitation.tenant.slug}?tab=schema`, {
                            acceptedInvitation: result.data.acceptInvitation.invitation
                        });
                    } else if (result.data.acceptInvitation.invitation?.organization?.id) {
                        history.push(`/organization/${result.data.acceptInvitation.invitation.organization.slug}`, {
                            acceptedInvitation: result.data.acceptInvitation.invitation
                        });
                    }
                }
            })();
        }
    }, [accepting, called, acceptInvitation, invitationToken, history, setIsClosed]);

    useEffect(() => {
        if (accepted?.acceptInvitation.ok && location.pathname.indexOf(`/organization/`) >= 0) {
            setIsClosed(true);
        }
    }, [location, accepted]);

    const handleCloseWarning = () => {
        setIsClosed(true);
    };

    if (isClosed || !invitationToken) {
        return <div>{props.children}</div>;
    }

    if (error) {
        return <MutationErrorsDisplay closeWarning={handleCloseWarning} title="Could not accept invitation" error={error} />
    }

    if (accepted?.acceptInvitation?.errors?.length) {
        return <MutationErrorsDisplay closeWarning={handleCloseWarning} title="Could not accept invitation" errors={accepted?.acceptInvitation.errors} />
    }

    return <LoadingView message="Accepting invitation" />;
}
