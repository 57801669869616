import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { ReactComponent as AUTHZED_LOGO } from '../assets/logo.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logoContainer: {
            height: '1.5em',
            '& svg': {
                height: '1.5em'
            }
        }
    }));

/**
* AppTitle is a component which shows the stylized app title.
*/
export default function AppTitle() {
    const classes = useStyles();

    return <div className={classes.logoContainer}>
        <AUTHZED_LOGO />
    </div>;
}