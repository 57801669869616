import { User } from "@code/authzed-common/src/types/user";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import UserLogo from "./UserLogo";

export function UserReference(props: { user: User }) {
    const Logo = React.forwardRef(function Logo(lprops, ref: any) {
        return <span {...lprops} ref={ref}><UserLogo user={props.user} /></span>
    });

    return <Tooltip title={
        <div>
            <div>{props.user.profile?.name}</div>
            <div>{props.user.profile?.email}</div>
        </div>
    }><Logo /></Tooltip>
}