import { useAuthentication } from "@code/authzed-common/src/authn/useauthentication";
import {
  EnableBillingData,
  EnabledBillingData,
  ENABLE_BILLING,
} from "@code/authzed-common/src/queries/billing";
import {
  RequestProdAccessData,
  RequestProdAccessResponse,
  REQUEST_PROD_ACCESS,
} from "@code/authzed-common/src/queries/organization";
import {
  CreatedTenant,
  CreateTenant,
  CREATE_TENANT,
} from "@code/authzed-common/src/queries/tenant";
import { UserEvent } from "@code/authzed-common/src/types/events";
import {
  Organization,
  ProdTenantStatus,
} from "@code/authzed-common/src/types/organization";
import { Tenant, TenantKind } from "@code/authzed-common/src/types/tenant";
import { useAlert } from "@code/trumpet/src/AlertProvider";
import { AmplitudeContext } from "@code/trumpet/src/AmplitudeClient";
import { MutationError } from "@code/trumpet/src/base";
import { useManagedMutation } from "@code/trumpet/src/hooks";
import { StripePaymentForm } from "@code/trumpet/src/StripePaymentForm";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { PaymentMethod } from "@stripe/stripe-js";
import clsx from "clsx";
import React, { FormEvent, useContext, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import slugify from "slugify";
import { Duration, parse } from "tinyduration";
import AppConfig from "../services/configservice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slug: {
      fontFamily: "Roboto Mono, monospace",
    },
    formControl: {
      marginTop: theme.spacing(2),
    },
    kindItem: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      alignItems: "center",
      columnGap: "6px",
    },
    kindCircle: {
      width: "12px",
      height: "12px",
      borderRadius: "50%",
    },
    development: {
      backgroundColor: theme.palette.warning.main,
    },
    production: {
      backgroundColor: theme.palette.success.main,
    },
    tenantKind: {
      display: "flex",
      alignItems: "center",
      "&::before": {
        content: '""',
        display: "inline-block",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        marginRight: "6px",
      },
    },
    tenantProduction: {
      "&::before": {
        backgroundColor: theme.palette.success.dark,
      },
    },
    tenantDevelopment: {
      "&::before": {
        backgroundColor: theme.palette.warning.dark,
      },
    },
  })
);

export interface CreateTenantProps {
  /**
   * organization is the organization under which the tenant should be created.
   */
  organization: Organization;

  /**
   * allowProduction indicates whether the form shows the tenant kind selector between
   * development and production. Default is false.
   */
  allowProduction?: boolean;

  /**
   * tenantCreated is the callback invoked when the tenant has been created.
   */
  tenantCreated: (tenant: Tenant, defaultToken: string) => void;

  /**
   * tenantCreating is the callback invoked when the state of the creation changes.
   */
  tenantCreating?: (isCreating: boolean) => void;

  /**
   * formCanceled is invoked if the Cancel button is clicked. If not specified, cancel
   * buttons are not shown.
   */
  formCanceled?: () => void;

  /**
   * formSubmitted is invoked if the "Request Prod Access" form is submitted.
   */
  formSubmitted?: () => void;

  /**
   * defaultName is the default name for the new tenant.
   */
  defaultName?: string;

  /**
   * defaultDescription is the default description for the new tenant.
   */
  defaultDescription?: string;

  /**
   * defaultSlug is the default slug for the new tenant.
   */
  defaultSlug?: string;
}

/**
 * CreateTenantForm exposes a form for creating a tenant.
 */
export function CreateTenantForm(props: CreateTenantProps) {
  const classes = useStyles();
  const amplitudeClient = useContext(AmplitudeContext);
  const { user } = useAuthentication();

  const [newTenantSlug, setNewTenantSlug] = useState(() => {
    return (
      props.defaultSlug ??
      slugify(`${props.organization.slug}`, {
        replacement: "_",
        remove: /[*+~.()'"!:@-]/g,
        lower: true,
        trim: true,
      }) + "_"
    );
  });

  const [newTenantName, setNewTenantName] = useState(props.defaultName ?? "");
  const [newTenantDescription, setNewTenantDescription] = useState(
    props.defaultDescription ?? ""
  );
  const [newTenantKind, setNewTenantKind] = useState(TenantKind.DEVELOPMENT);

  const [createTenantError, setCreateTenantErrors] = useState<
    MutationError[] | undefined
  >(undefined);

  const [createTenant, { running: creatingTenant }] = useManagedMutation<
    CreatedTenant,
    CreateTenant
  >(
    CREATE_TENANT,
    {},
    {
      handleMutationErrors: false,
    }
  );

  const reset = () => {
    setCreateTenantErrors(undefined);
    setNewTenantSlug(props.defaultSlug ?? `${props.organization.slug}_`);
    setNewTenantName(props.defaultName ?? "");
    setNewTenantDescription(props.defaultDescription ?? "");
    setNewTenantKind(TenantKind.DEVELOPMENT);
  };

  const cancel = () => {
    reset();
    if (props.formCanceled) {
      props.formCanceled();
    }
  };

  const startCreateTenant = () => {
    amplitudeClient?.logEvent(UserEvent.ClickCreateTenant);
    (async () => {
      if (props.tenantCreating) {
        props.tenantCreating(true);
      }

      const result = await createTenant({
        variables: {
          organizationId: props.organization.id,
          slug: newTenantSlug,
          name: newTenantName,
          kind: newTenantKind,
          description: newTenantDescription,
        },
      });

      if (props.tenantCreating) {
        props.tenantCreating(false);
      }

      if (result && result.data?.createTenant.ok) {
        reset();
      } else {
        setCreateTenantErrors(result?.data?.createTenant.errors);
      }
    })();
  };

  const prodTenantDisallowed =
    newTenantKind === TenantKind.PRODUCTION &&
    props.organization.prodTenantsStatus !== ProdTenantStatus.ENABLED;
  const canCreateTenant =
    !prodTenantDisallowed &&
    newTenantName &&
    newTenantDescription &&
    newTenantSlug &&
    !newTenantSlug.endsWith("_");

  const { showAlert } = useAlert();
  const [requestProdAccess, { running: requestingProdAccess }] =
    useManagedMutation<RequestProdAccessResponse, RequestProdAccessData>(
      REQUEST_PROD_ACCESS
    );

  const handleRequestProdAccess = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    (async () => {
      const result = await requestProdAccess({
        variables: {
          id: props.organization.id,
          businessType: data.get("business-type")?.toString() ?? "",
          numEmployees: data.get("num-employees")?.toString() ?? "",
          greenfield: data.get("greenfield")?.toString() ?? "",
          techStack: data.get("tech-stack")?.toString() ?? "",
          intendedUse: data.get("intended-use")?.toString() ?? "",
        },
      });
      if (result?.data?.requestProdAccess.ok) {
        await showAlert({
          title: "Production request submitted",
          content:
            "Your request for production access has been submitted and an Authzed engineer will get in contact with you shortly",
          buttonTitle: "Okay",
        });
        if (props.formSubmitted) {
          props.formSubmitted();
        }
        reset();
      }
    })();
  };

  const ProdInquiryForm = () => {
    return (
      <>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel>
            What type of business is {props.organization.name}? e.g. B2B, B2C
            (optional)
          </InputLabel>
          <Input name="business-type" disabled={requestingProdAccess} />
        </FormControl>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel>
            How many engineers are in {props.organization.name}? (optional)
          </InputLabel>
          <Select name="num-employees" disabled={requestingProdAccess} native>
            <option selected></option>
            <option value="1-5">1 to 5</option>
            <option value="6-20">6 to 20</option>
            <option value="21-50">21 to 50</option>
            <option value="50+">50+</option>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel>
            Do you have existing permissions systems in{" "}
            {props.organization.name}? (optional)
          </InputLabel>
          <Select name="greenfield" disabled={requestingProdAccess} native>
            <option selected></option>
            <option value="existing">Existing permissions system</option>
            <option value="greenfield">Greenfield</option>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel>
            What does your tech stack look like? (optional)
          </InputLabel>
          <Input name="tech-stack" disabled={requestingProdAccess} />
        </FormControl>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel>Describe your intended use case</InputLabel>
          <Input
            name="intended-use"
            disabled={requestingProdAccess}
            required
            multiline
            rows={3}
          />
        </FormControl>
      </>
    );
  };

  const zeroWidthChar = "\u200b";
  const visibleMaskChar = "\u2012";

  const fixValue = (value: string) => {
    if (value.endsWith("/")) {
      value = value.slice(0, -1);
    }

    while (value.endsWith(zeroWidthChar)) {
      value = value.slice(0, -1);
    }

    while (value.endsWith(visibleMaskChar)) {
      value = value.slice(0, -1);
    }

    return value;
  };

  const slugMask = `s${"*".repeat(
    Math.max(Math.min(newTenantSlug.length - 1, 62), 2)
  )}e/`;

  const slugFormatChars = {
    s: "[a-z]",
    "*": "[a-z0-9_]",
    e: "[a-z0-9_]",
  };

  const slugMaskCharacter =
    newTenantSlug.length < 4 ? visibleMaskChar : zeroWidthChar;

  const [enableBilling, { running: enablingBilling }] = useManagedMutation<
    EnabledBillingData,
    EnableBillingData
  >(ENABLE_BILLING);

  const handlePaymentMethodCreated = (
    paymentMethod: PaymentMethod,
    billingEmail: string
  ) => {
    (async () => {
      await enableBilling({
        variables: {
          organizationId: props.organization.id,
          stripePaymentMethodId: paymentMethod.id,
          billingEmail: billingEmail,
        },
      });
    })();
  };

  const inactiveDevelopmentTenantExpiration =
    AppConfig().authzed.inactiveDevelopmentTenantExpiration;
  const inactiveDevelopmentTenantExpirationDuration = useMemo(() => {
    if (!inactiveDevelopmentTenantExpiration) {
      return undefined;
    }

    try {
      const durationObj = parse(inactiveDevelopmentTenantExpiration);
      const pieces: string[] = [];
      const addPiece = (key: keyof Duration) => {
        if (durationObj[key]) {
          pieces.push(`${durationObj[key]} ${key}`);
        }
      };

      addPiece("minutes");
      addPiece("hours");
      addPiece("days");
      addPiece("weeks");
      addPiece("months");
      addPiece("years");
      return pieces.join(", ");
    } catch (e) {
      return undefined;
    }
  }, [inactiveDevelopmentTenantExpiration]);

  return (
    <>
      {createTenantError !== undefined && createTenantError.length > 0 && (
        <Alert severity="error">{createTenantError[0].details}</Alert>
      )}
      <form onSubmit={startCreateTenant}>
        <DialogContent>
          <DialogContentText>
            {props.allowProduction && (
              <FormControl fullWidth>
                <InputLabel htmlFor="tenant-kind">System Kind</InputLabel>
                <Select
                  disabled={creatingTenant}
                  value={newTenantKind}
                  onChange={(
                    event: React.ChangeEvent<{ name?: string; value: unknown }>
                  ) => setNewTenantKind(event.target.value as TenantKind)}
                  required
                >
                  <MenuItem value={TenantKind.DEVELOPMENT}>
                    <div className={classes.kindItem}>
                      <span
                        className={clsx(
                          classes.kindCircle,
                          classes.development
                        )}
                      />
                      Development
                    </div>
                  </MenuItem>
                  <MenuItem value={TenantKind.PRODUCTION}>
                    <div className={classes.kindItem}>
                      <span
                        className={clsx(classes.kindCircle, classes.production)}
                      />
                      Production
                    </div>
                  </MenuItem>
                </Select>
                <FormHelperText id="tenant-kind-text">
                  Whether this system is for development or for production.{" "}
                  <strong>This cannot be changed after creation</strong>
                </FormHelperText>
              </FormControl>
            )}
            {newTenantKind === TenantKind.DEVELOPMENT &&
              inactiveDevelopmentTenantExpirationDuration !== undefined && (
                <DialogContent style={{ paddingTop: 0 }}>
                  <Alert
                    variant="standard"
                    severity="warning"
                    style={{
                      margin: "0px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    Development permission systems will be automatically deleted
                    after {inactiveDevelopmentTenantExpirationDuration} of
                    inactivity.
                  </Alert>
                </DialogContent>
              )}
            {!prodTenantDisallowed && (
              <>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="tenant-slug">
                    Object Prefix (Globally Unique Slug)
                  </InputLabel>
                  <InputMask
                    disabled={creatingTenant}
                    value={newTenantSlug}
                    alwaysShowMask
                    mask={slugMask}
                    maskChar={slugMaskCharacter}
                    formatChars={slugFormatChars}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setNewTenantSlug(fixValue(event.target.value))
                    }
                    autoFocus
                    required
                  >
                    {() => (
                      <Input
                        className={classes.slug}
                        id="tenant-slug"
                        name="tenant-slug"
                        placeholder="my_tenant"
                        aria-describedby="tenant-slug-text"
                        autoComplete="tenant-slug"
                      />
                    )}
                  </InputMask>

                  <FormHelperText id="tenant-slug-text">
                    The globally unique prefix applied to all objects in this
                    Permissions System. Must be alphanumeric, lowercase, between
                    4-64 characters and not end in an underscore.{" "}
                    <strong>This cannot be changed after creation</strong>
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="tenant-title">Name</InputLabel>
                  <Input
                    placeholder="my permissions system"
                    disabled={creatingTenant}
                    value={newTenantName}
                    inputProps={{ maxlength: 50, minlength: 1 }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setNewTenantName(event.target.value)
                    }
                  />
                  <FormHelperText id="tenant-title-text">
                    The user-friendly name for this system. This can be edited
                    at any time.
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="tenant-description">
                    Description
                  </InputLabel>
                  <Input
                    placeholder="An important permissions system"
                    disabled={creatingTenant}
                    value={newTenantDescription}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setNewTenantDescription(event.target.value)
                    }
                  />
                  <FormHelperText id="tenant-description-text">
                    A descriptive note for this system.
                  </FormHelperText>
                </FormControl>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        {!prodTenantDisallowed && (
          <DialogActions>
            {creatingTenant && <CircularProgress size="1.5rem" />}
            {props.formCanceled && (
              <Button disabled={creatingTenant} onClick={cancel}>
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={creatingTenant || !canCreateTenant}
              onClick={startCreateTenant}
              autoFocus
            >
              <div className={classes.kindItem}>
                <span
                  className={clsx(classes.kindCircle, {
                    [classes.production]:
                      newTenantKind === TenantKind.PRODUCTION,
                    [classes.development]:
                      newTenantKind === TenantKind.DEVELOPMENT,
                  })}
                />
                Create {newTenantName || "Permission System"}
              </div>
            </Button>
          </DialogActions>
        )}
      </form>
      {newTenantKind === TenantKind.PRODUCTION &&
        props.organization.prodTenantsStatus === ProdTenantStatus.DISABLED && (
          <form onSubmit={handleRequestProdAccess}>
            <DialogContent style={{ paddingTop: 0 }}>
              <Alert variant="filled" severity="info">
                To enable creation of production systems, we'd like to know more
                about your intended use case.
                <br />
                <br />
                Please fill out the form below and an Authzed engineer will
                contact you shortly.
              </Alert>
              <ProdInquiryForm />
            </DialogContent>
            <DialogActions>
              {props.formCanceled && (
                <Button disabled={creatingTenant} onClick={cancel}>
                  Cancel
                </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                disabled={requestingProdAccess}
              >
                Request Production Access
              </Button>
            </DialogActions>
          </form>
        )}
      {newTenantKind === TenantKind.PRODUCTION &&
        props.organization.prodTenantsStatus ===
          ProdTenantStatus.BILLING_REQUIRED && (
          <StripePaymentForm
            defaultEmailAddress={user?.emailAddress}
            paymentMethodCreated={handlePaymentMethodCreated}
          >
            {(
              renderForm: React.ReactChild,
              submitForm: (e: React.FormEvent<HTMLFormElement>) => void,
              isFormValid: boolean,
              isWorking: boolean
            ) => {
              return (
                <form onSubmit={submitForm}>
                  <DialogContent style={{ paddingTop: 0 }}>
                    <Alert variant="filled" severity="info">
                      Billing information is required to enable creation of
                      production systems
                    </Alert>
                    {renderForm}
                  </DialogContent>
                  <DialogActions>
                    {(isWorking || enablingBilling) && (
                      <CircularProgress size="1.5rem" />
                    )}
                    {props.formCanceled && (
                      <Button
                        disabled={isWorking || enablingBilling}
                        onClick={cancel}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isWorking || !isFormValid || enablingBilling}
                    >
                      Enable Billing
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </StripePaymentForm>
        )}
    </>
  );
}
