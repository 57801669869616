import { LookupOrganizationTenantsParams, LookupOrganizationTenantsResponse, ORGANIZATION_TENANTS_QUERY } from '@code/authzed-common/src/queries/tenant';
import { Organization } from '@code/authzed-common/src/types/organization';
import { Tenant, TenantKind } from '@code/authzed-common/src/types/tenant';
import { GetPages } from '@code/trumpet/src/base';
import { QueryView } from '@code/trumpet/src/components';
import { useManagedQuery } from '@code/trumpet/src/hooks';
import { useTitle } from "@code/trumpet/src/TitleHook";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as AUTHZED_LOGO } from '../assets/logo.svg';
import TenantLogo from '../components/TenantLogo';
import { TenantSummary } from '../components/TenantSummary';
import { useSharedStyles, useSharedTenantStyles } from '../sharedstyles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orgView: {
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
        },
        tenantLink: {
            color: theme.palette.text.primary,
            textDecoration: 'none'
        },
        link: {
            color: theme.palette.text.primary,
        },
        orgLogo: {
            width: '2rem',
            height: '2rem',
            marginRight: '10px',
        },
        empty: {
            fontSize: '125%',
            textAlign: 'center'
        },
        createTenantButton: {
            marginTop: theme.spacing(3),
            fontSize: '105%',
        },
        toolbar: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            alignItems: 'center',
        },
        subtoolbar: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },
        tenant: {
            display: 'grid',
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            gridTemplateColumns: 'auto 1fr auto auto',
            alignItems: 'center',
            columnGap: theme.spacing(2),
        },
        develTenant: {
            borderColor: theme.palette.warning.main,
        },
        prodTenant: {
            borderColor: theme.palette.success.main,
        }
    }));


function TenantRow(props: { tenant: Tenant, organization: Organization }) {
    const classes = useStyles();
    const sharedClasses = useSharedTenantStyles({ tenant: props.tenant });

    return <Link to={`/organization/${props.organization.slug}/systems/${props.tenant.slug}`} style={{ textDecoration: 'none' }}>
        <Paper className={clsx(classes.tenant, classes.prodTenant)}>
            <span style={{ gridRow: '1/3', gridColumn: '1/2' }}>
                <TenantLogo tenant={props.tenant} large />
            </span>
            <span style={{ gridRow: '1/2', gridColumn: '2/3' }}>
                <Typography variant="subtitle1">
                    <Link to={`/organization/${props.organization.slug}/systems/${props.tenant.slug}`} className={classes.tenantLink}>{props.tenant.name}</Link>
                </Typography>
            </span>
            <span style={{ gridRow: '2/3', gridColumn: '2/3' }}>
                <Typography variant="caption" color="textSecondary">{props.tenant.description}</Typography>
            </span>
            <span style={{ gridRow: '1/3', gridColumn: '3/4' }}>
                <TenantSummary tenant={props.tenant} />
            </span>
            <span style={{ gridRow: '1/3', gridColumn: '4/5' }}>
                <Button component={Link} to={`/organization/${props.organization.slug}/systems/${props.tenant.slug}`}>View</Button>
                <Button component={Link} to={`/organization/${props.organization.slug}/systems/${props.tenant.slug}?tab=permissions`}>Edit API Access</Button>
            </span>
        </Paper>
    </Link>;
}

export function OrgDashboard(props: { organization: Organization }) {
    const classes = useStyles();
    const sharedClasses = useSharedStyles();
    const history = useHistory();
    const organization = props.organization;

    useTitle(['authzed', organization.name], [organization]);

    const tenantState = useManagedQuery<LookupOrganizationTenantsResponse, LookupOrganizationTenantsParams>(ORGANIZATION_TENANTS_QUERY, {
        variables: {
            organizationId: props.organization.id,
            first: 25,
            after: undefined
        }
    });

    const tenants = GetPages(tenantState.data?.organizationById.tenants);
    const showCreateTenant = () => {
        history.push(`/organization/${props.organization.slug}/systems`, {
            showCreate: true
        });
    };

    const prodTenants = tenants?.filter((tenant: Tenant) => tenant.kind === TenantKind.PRODUCTION);
    const develTenants = tenants?.filter((tenant: Tenant) => tenant.kind === TenantKind.DEVELOPMENT);
    const systemTenants = tenants?.filter((tenant: Tenant) => tenant.kind === TenantKind.SYSTEM);

    return <Box>
        <QueryView state={tenantState}>
            {tenants?.length === 0 && <div className={classes.empty}>
                <div>
                    <div className={sharedClasses.inlineLogoContainer}>
                        <AUTHZED_LOGO />
                    </div>
                    To get started, please create a <strong>permissions system</strong> to represent your application or
                    set of microservices in Authzed
                </div>
                <Button className={classes.createTenantButton} onClick={showCreateTenant} variant="contained" color="primary">Create Permissions System</Button>
                <Typography variant="subtitle2" style={{ marginTop: '16px' }}>Need more help? Start the <a className={classes.link} href="tutorial">Authzed tutorial</a> for step-by-step directions</Typography>
            </div>}
            {tenants && tenants.length > 0 && <div className={classes.toolbar}>
                <Typography variant="h4">Permissions Systems</Typography>
                <Button variant="contained" size="large" onClick={showCreateTenant} startIcon={<AddIcon />} color="primary" aria-label="add">
                    Create Permissions System
                </Button>
            </div>}
            {prodTenants && prodTenants.length > 0 && <div>
                <div className={classes.subtoolbar}>
                    <Typography variant="h6">Production Permissions Systems</Typography>
                </div>
                {prodTenants.map((tenant: Tenant) => {
                    return <TenantRow tenant={tenant} organization={organization} />;
                })}
            </div>}
            {develTenants && develTenants.length > 0 && <div>
                <div className={classes.subtoolbar}>
                    <Typography variant="h6">Development Permissions Systems</Typography>
                </div>
                {develTenants.map((tenant: Tenant) => {
                    return <TenantRow tenant={tenant} organization={organization} />;
                })}
            </div>}
            {systemTenants && systemTenants.length > 0 && <div>
                <div className={classes.subtoolbar}>
                    <Typography variant="h6">System-Defined Permissions Systems</Typography>
                </div>
                {systemTenants.map((tenant: Tenant) => {
                    return <TenantRow tenant={tenant} organization={organization} />;
                })}
            </div>}
        </QueryView>
    </Box>;
}
