import { useCallback, useEffect, useRef, useState } from "react";
import { usePageVisibility } from 'react-page-visibility';

/**
 * PromQueryStatus is the status of the query to be run/has been run.
 */
export enum PromQueryStatus {
    /**
     * LOADING indicates that the query is currently loading.
     */
    LOADING = 0,

    /**
     * LOAD_ERROR indicates an error occurred when trying to load the query results.
     */
    LOAD_ERROR = -1,

    /**
     * LOADED indicates that the query has been run and the results have been loaded. Note that
     * the results themselves may contain an error.
     */
    LOADED = 2,

    /**
     * DISABLED indicates that the hook has been disabled.
     */
    DISABLED = -2,
}

export type PromQueryResult = PromSuccessResult | PromErrorResult

interface PromErrorResult {
    status: "error"
    errorType?: string
    error?: string
}

interface PromSuccessResult {
    status: "success"
    warnings?: string[]
    data: PromResultData
}

export type PromResultData = PromMatrixData | PromVectorData | PromScalarData | PromStringData

interface PromMatrixData {
    resultType: "matrix"
    result: PromMatrixResult[]
}

type PromValue = [number, string]

interface PromMatrixResult {
    metric: Record<string, any>
    values: PromValue[]
}

interface PromVectorData {
    resultType: "vector"
}

interface PromScalarData {
    resultType: "scalar"
    result: PromValue // [<unix_time>, "<scalar_value>"]
}

interface PromStringData {
    resultType: "string"
}

export type PromQuery = string | (() => string)

/**
 * PromQueryState represents the state of the query being run.
 */
export interface PromQueryState {
    /**
     * status is the status of the query load.
     */
    status: PromQueryStatus

    /**
     * queryResult is the result of the query load. Only set of status === LOADED.
     */
    queryResult?: PromQueryResult

    /**
     * lastUpdated is when the query was last loaded/updated.
     */
    lastUpdated?: Date
}

export enum UpdateOption {
    WITH_PAGE_VISIBLE = 0,
    ALWAYS = 1,
}

/**
 * usePrometheusQuery executes a Prometheus query periodically against a Prometheus instance.
 * @param query The Prometheus query to execute.
 * @param parameters The parameters to be added to the query call. If the value is a function, it is executed on each call.
 * @param endpoint The Prometheus endpoint to use. Must *not* include `/api/v1`.
 * @param authHeader The Authorization header to send, if any.
 * @param isRangeQuery If this query is a range query, set to true.
 * @param updateDurationMs The duration (in milliseconds) in which to re-run and update the query.
 * @param updateOption The option for specifying when an update should occur.
 * @param isEnabled Whether the query hook is enabled.
 */
export function usePrometheusQuery(query: PromQuery, parameters: Record<string, any>, endpoint: string, authHeader: string | undefined, isRangeQuery: boolean, updateDurationMs: number, updateOption: UpdateOption = UpdateOption.ALWAYS, isEnabled: boolean | undefined = true): PromQueryState {
    const [state, setState] = useState<PromQueryState>({
        status: isEnabled ? PromQueryStatus.LOADING : PromQueryStatus.DISABLED
    });

    const isPageVisible = usePageVisibility();
    const lastUpdated = useRef<number | undefined>(undefined);

    const runUpdate = useCallback(async () => {
        if (!isEnabled) { return; }
        lastUpdated.current = Date.now();

        const args = new URLSearchParams();
        const queryVal = typeof query === 'function' ? query() : query;
        args.set("query", queryVal);
        Object.keys(parameters).forEach((key: string) => {
            let value = parameters[key];
            if (typeof value === 'function') {
                value = value();
            }
            args.set(key, value);
        });

        const url = new URL('api/v1/', endpoint).href;
        const headers: Record<string, string> = {};
        if (authHeader) {
            headers['Authorization'] = authHeader
        }

        try {
            const result = await fetch(`${url}${isRangeQuery ? 'query_range' : 'query'}?${args.toString()}`, {
                credentials: 'omit',
                headers: headers,
            });
            if (result.status !== 200) {
                setState({
                    status: PromQueryStatus.LOAD_ERROR,
                })
                return
            }

            const jsonData = await result.json();
            setState({
                status: PromQueryStatus.LOADED,
                queryResult: jsonData as PromQueryResult,
                lastUpdated: new Date(),
            })
        } catch (e) {
            setState({
                status: PromQueryStatus.LOAD_ERROR,
            })
        }
    }, [query, endpoint, parameters, isRangeQuery, isEnabled, updateOption, isPageVisible]);


    useEffect(() => {
        let timer: NodeJS.Timer | undefined = undefined;
        if (isEnabled && (isPageVisible || updateOption === UpdateOption.ALWAYS)) {
            timer = setInterval(() => {
                runUpdate();
            }, updateDurationMs);

            // Run immediately.
            if (lastUpdated.current === undefined ||
                ((Date.now() - lastUpdated.current) >= (updateDurationMs / 1000))) {
                runUpdate();
            }
        }

        return () => timer && clearInterval(timer);
    }, [runUpdate, isEnabled, updateOption, isPageVisible]);

    return state
}

export interface EventTriggerState {
    triggered: boolean
}

/**
 * usePrometheusEventTrigger is a hook that will return true when the given Prometheus query starts to
 * report data.
 */
export function usePrometheusEventTrigger(query: string, parameters: Record<string, any>, endpoint: string, authHeader: string | undefined, updateDurationMs: number, updateOption: UpdateOption, isEnabled: boolean): EventTriggerState {
    const state = usePrometheusQuery(query, parameters, endpoint, authHeader, true, updateDurationMs, updateOption, isEnabled);
    return {
        triggered: state.queryResult?.status === 'success' &&
            state.queryResult.data.resultType === 'matrix' &&
            state.queryResult.data.result.length > 0,
    }
}
