import { INVITE_ORGANIZATION_BY_EMAIL } from '@code/authzed-common/src/queries/invitations';
import { AUTOCOMPLETE_ORGANIZATION_USERS_QUERY, ORGANIZATION_MEMBERS_QUERY, REMOVE_ORGANIZATION_MEMBER, SET_ORGANIZATION_MEMBER_ROLE } from '@code/authzed-common/src/queries/organization';
import { Organization, OrgMember, OrgRole } from '@code/authzed-common/src/types/organization';
import { getUserTitle } from '@code/authzed-common/src/types/user';
import MembershipEditorBase from '@code/trumpet/src/MembershipEditorBase';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import React from 'react';
import UserLogo from '../components/UserLogo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.text.primary,
        },
        adminRole: {
            backgroundColor: fade(theme.palette.secondary.main, 0.5) + '!important' as any,
            color: theme.palette.secondary.contrastText + '!important' as any,
        },
    }));


/**
 * The properties for the permissions editor control.
 */
interface PermissionEditorProps {
    organization: Organization
}

/**
 * PermissionEditor is the editor for an organization's permission.
 */
export default function PermissionEditor(props: PermissionEditorProps) {
    const classes = useStyles();

    const roles = [
        /*{
            id: OrgRole.MEMBER,
            label: 'Member',
            isManager: false,
        },*/
        {
            id: OrgRole.ADMIN,
            label: 'Admin',
            isManager: true,
            selectedClassName: classes.adminRole,
        }
    ];

    const kinds = [
        // User.
        {
            id: "MemberUser",
            title: "User",
            getName: (m: OrgMember) => {
                return `${getUserTitle(m)} - ${m.profile?.email}`;
            },
            renderLogo: (m: OrgMember) => {
                return <UserLogo user={m} size="small" />;
            },
            renderInline: (m: OrgMember) => {
                return getUserTitle(m);
            },
            renderDescription: (member: OrgMember) => {
                if (member.profile?.email) {
                    if (member.profile.email.indexOf('•') >= 0) {
                        return member.profile.email;
                    } else {
                        return <a className={classes.link} href={`mailto:${member.profile?.email}`}>{member.profile?.email}</a>;
                    }
                }
                return <span></span>;
            },
            renderToBeRemoved: (m: OrgMember) => {
                return getUserTitle(m);
            },
        },
    ];

    const getKindForMember = (member: OrgMember) => {
        return kinds[0];
    };

    const getRoleForMember = (member: OrgMember) => {
        // NOTE: For now, we don't have any users added solely as members, so
        // always return admin.
        return roles[0];
    };

    const translateAutocompleteResult = (result: any) => {
        return {
            'id': result.id,
            'profile': result.profile,
        }
    };

    return <MembershipEditorBase<OrgMember, any>
        memberKinds={kinds}
        memberRoles={roles}
        defaultRole={roles[0]}
        translateAutocompleteResult={translateAutocompleteResult}
        getKindForMember={getKindForMember}
        getRoleForMember={getRoleForMember}

        lookupQuery={{
            gql: ORGANIZATION_MEMBERS_QUERY,
            variables: {
                organizationId: props.organization.id,
            },
            recordsKey: ['organizationById', 'members']
        }}

        autocompleteQuery={{
            gql: AUTOCOMPLETE_ORGANIZATION_USERS_QUERY,
            variables: {
                organizationId: props.organization.id,
            },
            recordsKey: ['autocompleteOrgUsers']
        }}

        removeMemberMutation={{
            gql: REMOVE_ORGANIZATION_MEMBER.query,
            variables: {
                organizationId: props.organization.id,
            },
            refetchQueries: ['LookupOrganizationMembers'],
        }}

        changeMemberRoleMutation={{
            gql: SET_ORGANIZATION_MEMBER_ROLE.query,
            variables: {
                organizationId: props.organization.id,
            },
            refetchQueries: ['LookupOrganizationMembers'],
        }}

        inviteEmailMutation={
            {
                gql: INVITE_ORGANIZATION_BY_EMAIL.query,
                variables: {
                    organizationId: props.organization.id
                },
                refetchQueries: INVITE_ORGANIZATION_BY_EMAIL.refetchQueries
            }
        }

        canMutate={true}

        removalShortText="organization"
        removalLongText="any tenants managed by this organization"
        autocompleteText="Add a user to this organization"
        enterText="Enter the full e-mail address of the user to be added as an admin"
        removalSuffixText=""
    />
}