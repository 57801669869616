import { gql } from "@apollo/client";
import { ObjectDefinition } from "../types/definition";

/**
 * Retrieves an object definition by path.
 * @param LookupDefinitionByPathParams
 * @returns LookupDefinitionByPathData
 */
export const LOOKUP_DEFINITION_BY_PATH = {
    query: gql`
        query LookupDefinitionByPath($organizationId: ID!, $definitionPath: String!) {
            definitionByPath(organizationId: $organizationId, definitionPath: $definitionPath) {
               path
            }
        }
    `,
    withDevRelationshipsQuery: gql`
    query LookupDefinitionByPath($organizationId: ID!, $definitionPath: String!) {
        definitionByPath(organizationId: $organizationId, definitionPath: $definitionPath) {
           path
           developmentRelationships
        }
    }
    `,
    withRelationshipsQuery: gql`
    query LookupDefinitionByPath($organizationId: ID!, $definitionPath: String!) {
        definitionByPath(organizationId: $organizationId, definitionPath: $definitionPath) {
           path
           relationships
        }
    }
    `,
    metadata: {
        workingMessage: 'Loading definition',
        errorMessage: 'Failed to load definition',
    }
};

export interface LookupDefinitionByPathParams {
    /**
     * organizationId is the ID of the parent organization.
     */
    organizationId: string

    /**
     *definitionPath is the full path of the definition to lookup.
     */
    definitionPath: string
}

export interface LookupDefinitionByPathData {
    definitionByPath: ObjectDefinition
}
