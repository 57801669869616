import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { PropsWithChildren } from "react";


/**
 * Defines the properties for the Wizard.
 */
interface WizardProps {
    /**
     * activeStep is the 0-based index of the currently active step.
     */
    activeStep: number

    /**
     * children defined the steps of the Wizard. Must contain <WizardStep />
     */
    children: WizardStepType[];

    /**
     * className is the override class name for the component.
     */
    className?: string

    /**
     * classes are the override class names for the component.
     */
    classes?: WizardClasses

    /**
     * stepComponent is, if specified, the component to use for the step content.
     */
    stepComponent?: any;
}

/**
 * WizardClasses defines the overridable CSS classes for the component.
 */
interface WizardClasses {
    /**
     * stepContent is the CSS className for the content pane.
     */
    stepContent?: string

    /**
     * stepContent is the CSS className for the stepper component.
     */
    stepper?: string
}

interface WizardStepProps {
    title: string
    icon: React.ReactNode;
    isOptional?: boolean;
    isHidden?: boolean;
}

type WizardStepType = JSX.Element;

export const WizardStep = (props: PropsWithChildren<WizardStepProps>): WizardStepType => {
    return <div>{props.children}</div>;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        stepContent: {
            padding: theme.spacing(2)
        },
        stepper: {
            padding: theme.spacing(1)
        }
    }));


const useStepIconStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#eaeaf0',
            display: 'inline-flex',
            alignItems: 'center',
        },
        active: {
            color: theme.palette.secondary.light,
        },
        circle: {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
        completed: {
            color: theme.palette.success.light,
            zIndex: 1,
            fontSize: 18,
        },
    }));

// Based on: https://material-ui.com/components/steppers/#stepper
function StepIcon(props: StepIconProps) {
    const classes = useStepIconStyles();
    const { active, completed } = props;

    return (
        <span
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {props.icon}
        </span>
    );
}

/**
 * Wizard displays a steppable wizard.
 */
export default function Wizard(props: WizardProps) {
    const classes = useStyles();
    const StepComponent = props.stepComponent || Box;

    return <div className={clsx(classes.root, props.className)}>
        <Stepper className={clsx(classes.stepper, props.classes?.stepper)} activeStep={props.activeStep}>
            {props.children.map((step: WizardStepType, index: number) => {
                if (step.props.isHidden) { return <span />; }

                const labelProps: {
                    optional?: React.ReactNode,
                    icon: React.ReactNode,
                } = {
                    'icon': step.props.icon,
                };
                if (step.props.isOptional) {
                    labelProps.optional = <Typography variant="caption">Optional</Typography>;
                }
                return <Step key={index}>
                    <StepLabel StepIconComponent={StepIcon} {...labelProps}>{step.props.title}</StepLabel>
                </Step>;
            })}
        </Stepper>
        <StepComponent className={clsx(classes.stepContent, props.classes?.stepContent)}>
            {props.children[props.activeStep]}
        </StepComponent>
    </div>;
}
