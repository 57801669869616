import { Tenant } from '@code/authzed-common/src/types/tenant';
import AppConfig from '../services/configservice';

export function ZedSample(tenant: Tenant, token: string): string {
  return `# Install zed
brew install authzed/tap/zed

# Login to zed
zed context set ${tenant?.slug} ${AppConfig().authzed.apiEndpoint} ${token}

# Issue the Check request
zed permission check ${tenant?.slug}/document:firstdoc read ${
    tenant?.slug
  }/user:sarah`;
}

export function RubySample(tenant: Tenant, token: string): string {
  return `# Run "gem install authzed" to install the client library.
require 'authzed'

client = Authzed::Api::V1::Client.new(
    target: '${AppConfig().authzed.apiEndpoint}',
    interceptors: [Authzed::GrpcUtil::BearerToken.new(token: '${token}')],
)

sarah = Authzed::Api::V1::SubjectReference.new(
    object: Authzed::Api::V1::ObjectReference.new(
      object_type: '${tenant.slug}/user',
      object_id: 'sarah'
    )
  )
  
first_doc = Authzed::Api::V1::ObjectReference.new(
  object_type: "${tenant.slug}/document",
  object_id: "firstdoc"
)

sarah = Authzed::Api::V1::User.for(namespace: '${
    tenant.slug
  }/user', object_id: 'sarah')

# Is Sarah in the set of users that can read the first document?
resp = client.permissions_service.check_permission(
    Authzed::Api::V1::CheckPermissionRequest.new(
      resource: first_doc,
      permission: 'read',
      subject: sarah,
    )
  )
raise unless Authzed::Api::V1::CheckPermissionResponse::Permissionship.resolve(resp.permissionship) ==
  Authzed::Api::V1::CheckPermissionResponse::Permissionship::PERMISSIONSHIP_HAS_PERMISSION
`;
}

export function JavaSample(tenant: Tenant, token: string): string {
  return `/* Add the following to your pom.xml to install the client library:

<dependency>
    <groupId>com.authzed.api</groupId>
    <artifactId>authzed</artifactId>
    <version>0.3.0</version>
</dependency>
*/

import com.authzed.grpcutil.BearerToken;
import com.authzed.api.v1.Core;
import com.authzed.api.v1.PermissionService;
import io.grpc.ManagedChannel;
import io.grpc.ManagedChannelBuilder;

public class Test {
    static void main(String[] args){
        ManagedChannel channel = ManagedChannelBuilder
            .forTarget("${AppConfig().authzed.apiEndpoint}")
            .useTransportSecurity()
            .build();

        PermissionsServiceGrpc.PermissionsServiceBlockingStub permissionsService = PermissionsServiceGrpc.newBlockingStub(channel)
            .withCallCredentials(new BearerToken("${token}");
    
        PermissionService.CheckPermissionRequest request = CheckPermissionRequest.newBuilder()
            .setConsistency(
                    Consistency.newBuilder()
                            .setAtLeastAsFresh(zedToken)
                            .build())
            .setResource(
                    ObjectReference.newBuilder()
                            .setObjectType("${tenant.slug}/document")
                            .setObjectId("firstdoc")
                            .build())
            .setSubject(
                    SubjectReference.newBuilder()
                            .setObject(
                                        ObjectReference.newBuilder()
                                            .setObjectType("${
                                              tenant.slug
                                            }/user")
                                            .setObjectId("sarah")
                                            .build())
                            .build())
            .setPermission("read")
            .build();
        
        // Is Sarah in the set of users that can read the first document?
        PermissionService.CheckPermissionResponse response;
        try {
            response = permissionsService.checkPermission(request);
        } catch (Exception e) {
            System.out.println(e.getMessage());
            return;
        }
        System.out.println("Response: " + response.getPermissionship());
    }
}
`;
}

export function NodeJSSample(tenant: Tenant, token: string): string {
  return `// Run "yarn add @authzed/authzed-node" to install the client library.

const v1 = require('@authzed/authzed-node/v1');
const client = authzed.NewClient('${token}', '${
    AppConfig().authzed.apiEndpoint
  }');

const checkPermissionRequest = v1.CheckPermissionRequest.create({
    resource: v1.ObjectReference.create({
      objectType: '${tenant.slug}/document',
      objectId: 'firstdoc',
    }),
    permission: 'read',
    subject: v1.SubjectReference.create({
      object: v1.ObjectReference.create({
        objectType: '${tenant.slug}/user',
        objectId: 'sarah',
      }),
    }),
    consistency: v1.Consistency.create({
      requirement: {
        oneofKind: 'fullyConsistent',
        fullyConsistent: true,
      },
    }),
  });

  client.checkPermission(checkPermissionRequest, function (err, response) {
      console.log(response);
  });`;
}

export function PythonSample(tenant: Tenant, token: string): string {
  return `# Run "pip install authzed" to install the client library.

from authzed.api.v1 import (
    CheckPermissionRequest,
    CheckPermissionResponse,
    Client,
    ObjectReference,
    SubjectReference,
)
from grpcutil import bearer_token_credentials

sarah = SubjectReference(
    object=ObjectReference(
        object_type="${tenant.slug}/user",
        object_id="sarah",
    )
)


firstdoc = ObjectReference(object_type="${
    tenant.slug
  }/document", object_id="firstdoc")

client = Client(
    "${AppConfig().authzed.apiEndpoint}",
    bearer_token_credentials("${token}"),
)

resp = client.CheckPermission(
    CheckPermissionRequest(
        resource=firstdoc,
        permission="read",
        subject=sarah,
    )
)
assert resp.permissionship == CheckPermissionResponse.PERMISSIONSHIP_HAS_PERMISSION
`;
}

export function GoSample(tenant: Tenant, token: string): string {
  return `package main

    import (
       "context"
       "log"
    
       v1 "github.com/authzed/authzed-go/proto/authzed/api/v1"
       "github.com/authzed/authzed-go/v1"
    
       "github.com/authzed/grpcutil"
    )
    
    const (
       document = "${tenant.slug}/document"
       user     = "${tenant.slug}/user"
    )
    
    func main() {
       // Create an Authzed client.
       client, err := authzed.NewClient(
          "${AppConfig().authzed.apiEndpoint}",
          grpcutil.WithBearerToken(
             "${token}"),
          grpcutil.WithSystemCerts(false),
       )
       if err != nil {
          log.Fatalf("unable to initialize client: %s", err)
       }
    
       firstDoc := &v1.ObjectReference{
          ObjectType: "${tenant.slug}/document",
          ObjectId:   "firstdoc",
       }
       sarah := &v1.SubjectReference{
          Object: &v1.ObjectReference{
             ObjectType: "${tenant.slug}/user",
             ObjectId:   "sarah",
          },
       }
    
       // Run the check.
       resp, err := client.CheckPermission(context.Background(), &v1.CheckPermissionRequest{
          Resource:   firstDoc,
          Permission: "read",
          Subject:    sarah,
       })
       if err != nil {
          log.Fatalf("unable to run check request: %s", err)
       }
       log.Println(resp.GetPermissionship())
    }`;
}
