import registerDSLanguage, { DS_DARK_THEME_NAME, DS_LANGUAGE_NAME, DS_THEME_NAME } from '@code//authzed-common/src/lang/dslang';
import { useMediaQuery } from '@material-ui/core';
import Editor, { useMonaco } from "@monaco-editor/react";
import React, { useEffect, useState } from 'react';

export function SchemaDisplay(props: { schema: string, height?: string }) {
    const monacoRef = useMonaco();
    const [monacoReady, setMonacoReady] = useState(false);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Effect: Register the languages in monaco.
    useEffect(() => {
        if (monacoRef) {
            registerDSLanguage(monacoRef);
            setMonacoReady(true);
        }
        // NOTE: We only care if the monacoRef changes.
    }, [monacoRef]);

    return <div>{monacoReady && <Editor
        height={props.height ?? "50vh"}
        width='100%'
        defaultLanguage={DS_LANGUAGE_NAME}
        theme={prefersDarkMode ? DS_DARK_THEME_NAME : DS_THEME_NAME}
        value={props.schema}
        options={{
            readOnly: true,
            'semanticHighlighting.enabled': true,
            padding: {
                top: 25,
                bottom: 25,
            },
            mouseStyle: 'copy',
            minimap: {
                enabled: false
            }
        }}
    />}</div>;
}