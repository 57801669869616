import parser from '@geek/tld';
import * as CompanyEmailValidator from 'company-email-validator';

export interface OrganizationInfo {
    /**
     * id is an extracted possible ID for the organization for the user.
     */
    id: string

    /**
     * isCompanyEmail returns true if the email address is likely a company email address.
     */
    isCompanyEmail: boolean

    /**
     * userId is the first portion of the email address.
     */
    userId: string

    /**
     * companyDomain is the domain of the company email, if any.
     */
    companyDomain: string
}

/**
 * extractOrganizationInformation returns the organization information extracted for
 * the specified email address.
 */
export function extractOrganizationInformation(emailAddress: string): OrganizationInfo {
    const pieces = emailAddress.split('@');
    const parsed = parser(`https://${pieces[1]}`, { allowUnknownTLD: true });
    const domain = parsed.tld ? parsed.domain.substring(0, parsed.domain.length - parsed.tld.length - 1) : parsed.domain;

    // If a likely company email, use the domain as the organization ID. Otherwise, use the user
    // of the e-mail address.
    const isCompanyEmail = CompanyEmailValidator.isCompanyEmail(emailAddress);
    const orgId = isCompanyEmail ? domain : pieces[0];
    return {
        id: orgId,
        userId: pieces[0],
        isCompanyEmail: isCompanyEmail,
        companyDomain: parsed.domain,
    }
}