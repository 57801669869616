import { gql } from "@apollo/client";
import { PAGE_INFO_FRAGMENT, WithErrors, WITH_ERRORS_FRAGMENT } from "@code/trumpet/src/base";
import { Client } from "../types/client";
import { USER_FRAGMENT } from "./authenticateduser";


/**
 * Retrieves a client by ID.
 * @param LookupClientByIDParams
 * @returns LookupClientByIDData
 */
export const LOOKUP_CLIENT_BY_ID = {
  query: gql`
        query LookupClientById($clientId: ID!) {
            clientById(clientId: $clientId) {
               id
               title
               note
               createdBy {
                ${USER_FRAGMENT}
              }
          }
        }
    `,
  metadata: {
    workingMessage: 'Loading client',
    errorMessage: 'Failed to load client',
  }
};

export interface LookupClientByIDParams {
  /**
   * clientId is the ID of the client to lookup.
   */
  clientId: string
}

export interface LookupClientByIDData {
  clientById: Client;
}

/**
 * Query to lookup the clients under an organization.
 */
export const ORGANIZATION_CLIENTS_QUERY = gql`
query LookupOrganizationClients($organizationId: ID, $first: Int!, $after: String) {
    organizationById(organizationId: $organizationId) {
        id
        clients(first: $first, after: $after) {
            ${PAGE_INFO_FRAGMENT}
            edges {
                node {
                    id
                    title
                    note
                    createdBy {
                      ${USER_FRAGMENT}
                    }
                }
            }
        }
    }
}
`;


/**
 * Creates a new client.
 * @param title The title of the client.
 * @param note The note for the client.
 * @returns CreatedClient
 */
export const CREATE_CLIENT = {
  query: gql`
    mutation CreateClient($organizationId: ID!, $title: String!, $note: String!) {
      createClient(organizationId: $organizationId, title: $title, note: $note) {
        ${WITH_ERRORS_FRAGMENT}
        client {
          id
        }
      }
    }
  `,
  metadata: {
    workingMessage: 'Creating client',
    errorMessage: 'Failed to create client',
  },
  refetchQueries: ['LookupOrganizationClients']
};

export interface CreateClient {
  organizationId: string
  title: string
  note: string
}

export type CreatedClientData = {
  client: Client
} & WithErrors

export type CreatedClient = {
  createClient: CreatedClientData
}

/**
 * Retrieves the tokens owned by a client.
 */
export const CLIENT_TOKENS_QUERY = {
  query: gql`
        query ListClientTokens($clientId: ID!, $first: Int!, $after: String) {
          clientById(clientId: $clientId) {
            tokens(first: $first, after: $after) {
              ${PAGE_INFO_FRAGMENT}
              edges {
                node {
                  id
                  title
                  note
                  createdOn
                  createdBy {
                    ${USER_FRAGMENT}
                  }
                }
              }
            }
          }
        }
      `,
  metadata: {
    workingMessage: 'Loading client tokens',
    errorMessage: 'Failed to load client tokens',
  }
};


/**
 * Creates a new client token.
 * @param title The title of the token.
 * @param note The note for the token.
 */
export const CREATE_CLIENT_TOKEN = {
  query: gql`
  mutation CreateClientToken($clientId: ID!, $title: String!, $note: String!) {
    createClientToken(clientId: $clientId, title: $title, note: $note) {
      ${WITH_ERRORS_FRAGMENT}
      token {
        id
      }
      fullToken
    }
  }
`,
  metadata: {
    workingMessage: 'Creating client token',
    errorMessage: 'Failed to create client token',
  },
  refetchQueries: ['ListClientTokens']
};

