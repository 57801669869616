import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { PropsWithChildren } from "react";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&::before': {
                content: ' '
            },
            '&::after': {
                content: ' '
            },
            color: theme.palette.text.primary,
        },
    }));

/**
 * Defines the properties for the ExternalLink.
 */
interface ExternalLinkProps {
    /**
     * to is the URL for the link.
     */
    to: string;

    /**
     * className is the class name for the component.
     */
    className?: string;
}

/**
 * ExternalLink defines a link to an external URL.
 * @param props The props for the ExternalLink.
 * @example <ExternalLink to="https://some/url">link content</ExternalLink>
 */
export default function ExternalLink(props: PropsWithChildren<ExternalLinkProps>) {
    const classes = useStyles();

    return <a href={props.to} className={clsx(classes.root, props.className)} rel="noopener noreferrer" target="_blank">
        {props.children}
    </a>;
}