import { AuthenticatedUserData } from "@code/authzed-common/src/queries/authenticateduser";
import { Organization } from "@code/authzed-common/src/types/organization";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { OrgSelector } from "./OrgSelector";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userContext: {
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            columnGap: theme.spacing(1),
            alignItems: 'center',
        },
        item: {
            borderRight: '1px solid transparent',
            borderRightColor: theme.palette.divider,
            paddingRight: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    }));

export function UserContext(props: { userData: AuthenticatedUserData, organization: Organization | undefined }) {
    const classes = useStyles();

    return <div className={classes.userContext}>
        <div className={classes.item}>
            <Button href="mailto:support@authzed.com" size="small">Contact Support</Button>
        </div>
        <div className={classes.item}>
            <OrgSelector userData={props.userData} organization={props.organization} />
        </div>
    </div>;
}