import { createStyles, darken, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React from "react";
import Countdown from 'react-countdown';
import ExternalLink from './ExternalLink';
import { Component, StatusInfo } from "./StatusPageHook";

/**
 * StatusPageDisplayProps are the props for the status page display.
 */
export interface StatusPageDisplayProps {
    /**
     * info is the loaded status page information, if any.
     */
    info: StatusInfo | undefined;

    /**
     * componentIds, if specified, indicates the components IDs to check.
     * All others will be ignored.
     */
    componentIds?: string[] | undefined | null;
}

const severityMap: Record<"minor" | "major" | "critical", "info" | "warning" | "error"> = {
    "minor": "info",
    "major": "warning",
    "critical": "error"
}

const componentSeverityMap: Record<string, "info" | "warning" | "error"> = {
    "operational": "info",
    "under_maintenance": "info",
    "degraded_performance": "info",
    "partial_outage": "warning",
    "major_outage": "error"
};

const statusMap = {
    "operational": "Operational",
    "under_maintenance": "Under Maintenance",
    "degraded_performance": "Degraded Performance",
    "partial_outage": "Partial Outage",
    "major_outage": "Major Outage"
};

const maintenanceSeverityMap: Record<string, "info" | "warning" | "error"> = {
    "none": "info",
    "minor": "info",
    "major": "warning",
    "critical": "error"
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        componentAlert: {
            backgroundColor: () => {
                const getBackgroundColor = theme.palette.type === 'light' ? darken : lighten;
                return getBackgroundColor(theme.palette.background.default, 0.05);
            },
            margin: 0
        },
        maintenanceAlertMessage: {
            padding: 0
        },
        datetime: {
            fontSize: '85%'
        }
    }));

/**
 * StatusPageDisplay provides a display for the statuspage.io information loaded.
 * @param props The props for the alert dialog.
 * @example 
 *    const { info: statusPageInfo } = useStatusPage('somePageId');
 *    <StatusPageDisplay info={statusPageInfo} />
 */
export function StatusPageDisplay(props: StatusPageDisplayProps) {
    const classes = useStyles();

    if (props.info === undefined) {
        return <span />;
    }

    const filteredComponents = props.info.components.filter((component: Component) => !props.componentIds?.length || props.componentIds.includes(component.id))
    const degradedComponents = filteredComponents.filter((component: Component) => component.status !== "operational")

    const lessThanOneDay = (() => {
        if (props.info.scheduled_maintenances.length === 0) {
            return false;
        }

        return ((new Date(props.info.scheduled_maintenances[0].scheduled_for).valueOf()) - (new Date().valueOf())) < (60 * 60 * 24 * 1000);
    })();

    if (props.info.status.indicator !== "none" && props.info.incidents.length) {
        return <div>
            <Alert variant="filled" severity={severityMap[props.info.status.indicator]}>
                <ExternalLink to={props.info.incidents[0].shortlink}>{props.info.status.description}</ExternalLink>
            </Alert>
        </div>;
    } else if (degradedComponents.length > 0) {
        return <div>
            <Alert className={classes.componentAlert} severity={componentSeverityMap[degradedComponents[0].status]}>
                {degradedComponents[0].name}: {statusMap[degradedComponents[0].status]}
            </Alert>
        </div>;
    } else if (props.info.scheduled_maintenances.length > 0) {
        return <div>
            <Alert classes={{ message: classes.maintenanceAlertMessage }} variant="outlined" severity={maintenanceSeverityMap[props.info.scheduled_maintenances[0].impact]}>
                Scheduled Maintenance:
                <ExternalLink to={props.info.scheduled_maintenances[0].shortlink}>{props.info.scheduled_maintenances[0].name}</ExternalLink>
                {lessThanOneDay &&
                    <div>
                        <Countdown date={new Date(props.info.scheduled_maintenances[0].scheduled_for)} />
                    </div>
                }
                {!lessThanOneDay &&
                    <div className={classes.datetime}>
                        {(new Date(props.info.scheduled_maintenances[0].scheduled_for)).toString()}
                    </div>
                }
            </Alert>
        </div>;
    }

    return <span />;
}