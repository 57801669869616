import { useEffect, useState } from 'react';

/**
 * StatusInfo is the information returned from the statuspage.io embed API
 * v2.
 */
export interface StatusInfo {
    /**
     * page is the overall page information.
     */
    page: PageInfo

    /**
     * status is the overall status and summary.
     */
    status: PageStatus

    /**
     * components are the statuses of the individual components.
     */
    components: Component[]

    /**
     * incidents are any open incidents.
     */
    incidents: Incident[]

    /**
     * scheduled_maintenances are any scheduled maintenances.
     */
    scheduled_maintenances: ScheduledMaintenance[]
}

/**
 * PageStatus holds the overall page status.
 */
interface PageStatus {
    /**
     * indicator is the status of the overall status indicator. Will be `none`
     * unless there is an incidient.
     */
    indicator: "none" | "minor" | "major" | "critical"

    /**
     * description is the description of the current page, if any.
     */
    description: string
}

/**
 * PageInfo is the metadata for the monitored page/app.
 */
interface PageInfo {
    id: string
    name: string
    url: string
    updated_at: string
}

/**
 * ScheduledMaintenance is a single scheduled maintenance for the site/app.
 */
export interface ScheduledMaintenance {
    /**
     * status is the status of the maintenance.
     */
    status: "scheduled" | "in_progress" | "verifying" | "done"

    /**
     * scheduled_for is the datetime when the maintenance is scheduled.
     * @example 2020-05-01T11:30:00.000-05:00
     */
    scheduled_for: string

    /**
     * incident_updates are any incidents that have occurred during the window.
     */
    incident_updates: IncidentUpdate[]

    /**
     * impact is the impact expected for the maintenance.
     */
    impact: "none" | "minor" | "major" | "critical"

    /**
     * name is the user-visible name for the maintenance.
     */
    name: string

    /**
     * shortlink is the link to the maintenance.
     */
    shortlink: string
}

/**
 * Incident is an incident that has or is occurring.
 */
interface Incident {
    /**
     * description is the human-readable description of the incident.
     */
    description: string

    /**
     * impact is the impact for the incident.
     */
    impact: "none" | "minor" | "major" | "critical"

    /**
     * resolved_at is when the incident was resolved, if any.
     */
    resolved_at: string | undefined

    /**
     * shortlink is the link to the incident.
     */
    shortlink: string

    /**
     * status is the status of the incident.
     */
    status: "investigating" | "identified" | "monitoring" | "resolved" | "postmortem"
}

/**
 * IncidentUpdate is an update to an incident.
 */
interface IncidentUpdate {
    id: string
    status: "scheduled" | "identified" | "monitoring"
    body: string
    created_at: string
    display_at: string | undefined
    updated_at: string | undefined
}

/**
 * Component is a single component and its status.
 */
export interface Component {
    id: string

    /**
     * name is the name of the component.
     */
    name: string

    /**
     * updated_at is when the status of the component was last updated.
     */
    updated_at: string

    /**
     * status is the status of the component.
     */
    status: "operational" | "under_maintenance" | "degraded_performance" | "partial_outage" | "major_outage"

    /**
     * description is the description of the component, if any.
     */
    description: string | null

    /**
     * only_show_if_degraded indicates whether the component should only be shown
     * if in a degraded state.
     */
    only_show_if_degraded: boolean

    /**
     * components are the IDs of the components which live under this component.
     */
    components: string[]
}

interface StatusPageLoader {
    /**
     * summary loads a summary of the configured status page, invoking
     * the `success` callback on success.
     */
    summary({ success }: { success: (data: StatusInfo) => any }): any
}

interface StatusPageEmbedAPI {
    page: {
        new({ page }: { page: string }): StatusPageLoader
    }
}

declare global {
    interface Window {
        StatusPage: StatusPageEmbedAPI;
    }
}

let tagInjected = false;

/**
 * StatusPageState is the state returned by the useStatusPage hook.
 */
interface StatusPageState {
    /**
     * loading indicates whether the status page information is loading.
     */
    loading: boolean

    /**
     * info is the loading status page information, if any.
     */
    info?: StatusInfo
}

/**
 * useStatusPage is a hook which loads the status page information from a statuspage.io
 * page and makes the information available.
 */
export const useStatusPage = (pageId: string) => {
  const [state, setState] = useState<StatusPageState>({
    loading: true,
    info: undefined,
  });

  const handleGotSummary = (data: StatusInfo) => {
    setState({
      loading: false,
      info: data,
    });
  };

  const handleScriptLoaded = () => {
    try {
      const statusPageHandler = new window.StatusPage.page({ page: pageId });
      statusPageHandler.summary({
        success: handleGotSummary,
      });
    } catch (error) {
      console.error('StatusPage is unavailable', error);
    }
  };

  useEffect(() => {
    if (!pageId) {
      return;
    }

    if (!tagInjected) {
      const script = document.createElement('script');
      script.src = `https://statuspage-production.s3.amazonaws.com/se-v2.js`;
      script.async = true;
      script.onload = handleScriptLoaded;

      document.body.appendChild(script);
      tagInjected = true;

      return () => {
        document.body.removeChild(script);
      };
    }

    if (tagInjected && state.loading) {
      handleScriptLoaded();
    }
  }, [pageId]);

  return state;
};
