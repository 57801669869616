import { UserProps } from '@code/authzed-common/src/authn/user';
import { AuthenticatedUserData } from '@code/authzed-common/src/queries/authenticateduser';
import { AcceptedInvitation } from '@code/authzed-common/src/queries/invitations';
import { LookupOrganizationBySlugData, LookupOrganizationBySlugDataParams, LOOKUP_ORG_BY_SLUG } from '@code/authzed-common/src/queries/organization';
import { OrganizationPermission, usePermissionsService, WithOrgPermission } from '@code/authzed-common/src/services/permissionsservice';
import { Tenant } from '@code/authzed-common/src/types/tenant';
import { useManagedQuery } from '@code/trumpet/src/hooks';
import ListItemNavLink from '@code/trumpet/src/ListItemNavLink';
import LoadingView from '@code/trumpet/src/LoadingView';
import { SectionCaption } from '@code/trumpet/src/SectionCaption';
import { StatusPageDisplay } from '@code/trumpet/src/StatusPageDisplay';
import { useStatusPage } from '@code/trumpet/src/StatusPageHook';
import Trumpet from '@code/trumpet/src/Trumpet';
import { faCreditCard, faTh, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ComputerIcon from '@material-ui/icons/Computer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useRef } from "react";
import { useCookies } from 'react-cookie';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { OrgBilling } from '../pages/orgbilling';
import { OrgClient } from '../pages/orgclient';
import { OrgClients } from '../pages/orgclients';
import { OrgDashboard } from '../pages/orgdashboard';
import { OrgPermissions } from '../pages/orgpermissions';
import { OrgTenant } from '../pages/orgtenant';
import { OrgTenants } from '../pages/orgtenants';
import AppConfig from '../services/configservice';
import { TRUMPET_COLORS } from '../theme';
import AppTitle from './AppTitle';
import TenantLogo from './TenantLogo';
import { Tutorial } from './Tutorial';
import { UserContext } from './UserContext';
import { UserMenuOptions } from './UserMenuOptions';

interface OrganizationViewProps {
    orgSlug: string
    user: UserProps
    userData: AuthenticatedUserData
    signoutAction?: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orgView: {
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
        },
        orgLogo: {
            width: '2rem',
            height: '2rem',
            marginRight: '10px',
        },
        orgContext: {
            display: 'inline-flex',
            alignItems: 'center',
            padding: theme.spacing(1),
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
            borderRadius: '16px',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(255,255, 255, 0.07)'
            }
        },
        link: {
            color: theme.palette.text.primary,
        },
        divider: {
            backgroundColor: theme.palette.divider,
            padding: theme.spacing(1),
            textAlign: 'center'
        },
        currentOrgCheck: {
            opacity: 0,
            marginRight: theme.spacing(0.5),
        },
        currentOrgCheckSelected: {
            opacity: 1,
        },
        orgMenuItem: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            padding: theme.spacing(1.5),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    }));


interface LocationState {
    skipTutorial?: boolean
    acceptedInvitation?: AcceptedInvitation | undefined
}

export function OrganizationView(props: OrganizationViewProps) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    const { data: organizationData, loading } = useManagedQuery<LookupOrganizationBySlugData, LookupOrganizationBySlugDataParams>(LOOKUP_ORG_BY_SLUG, {
        variables: {
            slug: props.orgSlug
        }
    })
    const { info: statusPageInfo } = useStatusPage(AppConfig().statuspage.pageId);

    const organization = organizationData?.organizationBySlug;
    const permissionsService = usePermissionsService();

    const state = location.state as (LocationState | undefined);

    const [cookies] = useCookies(['skip-tutorial', 'tutorial-step']);
    const tutorialRedirected = useRef(false);

    useEffect(() => {
        const showIntroTutorial = (
            (cookies['skip-tutorial'] !== 'true' && state?.skipTutorial !== true) &&
            (cookies['tutorial-step'] ||
                (organization !== undefined && props.userData.authenticatedUser && props.userData.authenticatedUser.recentOrgs.length < 2 &&
                    organization?.recentTenants?.length === 0))
        );
        if (!tutorialRedirected.current && organization && showIntroTutorial && !location.pathname.endsWith('/tutorial')) {
            tutorialRedirected.current = true;
            history.replace(`/organization/${organization.slug}/tutorial`);
        }
    }, [location, organization, state, cookies, history, props.userData.authenticatedUser?.recentOrgs]);

    useEffect(() => {
        if (organization) {
            document.title = `authzed | ${organization.name}`;
        }
    }, [organization]);

    const hasOrgPermission = permissionsService.checkOrg(organization, OrganizationPermission.CreateClient, OrganizationPermission.ChangeMemberRole, OrganizationPermission.ManageBilling);

    let drawerContent = (organization !== undefined) ? (() => {
        const prefix = `/organization/${organization.slug}`;
        return <div>
            <List style={{ padding: 0 }}>
                <ListItemNavLink icon={<DashboardIcon />} primary="Dashboard" to={`${prefix}`} />
            </List>

            {organization !== undefined && (organization.recentTenants?.length ?? 0) > 0 &&
                <div>
                    <SectionCaption title="Permissions Systems" />
                    <List style={{ padding: 0 }}>
                        {organization?.recentTenants?.map((tenant: Tenant) => {
                            return <ListItemNavLink key={tenant.id} icon={<TenantLogo tenant={tenant} />} primary={<>
                                <div>{tenant.name}</div>
                                <Typography variant="caption" color="textSecondary" >{tenant.slug}/</Typography>
                            </>} to={`${prefix}/systems/${tenant.slug}`} />;
                        })}
                        <ListItemNavLink icon={<FontAwesomeIcon icon={faTh} size="lg" />} primary="All Systems" to={`${prefix}/systems`} />
                    </List>
                </div>}

            {organization !== undefined && hasOrgPermission && <>
                <SectionCaption title={`${organization.name} Settings`} />
                <List style={{ padding: 0 }}>
                    <WithOrgPermission organization={organization} permission={OrganizationPermission.CreateClient}>
                        <ListItemNavLink parentMatch={true} icon={<ComputerIcon />} primary="API Clients" to={`${prefix}/clients`} />
                    </WithOrgPermission>
                    <WithOrgPermission organization={organization} permission={OrganizationPermission.ChangeMemberRole}>
                        <ListItemNavLink icon={<FontAwesomeIcon icon={faUserLock} size="lg" />} primary="Administrators" to={`${prefix}/permissions`} />
                    </WithOrgPermission>
                    <WithOrgPermission organization={organization} permission={OrganizationPermission.ManageBilling}>
                        {AppConfig().stripe.featureBilling && <ListItemNavLink icon={<FontAwesomeIcon icon={faCreditCard} size="lg" />} primary="Billing" to={`${prefix}/billing`} />}
                    </WithOrgPermission>
                </List>
            </>}
        </div>
    })() : undefined;

    let titleBarState = {
        title: <AppTitle />,
        notificationCount: 0, /* FILL ME IN */
        hasAdditionalNotifications: false /*HasNextPage(data?.authenticatedUser.notifications)*/,
        user: props.user,
        signoutAction: props.signoutAction || (() => null),
        notificationElement: undefined, // {notificationViews}
        //searchElement: <QuickSearch />,
        statusElement: <StatusPageDisplay info={statusPageInfo} componentIds={AppConfig().statuspage.componentIds} />,
        additionalUserMenuOptions: <UserMenuOptions />,
        userContext: <UserContext userData={props.userData} organization={organization} />
    }

    return <Trumpet themedProps={TRUMPET_COLORS} key="org-trumpet" titleBarState={titleBarState} drawerContent={location.pathname.endsWith('/tutorial') ? undefined : drawerContent}>
        {loading && organization === undefined && <LoadingView message="Loading Organization" />}
        {!loading && organization === undefined && <Alert severity="error">Unknown organization</Alert>}
        {organization !== undefined &&
            <div className={classes.orgView}>
                {!!state?.acceptedInvitation?.organization?.id &&
                    <Alert severity="success" variant="filled" style={{ marginBottom: '20px' }}>Accepted invitation to organization</Alert>
                }
                {!!state?.acceptedInvitation?.tenant?.id &&
                    <Alert severity="success" variant="filled" style={{ marginBottom: '20px' }}>Accepted invitation to permissions system</Alert>
                }

                <Switch>
                    <Route path="/organization/:slug" exact>
                        <OrgDashboard organization={organization} />
                    </Route>

                    <Route path="/organization/:slug/tutorial" exact>
                        <Tutorial organization={organization} />
                    </Route>

                    {organization !== undefined && permissionsService.checkOrg(organization, OrganizationPermission.ChangeMemberRole) && <Route path="/organization/:slug/permissions" exact>
                        <OrgPermissions organization={organization} />
                    </Route>}

                    {organization !== undefined && permissionsService.checkOrg(organization, OrganizationPermission.ManageBilling) && AppConfig().stripe.featureBilling && <Route path="/organization/:slug/billing" exact>
                        <OrgBilling organization={organization} />
                    </Route>}

                    {organization !== undefined && permissionsService.checkOrg(organization, OrganizationPermission.CreateClient) && <Route path="/organization/:slug/clients" exact render={(props) => {
                        return <OrgClients organization={organization} {...props} />;
                    }} />}

                    <Route path="/organization/:slug/clients/:clientid" exact render={(props) => (
                        <OrgClient clientId={props.match.params.clientid} organization={organization} />
                    )} />

                    <Route path="/organization/:slug/systems" exact render={(props) => (
                        <OrgTenants organization={organization} {...props} />
                    )} />

                    <Route path="/organization/:slug/systems/:tenantslug" exact render={(props) => (
                        <OrgTenant tenantSlug={props.match.params.tenantslug} organization={organization} />
                    )} />
                    <Route>
                        <Redirect to="/404" />
                    </Route>
                </Switch>
            </div>
        }
    </Trumpet>;
}
