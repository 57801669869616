/**
 * Profile holds the profile information about the user.
 */
export interface Profile {
    /**
     * email is the user's email address. May be missing if there is no known email
     * address for the user OR if the user has not given consent to have their email
     * sent.
     */
    email?: string

    /**
     * name is the user's name. May be an empty string.
     */
    name: string

    /**
     * avatarUrl is the URL of the user's avatar. May be an empty string.
     */
    avatarUrl: string
}

export interface User {
    id: string
    profile?: Profile | null
}

export function getUserTitle(user: User) {
    return user.profile?.name || user.profile?.email || user.id;
}