import amplitude, { AmplitudeClient } from 'amplitude-js';
import React, { createContext, PropsWithChildren } from 'react';

/**
 * Context that provides an Amplitude client instance.
 */
export const AmplitudeContext = createContext<AmplitudeClient | undefined>(undefined);

export interface AmplitudeClientProps {
    /**
     * apiKey is the Amplitude project API key.
     */
    apiKey?: string

    /**
     * userId is an app user id.
     */
    userId?: string

    /**
     * initialize is whether to initialize the client. Default is true.
     */
    initialize?: boolean | undefined
}

/**
 * Context provider that provides an Amplitude client instance for the
 * given initialization inputs. Will be undefined if apiKey is not provided.
 */
export const AmplitudeClientProvider = (props: PropsWithChildren<AmplitudeClientProps>) => {
    const { apiKey, userId } = props;
    let client = undefined;
    if (apiKey && props.initialize !== false) {
        client = amplitude.getInstance()
        client.init(apiKey, userId);
    }

    return (
        <AmplitudeContext.Provider value={client}>
            {props.children}
        </AmplitudeContext.Provider>
    );
}

/**
 * Helper function for wrapping a handler with an Amplitude call to log the provided event.
 */
export const wrapWithAmplitudeEvent = (amplitudeClient: (AmplitudeClient | undefined), handler: () => void, event: string, properties = undefined) => {
    return () => {
        amplitudeClient?.logEvent(event, properties);
        handler();
    }
}
