import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDeepCompareMemo } from "use-deep-compare";

export type TabName = string | Record<string, boolean>;

/**
 * useHistoricalTabs provides a hook for connecting up Tabs that change the `tab` URL query param
 * and react to changes in that parameter.
 * @param validTabNames The full set of valid tab names.
 * @param defaultTabName If specified, the name of the default tab to show. If not specified, the
 * first tab name in `validTabNames` is used.
 * @example
 *      const { currentTabName, handleChangeTab, setCurrentTabName } = useHistoricalTabs(['first', 'second', 'third']);
 *      <Tabs onChange={handleChangeTab} value={currentTabName}>
 *         <Tab value="first" ... />
 *         <Tab value="second" ... />
 *         <Tab value="third" ... />
 *      </Tabs>
 *      <TabPanel index="first" value={currentTabName}>...</TabPanel>
 *      ...
 *      
 *      // To set the current tab:
 *      setCurrentTabName('second');
 */
export default function useHistoricalTabs(validTabNames: TabName[], defaultTabName?: string) {
    const history = useHistory();

    const tabNames = useDeepCompareMemo(() => {
        return validTabNames.flatMap((valid: TabName) => {
            switch (typeof valid) {
                case 'string':
                    return [valid];

                case 'object':
                    return Object.keys(valid).map((key: string) => valid[key] ? key : undefined).filter((tabName: string | undefined) => !!tabName) as string[];

                default:
                    throw Error('invalid tab name')
            }
        });
    }, [validTabNames]);

    const getTabNameFromHistory = () => {
        if (history.location.search.length <= 1) {
            return undefined;
        }

        const urlParams = new URLSearchParams(history.location.search.substr(1));
        const tabName = urlParams.get('tab') || '';
        if (tabName === '') {
            return undefined;
        }

        return tabName;
    };

    const validate = (tabName: string | undefined, opt_default?: string) => {
        if (tabName === undefined || tabNames.indexOf(tabName) < 0) {
            tabName = opt_default || defaultTabName || tabNames[0];
        }
        return tabName;
    };

    const hasTab = (tabName: string) => {
        return tabNames.indexOf(tabName) >= 0;
    }

    // The startingTabName is, in order of precedence:
    //  1) The tab in the history
    //  2) The default tab name
    //  3) The first tab in the tab names
    const startingTabName = validate(getTabNameFromHistory() || defaultTabName || tabNames[0]);
    if (getTabNameFromHistory() !== startingTabName) {
        history.replace(`?tab=${startingTabName}`);
    }

    const [currentTabName, setCurrentTabName] = useState(startingTabName);

    const handleChangeTab = (event: React.ChangeEvent<{}>, selectedTabName: string) => {
        selectedTabName = validate(selectedTabName, currentTabName);
        if (selectedTabName !== currentTabName) {
            setCurrentTabName(selectedTabName);
            history.push(`?tab=${selectedTabName}`);
        }
    };

    // NOTE: this useEffect registers a callback on any history changes to watch for URL changes
    // that include the `tab` query parameter. If found, we change the tab accordingly.
    useEffect(() => {
        const unregisterCallback = history.listen(() => {
            const tabName = validate(getTabNameFromHistory(), currentTabName);
            if (tabName !== undefined && tabName !== currentTabName) {
                setCurrentTabName(tabName);
            }
        });
        return unregisterCallback;
    });

    return { currentTabName, handleChangeTab, setCurrentTabName, hasTab }
}