import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import * as Sentry from "@sentry/react";
import React, { PropsWithChildren, useState } from "react";
import { useAuthentication } from "../authn/useauthentication";


/**
 * AuthedSentryErrorBoundary adds an error boundary to catch errors and display a feedback
 * collection box, while also reporting the error to Sentry with the necessary user information
 * attached (if any.)
 * 
 * NOTE: Requires Sentry to be configured *and* must be under an Auth0Provider.
 */
export default function AuthedSentryErrorBoundary(props: PropsWithChildren<{}>) {
    const { user } = useAuthentication();
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    return <Sentry.ErrorBoundary fallback={({ error, componentStack, resetError }) => (
        <Dialog onClose={handleClose} open={open} style={{ zIndex: 100 }}>
            <Alert severity="error">Something went wrong. We've noted the error and will work to address it</Alert>
            <details style={{ margin: '10px' }}>
                <summary>Error Information</summary>
                <pre>{error.toString()}</pre>
                <details style={{ margin: '10px' }}>
                    <summary>Stack Trace</summary>
                    <pre>{componentStack}</pre>
                </details>
            </details>
        </Dialog>
    )} dialogOptions={{
        user: {
            email: user?.emailAddress ?? '',
            name: user?.fullName ?? '',
        },
        title: 'It looks like something went wrong',
        subtitle2: 'Provide any info that may help us fix this issue',
    }} showDialog>
        {props.children}
    </Sentry.ErrorBoundary>
}