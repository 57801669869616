import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { SideDrawerOpenContext } from './SideDrawer';

export interface SectionCaptionProps {
    title: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            padding: '2px',
            paddingTop: '4px',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.divider,
            overflow: 'hidden',
            transition: 'all ease-in-out 200ms'
        },
        isOpen: {
            height: '25px',
        },
        isClosed: {
            height: '0px'
        }
    }));


/**
 * SectionCaption defines a caption of a section in the sidebar.
 */
export const SectionCaption = (props: SectionCaptionProps) => {
    const classes = useStyles();
    return <SideDrawerOpenContext.Consumer>
        {(value: boolean) => {
            return <Typography className={`${classes.root} ${value ? classes.isOpen : classes.isClosed}`} variant="caption">{props.title}</Typography>;
        }}
    </SideDrawerOpenContext.Consumer>;
};
