import { TenantPermission } from "../services/permissionsservice";
import { Client } from "./client";
import { ObjectDefinition } from "./definition";
import { Permissions } from "./permissions";
import { User } from "./user";

/**
 * TenantRole holds the different roles for a user or client in a tenant.
 */
export enum TenantRole {
    VIEWER = "VIEWER",
    WRITER = "WRITER",
    ADMIN = "ADMIN"
}

/**
 * TenantKind holds the different kinds for a tenant.
 */
export enum TenantKind {
    DEVELOPMENT = "DEVELOPMENT",
    PRODUCTION = "PRODUCTION",
    SYSTEM = "SYSTEM"
}

/**
 * Tenant represents a single tenant under an organization.
 */
export interface Tenant {
    /**
     * id is the unique ID for the tenant.
     */
    id: string

    /**
     * slug is the unique slug for the tenant.
     */
    slug: string

    /**
     * name is the human-readable name of the tenant.
     */
    name: string

    /**
     * description is the human-readable description for the tenant.
     */
    description: string

    /**
     * kind is the kind of the tenant.
     */
    kind: TenantKind

    /**
     * schema is the schema for the tenant, if loaded.
     */
    schema?: string

    /**
     * createdBy is the user that created the tenant.
     */
    createdBy?: User

    /**
     * definitions are the definitions defined in the tenant.
     */
    definitions?: ObjectDefinition[]

    /**
     * metricsViewToken is the token for viewing metrics on the tenant.
     */
    metricsViewToken?: string

    /**
     * addedClientDefaultRole is the default role to given clients added to the tenant.
     */
    addedClientDefaultRole?: TenantRole

    /**
     * permissions are the permissions loaded for the organization.
     */
    permissions: Permissions<TenantPermission>
}


export type MemberDirectUser = User & {
    __typename: "MemberDirectUser"
}


export type MemberIndirectUser = User & {
    __typename: "MemberIndirectUser"
}

export type MemberClient = Client & {
    __typename: "MemberClient"
}

export type TenantMember = (MemberDirectUser | MemberIndirectUser | MemberClient) & {
    memberRole: TenantRole
};