import React from "react";

export const FavIconServiceContext = React.createContext<FavIconService | undefined>(undefined);

interface FavIconResponse {
    domain: string
    icons: {
        src: string
    }[]
}

/**
 * FavIconService is a helper class which makes use of the https://favicongrabber.com/ API
 * to find a favicon for a domain, if any.
 */
export class FavIconService {
    private cache: Record<string, FavIconResponse | undefined> = {};
    private pending: Record<string, Promise<string | undefined>> = {};

    public async lookup(domainName: string): Promise<string | undefined> {
        // Check the cache for a result.
        if (domainName in this.cache) {
            const data = this.cache[domainName];
            if (!data?.icons?.length) {
                return undefined;
            }

            return data.icons[0].src;
        }

        // If there is a pending call, return its future.
        if (domainName in this.pending) {
            return this.pending[domainName];
        }

        // Otherwise create a new future and spin off a call.
        this.pending[domainName] = fetch(`https://favicongrabber.com/api/grab/${domainName}`).then((resp: any) => {
            if (resp.status !== 200) {
                return undefined;
            }

            return resp.json();
        }).then((data: FavIconResponse) => {
            if (!data?.icons?.length) {
                return undefined;
            }

            this.cache[domainName] = data;
            return data.icons[0].src;
        });

        return this.pending[domainName];
    }
}
