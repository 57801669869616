import { ApolloProvider } from '@apollo/client';
import { AuthenticationRequired, AuthnProvider } from '@code/authzed-common/src/authn/provider';
import { useAuthentication } from '@code/authzed-common/src/authn/useauthentication';
import { AuthenticatedUser, AuthenticatedUserData, USER_DATA_QUERY } from '@code/authzed-common/src/queries/authenticateduser';
import { FavIconService, FavIconServiceContext } from '@code/authzed-common/src/services/faviconservice';
import { UserEvent } from '@code/authzed-common/src/types/events';
import AuthedSentryErrorBoundary from '@code/authzed-common/src/util/AuthedSentryErrorBoundary';
import { AmplitudeClientProvider, AmplitudeContext } from '@code/trumpet/src/AmplitudeClient';
import { useGoogleAnalytics } from '@code/trumpet/src/GoogleAnalyticsHook';
import { useFixedQuery } from '@code/trumpet/src/hooks';
import LoadingView from "@code/trumpet/src/LoadingView";
import TrumpetThemed from '@code/trumpet/src/TrumpetThemed';
import { Box } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Elements } from '@stripe/react-stripe-js';
import React, { useContext, useEffect, useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch, useHistory, useLocation } from "react-router-dom";
import './App.css';
import { InvitationHandler, InvitationTracker } from './components/InvitationHandler';
import { NoOrganizationView } from './components/NoOrganizationView';
import { OrganizationView } from './components/OrganizationView';
import { AuthErrorDisplay, PageNotFound } from './components/WarningDisplay';
import { CurrentUserContext } from './context';
import buildClient from './services/apollo';
import AppConfig from './services/configservice';
import { useSharedAppStyles } from './sharedstyles';
import { TRUMPET_COLORS } from './theme';


// NOTE: Placed here to ensure it is called outside of the Render.
const stripePromise = AppConfig().stripe.stripeKey ? (async () => {
  const stripe = await import('@stripe/stripe-js');
  return await stripe.loadStripe(AppConfig().stripe.stripeKey);
})() : Promise.resolve(null);

const AuthenticatedView = () => {
  // Register GA hook.
  useGoogleAnalytics(AppConfig().ga.measurementId);

  const { isLoading, user, logout, getAuthToken } = useAuthentication();

  useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user.emailAddress ?? '', name: user.fullName, id: user.id });
    }
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>
  }

  const StripeElements = AppConfig().stripe.stripeKey ? Elements : Box;
  return <ApolloProvider client={buildClient(AppConfig().authzed.endpoint, getAuthToken)}>
    <CookiesProvider>
      <StripeElements stripe={stripePromise}>
        <AmplitudeClientProvider apiKey={AppConfig().amplitude.apiKey} userId={user?.id}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <WithUserView user={user} signoutAction={() => logout()} />
          </BrowserRouter>
        </AmplitudeClientProvider>
      </StripeElements>
    </CookiesProvider>
  </ApolloProvider>;
};

interface WithUserProps {
  user: any
  signoutAction?: () => void
}

const UpdateGA = (props: { user: AuthenticatedUser }) => {
  const { setUser } = useGoogleAnalytics(AppConfig().ga.measurementId);

  setUser({
    'user_id': props.user.id,
    'authn_id': props.user.authnId
  });

  return <span />;
};

const UpdateAmplitude = (props: { user: AuthenticatedUser }) => {
  const location = useLocation();
  const amplitudeClient = useContext(AmplitudeContext);
  amplitudeClient?.setUserId(props.user.id);
  amplitudeClient?.logEvent(UserEvent.ViewedPage, { path: location.pathname });

  return <span />;
};



export function WithUserView(props: WithUserProps) {
  return <TrumpetThemed {...TRUMPET_COLORS}>
    <InvitationHandler>
      <ThemedAppLayout {...props} />
    </InvitationHandler>
  </TrumpetThemed>;
};


function ThemedAppLayout(props: WithUserProps) {
  const { error, data: userData, refetch } = useFixedQuery<AuthenticatedUserData>(USER_DATA_QUERY);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!userData?.authenticatedUser || location.pathname !== '/') {
      return;
    }

    // Not part of an organization. Redirect to creation.
    if (!userData.authenticatedUser.recentOrgs.length) {
      history.push(`/createorg`);
      return;
    }

    // Part of a single organization: Redirect to the organization.
    if (userData.authenticatedUser.recentOrgs.length === 1) {
      history.push(`/organization/${userData.authenticatedUser.recentOrgs[0].slug}`);
      return;
    }

    // Otherwise, redirect to organizations page.
    history.push(`/organizations`);

    // NOTE: We do not want to refire if `history` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, location]);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const sharedAppClasses = useSharedAppStyles({ prefersDarkMode: prefersDarkMode });

  const handleRefetch = () => {
    refetch!();
  };

  return <div className={sharedAppClasses.root}>
    <div className={sharedAppClasses.contentRoot}>
      <CurrentUserContext.Provider value={userData}>
        {error !== undefined &&
          <AuthErrorDisplay error={error} refetch={handleRefetch} />}
        {userData === undefined && error === undefined && <LoadingView message="Loading" />}
        {!!userData?.authenticatedUser && <UpdateGA user={userData.authenticatedUser} />}
        {!!userData?.authenticatedUser && <UpdateAmplitude user={userData.authenticatedUser} />}
        <Switch>
          {!!userData?.authenticatedUser && <Route path="/organization/:orgslug" render={(routeProps) => (
            <OrganizationView orgSlug={routeProps.match.params.orgslug} user={props.user} userData={userData} signoutAction={props.signoutAction} />
          )} />}
          {!!userData?.authenticatedUser && <Route path={["/organizations", "/createorg", "/tokens"]} exact>
            <NoOrganizationView user={props.user} userData={userData} signoutAction={props.signoutAction} />
          </Route>}
          <Route path="/" exact>
            {error === undefined && <LoadingView message="Loading" />}
          </Route>
          <Route path="/404" exact>
            <PageNotFound />
          </Route>
          {!!userData?.authenticatedUser && <Route>
            <PageNotFound />
          </Route>}
        </Switch>
      </CurrentUserContext.Provider>
    </div>
  </div>
};

function LoadingAuthView() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const classes = useSharedAppStyles({ prefersDarkMode: prefersDarkMode });
  return <div className={classes.root}>
    <div className={classes.contentRoot}>
      <LoadingView message="Loading" />
    </div>
  </div>
}

const loadingView = () => {
  return <TrumpetThemed {...TRUMPET_COLORS}><LoadingAuthView /></TrumpetThemed>
};

const ProtectedAuthenticatedView = () => {
  return <AuthenticationRequired loadingView={loadingView}>
    <AuthenticatedView />;
  </AuthenticationRequired>;
}

function App() {
  if (AppConfig().sentry.dsn) {
    Sentry.init({
      dsn: AppConfig().sentry.dsn!,
      release: "authzedui@" + process.env.npm_package_version,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
    });
  };

  const faviconService = useMemo(() => {
    return new FavIconService();
  }, []);

  return <InvitationTracker>
    <AuthnProvider config={AppConfig()}>
      <AuthedSentryErrorBoundary>
        <FavIconServiceContext.Provider value={faviconService}>
          <ProtectedAuthenticatedView />
        </FavIconServiceContext.Provider>
      </AuthedSentryErrorBoundary>
    </AuthnProvider>
  </InvitationTracker>;
}

export default App;
