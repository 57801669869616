import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import React from 'react';
import { LinkProps as RouterLinkProps, NavLink } from 'react-router-dom';
import { SideDrawerOpenContext } from './SideDrawer';

/**
 * Defines the properties for the ListItemNavLink.
 */
interface ListItemNavLinkProps {
    /**
     * The icon to include in the ListItemNavLink, if any.
     */
    icon?: React.ReactElement;

    /**
     * The primary text for the ListItemNavLink. Should describe the section.
     */
    primary: React.ReactElement | string;

    /**
     * The route path to which clicking the ListItemNavLink should navigate.
     */
    to: string;

    /**
     * parentMatch, if true, will match all links under the specified `to`. Defaults
     * to false.
     */
    parentMatch?: boolean;

    /**
     * className is the CSS class name to apply to the root element, if any.
     */

    className?: string;

    /**
     * control is a right hand side control to display for the nav link.
     */
    control?: React.ReactElement;
}

// Based on: https://material-ui.com/guides/composition/#link

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItemText: {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        listItemIcon: {
        },
        listItemIconSizer: {
            display: 'inline-block',
            width: '2em',
            textAlign: 'center'
        },
        navActive: {
            backgroundColor: theme.palette.primary.main,
            '& $listItemText': {
                fontWeight: 'bold',
                color: theme.palette.primary.contrastText,
            },
            '& $listItemIcon path': {
                fill: theme.palette.primary.contrastText,
            },
            '&:hover $listItemText': {
                color: theme.palette.text.primary,
            },
            '&:hover $listItemIcon path': {
                fill: theme.palette.text.primary,
            },
        },
        control: {
            opacity: 0,
            transition: 'opacity ease-in-out 250ms'
        },
        listItem: {
            '&:hover span': {
                opacity: 1,
            }
        }
    }))

/**
 * ListItemNavLink defines a styled NavLink that is placed in a side drawer
 * and navigates to a specific route when clicked. The link is also auto-styled
 * when the current route matches the nav link.
 * 
 * @param props The properties for the ListItemNavLink.
 * @example <ListItemNavLink icon={<Dashboard />} primary="Dashboard" to="/dashboard" />
 */
export default function ListItemNavLink(props: ListItemNavLinkProps) {
    const classes = useStyles();
    const { icon, primary, to, parentMatch } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <NavLink exact={!parentMatch} to={to} ref={ref} activeClassName={classes.navActive}  {...itemProps} />
            )),
        [to, classes, parentMatch],
    );

    return (
        <SideDrawerOpenContext.Consumer>
            {(isOpen: boolean) => {
                const item = <ListItem className={clsx(classes.listItem, props.className)} button component={renderLink}>
                    {icon ? <ListItemIcon className={classes.listItemIcon}>
                        <span className={classes.listItemIconSizer}>
                            {icon}
                        </span>
                    </ListItemIcon> : null}
                    <ListItemText classes={{ 'primary': classes.listItemText }} primary={primary} />
                    {props.control && <span className={classes.control}>{props.control}</span>}
                </ListItem>;

                return isOpen ? item : <Tooltip title={props.primary} placement="right-end">
                    {item}
                </Tooltip>;
            }}
        </SideDrawerOpenContext.Consumer>
    );
}