import { User } from '@code/authzed-common/src/types/user';
import UserIcon from "@code/trumpet/src/UserIcon";
import React from "react";


/**
 * Defines the properties for the UserLogo.
 */
interface UserLogoProps {
    /**
     * The user.
     */
    user: User;

    /**
     * className is the custom CSS class name for this component, if any.
     */
    className?: string

    /** 
     * size is the size of the icon.
     */
    size?: "small" | "normal"
}

export default function UserLogo(props: UserLogoProps) {
    const username = props.user.profile?.name || props.user.profile?.email || props.user.id;
    return <UserIcon size={props.size} className={props.className} username={username} avatarUrl={props.user.profile?.avatarUrl} />
}