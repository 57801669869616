import { Tenant, TenantKind } from "@code/authzed-common/src/types/tenant";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface StyleProps {
    prefersDarkMode: boolean
}


export const useSharedAppStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            backgroundColor: theme.palette.background.default,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            '&::before': {
                content: '""',
                position: 'fixed',
                backgroundColor: theme.palette.background.default,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 0,
                backgroundImage: `url(${process.env.PUBLIC_URL}/texture.svg)`,
                backgroundSize: 'contain',
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                filter: (props: StyleProps) => props.prefersDarkMode ? 'grayscale(1)' : '',
                opacity: (props: StyleProps) => props.prefersDarkMode ? 0.3 : 1,
            }
        },
        contentRoot: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
        },
    }));



export const useSharedStyles = makeStyles((theme: Theme) =>
    createStyles({
        breadcrumbs: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                marginRight: theme.spacing(1)
            },
            '& a': {
                textDecoration: 'none',
            }
        },
        link: {
            color: theme.palette.text.primary,
        },
        rootError: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '75%',
            width: '100%',
            flexDirection: 'column',
        },
        inlineLogoContainer: {
            '& svg': {
                height: '50px',
                '& path': {
                    fill: theme.palette.grey[600]
                }
            },
            marginBottom: theme.spacing(3)
        }
    }));


interface tenantStyleProps {
    tenant: Tenant | undefined
}

export const useSharedTenantStyles = makeStyles((theme: Theme) =>
    createStyles({
        tenantTag: {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            padding: '6px',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
        tenantKind: {
            display: 'flex',
            alignItems: 'center',
            '&::before': {
                content: '""',
                display: 'inline-block',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                marginRight: '6px',
                backgroundColor: (props: tenantStyleProps) => {
                    if (props.tenant?.kind === undefined) {
                        return undefined;
                    }

                    switch (props.tenant?.kind) {
                        case TenantKind.SYSTEM:
                            return theme.palette.info.dark;

                        case TenantKind.PRODUCTION:
                            return theme.palette.success.dark;

                        case TenantKind.DEVELOPMENT:
                            return theme.palette.warning.dark;
                    }

                    return undefined;
                }
            }
        },
    }));
