import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { schemeCategory10 } from 'd3-scale-chromatic';
import React from 'react';
import {
    createContainer,
    VictoryAxis,
    VictoryChart,
    VictoryContainer,
    VictoryCursorContainerProps,
    VictoryLabel,
    VictoryLegend,
    VictoryLine,
    VictoryVoronoiContainerProps
} from 'victory';
import { PromQueryResult } from './hook';
import buildVictoryTheme from './theme';

interface ActivePoint {
    kindIndex: number;
    y: number
}

interface StyleProps {
    victoryTooltipStyles: {
        fontFamily: any
        fontSize: number
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        legendTooltip: {
            display: 'inline-block',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '4px',
            padding: '6px',
            fontFamily: (props: StyleProps) => props.victoryTooltipStyles.fontFamily,
            fontSize: (props: StyleProps) => props.victoryTooltipStyles.fontSize,
            pointerEvents: 'none',
        },
        legendTooltipColor: {
            display: 'inline-block',
            width: (props: StyleProps) => props.victoryTooltipStyles.fontSize - 2,
            height: (props: StyleProps) => props.victoryTooltipStyles.fontSize - 2,
            border: '1px solid transparent',
            borderColor: theme.palette.divider,
        },
        legendTooltipItem: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto auto 12px 1fr',
            width: '100%',
            columnGap: '6px',
        },
        legendValue: {
            textAlign: 'right',
            fontWeight: 'bold',
            fontSize: '95%',
        },
    }));

export interface PrometheusResultChartProps {
    /**
     * width is the width of the chart, in pixels.
     */
    width: number;

    /**
     * height is the height of the chart, in pixels.
     */
    height: number;

    /**
     * result is the Prometheus query result to graph in the chart.
     */
    result: PromQueryResult

    /**
     * legendTitle is the (optional) title for the legend.
     */
    legendTitle?: string

    /**
     * legendGenerator, if specified, is the generator for creating legends on the chart based
     * on the metric metadata.
     */
    legendGenerator?: (metric: Record<string, any>) => string

    /**
     * valueFormat, if specified, is the format suffix for the Y-axis of the chart.
     */
    valueFormat?: string

    /**
     * scalarFormatter, if specified, formats the scalar value before presenting on the chart
     */
    scalarFormatter?: (value: string) => string

    /**
     * getDataColor returns the color for the dataset of the given index. If unspecified,
     * will use default colors.
     */
    getDataColor?: (index: number) => string
}

const TOOLTIP_ADJUST = 150 // pixels

/**
 * PrometheusResultChart renders a graphical chart view of the result of a Prometheus query.
 */
export default function PrometheusResultChart(props: PrometheusResultChartProps) {
    const theme = useTheme();
    const { theme: victoryTheme, tooltip: victoryTooltipStyles, baseProps: baseProps } = buildVictoryTheme(theme, props.width, props.height);
    const classes = useStyles({ victoryTooltipStyles: victoryTooltipStyles });

    switch (props.result.status) {
        case 'error':
            return <Alert severity="error">Could not load data: {props.result.error ?? ''}</Alert>

        case 'success':
            switch (props.result.data.resultType) {
                case 'matrix':
                    const metricCount = props.result.data.result.length;

                    let legends: string[] = [];
                    if (props.legendGenerator !== undefined) {
                        legends = props.result.data.result.map((result, index) => {
                            if (!props.legendGenerator) { return '' }
                            return props.legendGenerator(result.metric);
                        });
                    }

                    const getDataColor = props.getDataColor ?? ((index: number) => {
                        const colors = ['#9c9ede', '#6b6ecf', '#5254a3', '#393b79']
                        if (metricCount > 4) {
                            return schemeCategory10[index];
                        }

                        return colors[index];
                    });

                    const Container = createContainer<VictoryCursorContainerProps, VictoryVoronoiContainerProps>("voronoi", "cursor");
                    const LegendTooltip = (props: any) => {
                        const p = props as { x: number, y: number, activePoints: ActivePoint[] };

                        // TODO(jschorr): Properly measure the tooltip and adjust its left accordingly.
                        const limit = props.width - TOOLTIP_ADJUST
                        const x = p.x > TOOLTIP_ADJUST ? TOOLTIP_ADJUST : p.x;
                        return <foreignObject x={x} y={p.y} width={props.width} height={props.height}>
                            <div className={classes.legendTooltip}>
                                {p.activePoints.map((activePoint: ActivePoint) => {
                                    return <div className={classes.legendTooltipItem} key={activePoint.kindIndex}>
                                        <div className={classes.legendTooltipColor} style={{ backgroundColor: getDataColor(activePoint.kindIndex) }} />
                                        <span>{activePoint.kindIndex < legends.length ? legends[activePoint.kindIndex] : '(Unknown)'}</span>
                                        <span />
                                        <code className={classes.legendValue}>{roundTo2DecimalPlaces(activePoint.y)}</code>
                                    </div>
                                })}
                            </div>
                        </foreignObject>;
                    };

                    return <VictoryChart key="chart" animate={{ duration: 1000 }} domainPadding={20} theme={victoryTheme} containerComponent={
                        <Container
                            mouseFollowTooltips
                            voronoiDimension="x"
                            cursorDimension="x"
                            labels={({ datum }) => {
                                return `${datum.y}`;
                            }}
                            cursorComponent={
                                <line style={{ stroke: theme.palette.text.primary, strokeWidth: 1 }} />
                            }
                            labelComponent={<LegendTooltip />}
                        />
                    }>
                        {props.legendGenerator !== undefined && <VictoryLegend
                            centerTitle
                            orientation="horizontal"
                            x={15}
                            y={props.height - 20}
                            data={
                                props.result.data.result.map((result, index) => {
                                    return {
                                        name: legends[index], symbol: { fill: getDataColor(index) },
                                    }
                                })
                            }
                        />}

                        <VictoryAxis
                            dependentAxis
                            tickFormat={props.valueFormat ? ((y: number) => `${y} ${props.valueFormat}`) : undefined}
                        />
                        <VictoryAxis
                            tickFormat={(x: number) => formatTime(x)}
                        />
                        {
                            props.result.data.result.map((result, index) => {
                                const data = result['values'].map((item: Array<any>) => {
                                    const value = parseFloat(item[1]);
                                    return { kindIndex: index, x: item[0], y: isNaN(value) ? null : value }
                                });

                                return <VictoryLine
                                    key={index}
                                    interpolation="natural"
                                    data={data}
                                    style={{
                                        data: { stroke: getDataColor(index) },
                                    }}
                                />;
                            })
                        }
                    </VictoryChart >;
                case 'scalar':
                    const data = props.result.data.result[1];
                    const formattedValue = props.scalarFormatter ? props.scalarFormatter(data) : data;
                    const labelX = props.width / 2;
                    const labelY = props.height / 2;
                    return <VictoryContainer theme={victoryTheme} width={props.width} height={props.height}>
                        <VictoryLabel text={formattedValue || ""}
                            x={labelX} y={labelY}
                            textAnchor="middle"
                            style={{ fill: theme.palette.text.primary, fontSize: 36 }}
                        />
                        <VictoryLabel text={props.legendTitle ?? ""}
                            x={labelX} y={labelY + 36}
                            textAnchor="middle"
                            style={{ fill: theme.palette.text.primary, fontSize: 18 }}
                        />
                    </VictoryContainer>;
            }

            return <span>Unsupported Query Type</span>
    }
};

// From: https://stackoverflow.com/a/11832950
function roundTo2DecimalPlaces(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

// From: https://stackoverflow.com/a/35890537
function formatTime(s: number) {
    const dtFormat = new Intl.DateTimeFormat('en', {
        timeStyle: 'short',
    });

    return dtFormat.format(new Date(s * 1e3));
}
