import { CreateClient, CreatedClient, CREATE_CLIENT, ORGANIZATION_CLIENTS_QUERY } from "@code/authzed-common/src/queries/client";
import { SetTenantMemberRoleParams, SetTenantMemberRoleResponse, SET_TENANT_MEMBER_ROLE } from "@code/authzed-common/src/queries/tenant";
import { Client } from "@code/authzed-common/src/types/client";
import { UserEvent } from '@code/authzed-common/src/types/events';
import { Organization } from "@code/authzed-common/src/types/organization";
import { Tenant, TenantRole } from "@code/authzed-common/src/types/tenant";
import { AmplitudeContext } from '@code/trumpet/src/AmplitudeClient';
import BoundTable from "@code/trumpet/src/BoundTable";
import { useManagedMutation } from "@code/trumpet/src/hooks";
import { useTitle } from "@code/trumpet/src/TitleHook";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import ComputerIcon from '@material-ui/icons/Computer';
import React, { useContext, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { InfoBox } from "../components/InfoBox";
import OrgReference from "../components/OrgReference";
import TenantLogo from "../components/TenantLogo";
import { UserReference } from "../components/UserReference";
import { useSharedStyles } from "../sharedstyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoBar: {
            marginBottom: theme.spacing(2),
        },
        clientLink: {
            color: theme.palette.text.primary,
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        tenantItem: {
            '& div': {
                marginRight: theme.spacing(1)
            }
        }
    }));


interface OrgClientsState {
    /**
     * tenantForNewClient, if specified in state, indicates the tenant to which a newly created client
     * should be added.
     */
    tenantForNewClient?: Tenant
}


export function OrgClients(props: RouteComponentProps<any> & { organization: Organization }) {
    const amplitudeClient = useContext(AmplitudeContext);
    const sharedClasses = useSharedStyles();
    const classes = useStyles();
    const organization = props.organization;
    const history = useHistory();
    const tenantForNewClient = (history.location.state as (OrgClientsState | undefined))?.tenantForNewClient;

    const [isCreateDialogOpen, setCreateDialogOpen] = useState(!!tenantForNewClient);
    const [newClientTitle, setNewClientTitle] = useState('');
    const [newClientNote, setNewClientNote] = useState('');
    const [grantTenantId, setGrantTenantId] = useState<string | undefined>(undefined);

    const [createClient, { running: creatingClient }] = useManagedMutation<CreatedClient, CreateClient>(CREATE_CLIENT)
    const [setTenantMemberRole] = useManagedMutation<SetTenantMemberRoleResponse, SetTenantMemberRoleParams>(SET_TENANT_MEMBER_ROLE)

    useTitle(['authzed', 'Clients', organization.name], [organization]);

    const startCreateClient = () => {
        if (!newClientTitle || !newClientNote) {
            return;
        }

        (async () => {
            const result = await createClient({
                variables: {
                    organizationId: organization.id,
                    title: newClientTitle,
                    note: newClientNote
                }
            });
            if (result && result.data?.createClient.ok) {
                // If there is a tenant, add the client to the tenant.
                const grantTenant = props.organization.recentTenants?.find((tenant: Tenant) => tenant.id === grantTenantId);
                if (tenantForNewClient !== undefined || grantTenant !== undefined) {
                    const setResult = await setTenantMemberRole({
                        variables: {
                            tenantId: grantTenant?.id ?? tenantForNewClient?.id ?? '',
                            memberId: result.data.createClient.client.id,
                            newRole: grantTenant?.addedClientDefaultRole ?? tenantForNewClient?.addedClientDefaultRole ?? TenantRole.VIEWER,
                            preventRoleChange: true
                        }
                    });
                    if (!setResult || !setResult.data?.setTenantMembershipAndRole.ok) {
                        return;
                    }
                }

                // Go to the client.
                history.push(`/organization/${props.organization.slug}/clients/${result.data.createClient.client.id}`);
            }
        })();
    };

    const showCreateDialog = () => {
        setNewClientTitle('');
        setNewClientNote('');
        setGrantTenantId('');
        setCreateDialogOpen(true);
    };

    const handleCreateClient = () => {
        amplitudeClient?.logEvent(UserEvent.CreateNewClient);
        startCreateClient();
    }

    return <div>
        <Breadcrumbs className={sharedClasses.breadcrumbs} aria-label="breadcrumb">
            <Link className={sharedClasses.link} to={`/organization/${organization.slug}`}>
                <OrgReference organization={organization} />
            </Link>
            <Typography color="textPrimary">API Clients</Typography>
        </Breadcrumbs>
        <InfoBox>
            API Clients are callers to the Authzed API. Clients can have one or more API Tokens to authenticate.
        </InfoBox>
        <BoundTable<Client>
            columns={[
                {
                    id: "icon",
                    title: "",
                    render: (client: Client) => {
                        return <ComputerIcon />
                    }
                },
                {
                    id: "title",
                    title: "Title",
                    cellStyle: {
                        width: '25%',
                    },
                    render: (client: Client) => {
                        return <Link to={`/organization/${organization.slug}/clients/${client.id}`} className={classes.clientLink}>{client.title}</Link>
                    }
                },
                {
                    id: "note", title: "Note",
                    cellStyle: {
                        width: '60%',
                    },
                    render: (client: Client) => {
                        return client.note
                    }
                },
                {
                    id: "createdby", title: "Created By",
                    cellStyle: {
                        width: '15%',
                    },
                    render: (client: Client) => {
                        return <div style={{ textAlign: 'center' }}>
                            <UserReference user={client.createdBy} />
                        </div>;
                    }
                },
                {
                    id: "actions", title: "", isSortable: false,
                    render: (client: Client) => {
                        return ''
                    }
                },
            ]}
            query={
                {
                    gql: ORGANIZATION_CLIENTS_QUERY,
                    variables: {
                        organizationId: organization.id,
                    },
                    recordsKey: ['organizationById', 'clients']
                }
            }
            toolbar={
                <Box className={classes.toolbar} onClick={showCreateDialog}>
                    <Button variant="contained" startIcon={<AddIcon />} color="primary" aria-label="add">
                        Create Client
                    </Button>
                </Box>
            }
        />

        <Dialog
            open={isCreateDialogOpen}
            onClose={() => setCreateDialogOpen(false)}
            disableBackdropClick={creatingClient}
            disableEscapeKeyDown={creatingClient}
        >
            <form onSubmit={handleCreateClient}>
                <DialogTitle>Create new client under <OrgReference organization={props.organization} /> </DialogTitle>
                <DialogContent>
                    {creatingClient && <CircularProgress />}
                    {!creatingClient && <DialogContentText>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="client-title">Client Title</InputLabel>
                            <Input placeholder="my client"
                                value={newClientTitle}
                                autoFocus
                                inputProps={{ 'pattern': '^\\w(\\w|\\s)+$', 'maxlength': 50, 'minlength': 1 }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewClientTitle(event.target.value)} />
                            <FormHelperText id="client-title-text">The user-friendly title for the client. Must be alphanumeric. Can contain spaces.</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="client-note">Client Note</InputLabel>
                            <Input placeholder="An important client"
                                value={newClientNote}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewClientNote(event.target.value)} />
                            <FormHelperText id="client-note-text">A descriptive note for the client</FormHelperText>
                        </FormControl>
                        {(organization.recentTenants?.length ?? 0) > 0 && <FormControl fullWidth>
                            <InputLabel htmlFor="client-tenant">Auto-grant access to permissions system</InputLabel>
                            <Select
                                value={grantTenantId}
                                onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => setGrantTenantId(event.target.value as string)}>
                                <MenuItem aria-label="None" value={""}>(None)</MenuItem>
                                {organization.recentTenants!.map((tenant: Tenant) => {
                                    return <MenuItem key={tenant.id} value={tenant.id}>
                                        <div className={classes.tenantItem} >
                                            <TenantLogo tenant={tenant} />
                                            {tenant.name}
                                        </div>
                                    </MenuItem>
                                })}
                            </Select>
                            <FormHelperText id="client-tenant-text">(optional) Choose a permissions system in which to grant the newly created client API access</FormHelperText>
                        </FormControl>}
                    </DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button disabled={creatingClient} onClick={() => setCreateDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary" disabled={creatingClient || !newClientTitle || !newClientNote} onClick={handleCreateClient} autoFocus>
                        Create Client
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </div>;
}
